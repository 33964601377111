import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { render as domRender, unmountComponentAtNode } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import mapProps from 'recompose/mapProps';
import { ApolloProvider } from '@apollo/client';
import classNames from 'classnames';
import SSRContextProvider from '../SSRContext';
import ScrollableDropdownContent from '../ScrollableDrawerContent';
import useBottomDrawer from '../../hooks/useBottomDrawer';
import defaultStyles from './defaultStyles.legacy.css';
import { configureClientStore } from '../../../cash/shared/configureStore';
import { ModalFactoryOptions, ModalProps } from './typings';

const modalFactory = ({
  styles,
  Icon,
  ButtonWithLoading,
  targetId = 'rasch-confirm-alert',
  RaschProviders,
}: ModalFactoryOptions) => {
  let root = null;
  const createElementReconfirm = (props) => {
    const divTarget = document.getElementById(props.targetId || targetId);
    if (divTarget && props.customUi) {
      const store = configureClientStore();
      const Root = () => (
        <Provider store={store}>
          <BrowserRouter>
            <RaschProviders ignoreToastProvider />
            <ApolloProvider client={global.apolloClient}>
              <HelmetProvider>
                <SSRContextProvider>
                  <Modal {...props} />
                </SSRContextProvider>
              </HelmetProvider>
            </ApolloProvider>
          </BrowserRouter>
        </Provider>
      );
      domRender(<Root />, divTarget);
    } else if (divTarget && !props.customUi) {
      root = createRoot(divTarget);
      root.render(<Modal {...props} />);
    } else if (__DEVELOPMENT__) {
      // eslint-disable-next-line no-console
      console.error(
        `Modal: No element with id ${props.targetId || targetId} found.`,
      );
    }
  };

  const removeElementReconfirm = (props) => {
    const target = document.getElementById(props.targetId || targetId);
    if (target && !props.customUi) {
      root.unmount(target);
    }
    if (target && props.customUi) {
      // remove the element from the DOM
      unmountComponentAtNode(target);
    }
  };

  const addBodyClass = () => {
    document.body.classList.add(defaultStyles.BodyClass);
  };

  const removeBodyClass = () => {
    document.body.classList.remove(defaultStyles.BodyClass);
  };

  const withMapProps: (props) => ModalProps = mapProps((props: ModalProps) => {
    const defaultProps: ModalProps = {
      fullPage: false,
      overlay: null,
      title: null,
      content: null,
      overlayClassName: null,
      customUi: null,
      closeOnClickOutside: true,
      isCloseVisible: true,
      closeOnEscape: true,
      closeOnLocationChange: false,
      keyCodeForClose: [],
      afterClose: () => null,
      onClickOutside: () => null,
      onkeyPress: () => null,
      onKeypressEscape: () => null,
      type: 'modal',
      buttons: [
        {
          children: 'Abbrechen',
          onClick: () => null,
        },
        {
          children: 'Bestätigen',
          onClick: () => null,
        },
      ],
    };
    return { ...defaultProps, ...props };
  });

  const Modal = withMapProps(({ ...props }: ModalProps) => {
    const {
      title,
      hasStickyHeader = false,
      hasStickyFooter = false,
      content,
      customUi,
      closeOnClickOutside,
      closeOnEscape,
      closeOnLocationChange,
      keyCodeForClose,
      onkeyPress,
      onClickOutside,
      onKeypressEscape,
      buttons,
      hideDefaultButtons = false,
      isCloseVisible,
      type,
    }: ModalProps = props;
    let { overlay } = props;
    const isMobile = global.innerWidth < 760 && type === 'drawer';
    const contentRef = useRef(null);
    const drawerRef = useRef(overlay);
    const stickyHeaderRef = useRef(null);
    const stickyFooterRef = useRef(null);
    const stickyFooterModalRef = useRef(null);
    const defaultButtonGroupRef = useRef(null);
    const [stickyOffset, setStickyOffset] = useState(0);
    // useBottomDrawer hook is only used for mobile by isMobile rule
    useBottomDrawer({
      drawerRef,
      contentRef,
      isMobile,
      setIsOpen: () => close(),
    });

    const handleClickButton = (button) => {
      if (button.onClick) {
        button.onClick();
      }
      close();
    };

    const handleClickOverlay = (e) => {
      const isClickOutside = e.target === overlay;

      if (closeOnClickOutside && isClickOutside) {
        onClickOutside();
        close();
      }

      e.stopPropagation();
    };

    const close = useCallback(() => {
      removeBodyClass();
      if (type === 'drawer') {
        const element = document.getElementsByClassName(
          defaultStyles.DrawerBottom,
        );
        if (element && element[0]) {
          element[0].classList.remove(defaultStyles.Open);
        }
        setTimeout(() => {
          removeElementReconfirm(props);
        }, 300);
      } else {
        removeElementReconfirm(props);
      }
    }, [props, type]);

    const keyboard = useCallback(
      (event) => {
        const keyCode = event.keyCode;
        const isKeyCodeEscape = keyCode === 27;

        if (keyCodeForClose.includes(keyCode)) {
          close();
        }

        if (closeOnEscape && isKeyCodeEscape) {
          onKeypressEscape(event);
          close();
        }

        if (onkeyPress) {
          onkeyPress();
        }
      },
      [close, closeOnEscape, keyCodeForClose, onKeypressEscape, onkeyPress],
    );

    useEffect(() => {
      document.addEventListener('keydown', keyboard, false);
      return () => {
        document.removeEventListener('keydown', keyboard, false);
      };
    }, [keyboard]);

    const popStateListener = useCallback(() => {
      close();
    }, [close]);

    useEffect(() => {
      if (closeOnLocationChange) {
        window.addEventListener('popstate', popStateListener);
      }

      return () => {
        if (closeOnLocationChange) {
          window.removeEventListener('popstate', popStateListener);
        }
      };
    }, [popStateListener, closeOnLocationChange]);

    useEffect(() => {
      if (type === 'drawer') {
        // get element by class name
        const element = document.getElementsByClassName(
          defaultStyles.DrawerBottom,
        );
        if (element && element[0]) {
          setTimeout(() => {
            element[0].classList.add(defaultStyles.Open);
          }, 0);
        }
      }

      return () => {
        const element = document.getElementsByClassName(
          defaultStyles.DrawerBottom,
        );
        if (element && element[0]) {
          element[0].classList.remove(defaultStyles.Open);
        }
      };
    }, [type]);

    // use effect to update the stickyOffset value to calculate the scrollableContent correctly
    // including the height of the sticky header and footer
    useEffect(() => {
      if (
        !stickyHeaderRef?.current &&
        !stickyFooterRef?.current &&
        !stickyFooterModalRef?.current &&
        !defaultButtonGroupRef?.current
      ) {
        return;
      }

      const nodes = [];
      if (stickyHeaderRef?.current) {
        nodes.push(stickyHeaderRef?.current);
      }
      if (stickyFooterRef?.current) {
        nodes.push(stickyFooterRef?.current);
      }
      if (stickyFooterModalRef?.current) {
        nodes.push(stickyFooterModalRef?.current);
      }
      if (defaultButtonGroupRef?.current) {
        nodes.push(defaultButtonGroupRef?.current);
      }

      if (!Array.isArray(nodes || !nodes.length)) {
        return;
      }

      const resizeObserver = new ResizeObserver((nodes) => {
        // Wile wrapping it in the requestAnimationFrame we can avoid this error - ResizeObserver loop limit exceeded
        // accoring to this article this error only appears in chrome and can be ignored
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        window.requestAnimationFrame(() => {
          if (!Array.isArray(nodes) || !nodes.length) {
            return;
          }
          let offsetValue = 0;
          nodes.forEach((node) => {
            if (node?.target) {
              //@ts-ignore
              offsetValue += node.target.offsetHeight;
            }
          });
          setStickyOffset(offsetValue);
        });
      });

      nodes?.forEach((node) => {
        resizeObserver.observe(node);
      });
      // clean up
      return () => {
        Array.isArray(nodes) &&
          nodes.length &&
          nodes.forEach((node) => {
            resizeObserver.unobserve(node);
          });
        resizeObserver.disconnect();
        setStickyOffset(0);
      };
    }, []);

    const buttonGroupJsx = (
      <div
        ref={defaultButtonGroupRef}
        className={classNames(defaultStyles.ButtonGroup, {
          [styles.ButtonGroup]: !!styles.ButtonGroup,
          [defaultStyles.ButtonGroupScrollable]: !hasStickyFooter,
        })}
      >
        {buttons.map((item, i) => (
          <ButtonWithLoading
            key={i}
            {...item}
            onClick={() => handleClickButton(item)}
          >
            {item.children}
          </ButtonWithLoading>
        ))}
      </div>
    );

    return (
      <div
        role={'presentation'}
        className={classNames(defaultStyles.Overlay, {
          [styles.Overlay]: !!styles.Overlay,
          [defaultStyles.FullPage]: props.fullPage,
          [styles.FullPage]: styles.FullPage && props.fullPage,
        })}
        ref={(dom) => (overlay = dom)}
        onClick={(e) => handleClickOverlay(e)}
        onKeyDown={(e) => handleClickOverlay(e)}
      >
        {(type === 'modal' && customUi && customUi({ close })) || (
          <div
            ref={drawerRef}
            className={classNames(defaultStyles.Body, {
              [styles.Body]: !!styles.Body,
              [styles.Body]: !!styles.Body,
              [defaultStyles.DrawerBottom]: type === 'drawer',
            })}
          >
            {isCloseVisible && (
              <div
                role={'button'}
                tabIndex={0}
                onKeyDown={close}
                onClick={close}
                className={classNames(defaultStyles.CloseIconWrapper, {
                  [styles.CloseIconWrapper]: !!styles.CloseIconWrapper,
                  [defaultStyles.CloseIconWithStickyHeader]: hasStickyHeader,
                  [defaultStyles.DrawerBottom]: type === 'drawer',
                })}
              >
                <Icon type={'IconXMark'} />
              </div>
            )}
            {/* Placeholder div for sticky header. can be filled dynamically using React Portal therefore it has an id */}
            <>
              {hasStickyHeader && (
                <>
                  <div
                    ref={stickyHeaderRef}
                    id="ModalStickyHeader"
                    className={defaultStyles.StickyHeaderWrapper}
                  >
                    {title && <p className={styles.Title}>{title}</p>}
                  </div>
                </>
              )}
            </>

            {(type === 'drawer' && customUi && (
              <div
                ref={contentRef}
                className={classNames({
                  [defaultStyles.ScrollableContentWrapper]: hasStickyHeader,
                })}
              >
                <ScrollableDropdownContent stickyOffset={stickyOffset}>
                  {!hasStickyHeader && title && (
                    <div
                      className={classNames(
                        styles.Title,
                        defaultStyles.TitleScrollable,
                      )}
                    >
                      {title}
                    </div>
                  )}
                  {customUi({
                    close,
                    drawerRef,
                    hasStickyHeader,
                    hasStickyFooter,
                  })}
                  {!hasStickyFooter && buttons && !hideDefaultButtons && (
                    <>{buttonGroupJsx}</>
                  )}
                </ScrollableDropdownContent>
              </div>
            )) || (
              <>
                {content && (
                  <div
                    className={classNames(defaultStyles.Content, {
                      [styles.Content]: !!styles.Content,
                    })}
                  >
                    <ScrollableDropdownContent stickyOffset={stickyOffset}>
                      {title && !hasStickyHeader && (
                        <div>
                          <p
                            className={classNames(
                              defaultStyles.Title,
                              defaultStyles.TitleScrollable,
                              {
                                [styles.Title]: !!styles.Title,
                              },
                            )}
                          >
                            {title}
                          </p>
                        </div>
                      )}
                      <div className={defaultStyles.ContentWrapper}>
                        {content}
                      </div>
                      {buttons && !hasStickyFooter && <>{buttonGroupJsx}</>}
                    </ScrollableDropdownContent>
                  </div>
                )}
              </>
            )}
            {/* Placeholder div for sticky footer. can be filled dynamically using React Portal therefore it has an id */}
            {hasStickyFooter && (
              <>
                <div
                  ref={stickyFooterRef}
                  id="ModalStickyFooter"
                  className={defaultStyles.FooterWrapper}
                >
                  {(buttons && !hideDefaultButtons && <>{buttonGroupJsx}</>) ||
                    null}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }) as FC<ModalProps>;

  return (props) => {
    createElementReconfirm(props);
    addBodyClass();
  };
};

export default modalFactory;

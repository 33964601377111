/* istanbul ignore file */
import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { PAGE_SIZE } from './constants';
import { GET_CMS_SEARCH_PAGE_ALL, GET_SEARCH_PAGE_ALL } from './queries';

export const searchAllApolloConfig: RaschApolloConfig = {
  options: ({ params }) => {
    const { searchQuery = '' } = params;
    return {
      query: GET_SEARCH_PAGE_ALL,
      additionalQuery: GET_CMS_SEARCH_PAGE_ALL,
      additionalVariables: {
        query: searchQuery,
      },
      variables: {
        query: searchQuery,
        limit: PAGE_SIZE,
      },
    };
  },
};

import React from 'react';
import classNames from 'classnames';
import {
  DATE_FORMAT_FULL_TIME,
  DATE_FORMAT_SHORT,
  checkIfSameDay,
  formatDate,
} from '../../../../../../../../shared/helpers/dateTimeElapsed';
import { tealiumTrackEvent } from '../../../../../../../../shared/helpers/tealium';
import {
  formatPercentage,
  formatPrice,
  getFormatterByValue,
} from '../../../../../components/Highcharts/helpers';
import { getTrendClass, renderIconsByFieldType } from './helpers';
import Link from '../../../../../../../../common/components/Link';
import Icon from '../../../../../components/Icon';
import Tooltip from '../../../../../components/Tooltip';
import ListCheckbox from '../../ListCheckbox';
import InstrumentField from '../../Portfolio/InstrumentField';
import { ColorTableCell } from '../../../../../components/Highcharts/components/ChartComparison/components/ColorTableCell';
import { alertsFormOverlay } from '../../../../../components/AlertsForm';
import { MARKET_TABLE_ORIGIN } from '../../../../../components/MarketTable/constants';
import {
  CHANCE_ICON,
  MONITOR_ICON,
  SENSITIVITY_ICON,
  TOTALS_ROW_IDENTIFIER,
} from '../constants';
import styles from './tableStyles.legacy.css';
import { MULTIPLE_INSTRUMENTS_GENERIC_DATA } from '../../../../../components/Widgets/components/MultipleInstrumentsGenericData/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [
  styles.SensitiveIconWrapper,
  styles.MonitorIcon,
  styles.MonitorIconWrapper,
  styles.Low,
  styles.Neutral,
  styles.High,
  styles.ChanceIcon,
];

const DEFAULT_STYLES = classNames(styles.TableRow, styles.Right);

const getIconByValue = (val) => {
  const value = Number(val);
  if (value === 0) {
    return 'IconArrowRightCircle';
  }
  if (value < 0) {
    return 'IconArrowDownRightCircle';
  }
  if (value > 0) {
    return 'IconArrowUpRightCircle';
  }
};

// For formatting currency data field with "Bonds" extension
const formatQuotation = (value: any, quotation: number) => {
  if (!value) {
    return '';
  }
  // 4 equals "Prozentpreis" => CHF (%)
  if (quotation === 4) {
    return `${value} (%)`;
  }
  return `${value}`;
};

// For formatting category 'DateOrTime' (avoid so much duplicated code?) (see headerMapping.md)
const formatDateOrTime = (value: any, alwaysDisplayDate = false) => {
  if (!alwaysDisplayDate && checkIfSameDay(new Date(value), new Date())) {
    return formatDate(value, DATE_FORMAT_FULL_TIME);
  }
  return formatDate(value, DATE_FORMAT_SHORT);
};

type PortfolioTealiumTrackPayload = {
  event_name: string;
  event_category: string;
  event_action: string;
  portfolio_key?: string;
  watchlist_key?: string;
  instrument_isin?: string;
  instrument_valor?: string;
  from?: string;
};

export type MapFieldsResult = Record<
  keyof Instrument | string,
  (string | typeof InstrumentField)[]
>;

const cacheFormatResults = {};

const getFormatValueFromCache = (key: string, fn: Function) => {
  if (!cacheFormatResults[key]) {
    cacheFormatResults[key] = fn();
  }
  return cacheFormatResults[key];
};

const formatDateWithCache = (value, format) => {
  return getFormatValueFromCache(`formatDate-${value}-${format}`, () =>
    formatDate(value, format),
  );
};

const formatDateOrTimeWithCache = (value, alwaysDisplayDate = false) => {
  return getFormatValueFromCache(
    `formatDateOrTime-${value}-${alwaysDisplayDate}`,
    () => formatDateOrTime(value, alwaysDisplayDate),
  );
};

export const formatPriceWithCache = (
  value,
  type = null,
  intlFormatter: Intl.NumberFormat = null,
) => {
  return getFormatValueFromCache(
    `formatPrice-${value}-${type}-${intlFormatter}`,
    () => formatPrice(value, type, intlFormatter),
  );
};

const formatPercentageWithCache = (value) => {
  return getFormatValueFromCache(`formatPercentage-${value}`, () =>
    formatPercentage(value),
  );
};

export const mapFields = (
  instrument: Instrument,
  fields: (keyof Instrument)[],
  fieldTypes?: Record<keyof Instrument, string>,
  formatterProps?: Record<keyof Instrument, Function> | Function,
): MapFieldsResult => {
  if (!instrument?.instrumentKey || fields?.length === 0) return {};

  const result = {};

  for (const [index, field] of fields.entries()) {
    const fallback = headerMapping.hasOwnProperty(field)
      ? null
      : instrument[field] || `[${field}]`;
    const fieldType = fieldTypes?.[field]
      ? { fieldType: fieldTypes?.[field] }
      : {};
    const formatter = formatterProps
      ? { formatter: formatterProps[index] || formatterProps }
      : {};
    const props = Object.assign(
      {
        instrument,
        value: instrument[field],
      },
      fieldType,
      formatter,
    );
    result[field] = headerMapping[field]?.formatter?.(props) || fallback;
  }
  return result;
};

export const mapTooltip = (field) =>
  !field ? null : <Tooltip content={headerMapping[field]?.tooltip || ''} />;

export const headerMapping = {
  // INFO: DO NOT EDIT THE "name" field!!!!!! It will be removed in the future
  name: {
    name: 'Name',
    description: 'Name',
    style: styles.TableRow,
    sortable: true,
    group: 'Stammdaten',
    valueOverride: 'mName', // INFO: don't add new logic here. The "name" field is a legacy field and should be replaced by "mName" field. Do not delete it either. valueOverride takes the formatter function from mName
  },
  mName: {
    name: 'Name',
    description: 'Name',
    style: styles.TableRow,
    sortable: true,
    formatter: ({ value, instrument, data = null, origin = '' }) => {
      let label = value;

      // if mName is empty we use the name field this can happen on other assets or on an broken instrument
      if (instrument?.otherAsset || !value) {
        label =
          instrument?.name ||
          `Inaktives Instrument: (@${instrument?.instrumentKey})`;
      }
      if (
        instrument?.identifier === TOTALS_ROW_IDENTIFIER ||
        (instrument.otherAsset && !instrument?.fullquoteUri)
      ) {
        return (
          <div
            className={classNames({
              [styles.NameCell]:
                origin !== MARKET_TABLE_ORIGIN &&
                origin !== MULTIPLE_INSTRUMENTS_GENERIC_DATA,
            })}
          >
            <span title={label}>{label}</span>
          </div>
        );
      }
      let tealiumTrackPayload: PortfolioTealiumTrackPayload = {
        event_name: 'portfolio_click_instrument',
        event_category: 'portfolio',
        event_action: 'portfolio_click_instrument',
        portfolio_key: data?.portfolioKey,
      };
      if (data?.__typename === 'Watchlist') {
        tealiumTrackPayload = {
          event_name: 'watchlist_click_instrument',
          event_category: 'watchlist',
          event_action: 'watchlist_click_instrument',
          watchlist_key: data?.watchlistKey,
        };
      }

      if (origin) {
        tealiumTrackPayload.from = origin;
      }
      return (
        <div
          className={classNames({
            [styles.NameCell]:
              origin !== MARKET_TABLE_ORIGIN &&
              origin !== MULTIPLE_INSTRUMENTS_GENERIC_DATA,
          })}
        >
          <Link
            className={classNames({
              [styles.Link]: !instrument.otherAsset && instrument?.fullquoteUri,
            })}
            path={
              (!instrument.otherAsset &&
                instrument?.fullquoteUri &&
                `/${instrument?.fullquoteUri}`) ||
              ''
            }
            onClick={
              instrument?.fullquoteUri
                ? () => {
                    tealiumTrackEvent({
                      type: 'link',
                      payload: {
                        ...tealiumTrackPayload,
                        instrument_isin: instrument?.isin,
                        instrument_valor: instrument?.mValor,
                        instrument_other_asset: instrument?.otherAsset || false,
                        instrument_type: instrument?.instrumentType,
                        instrument_key: instrument?.instrumentKey,
                        instrument_issuer: instrument?.leadMan,
                      },
                    });
                  }
                : undefined
            }
            label={label}
            title={label}
          />
        </div>
      );
    },
  },
  mShortName: {
    name: 'Name (Kurz)',
    description: 'Name (Kurz)',
    style: styles.TableRow,
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value, instrument, data, origin = '' }) => {
      let label = value;
      // if mName is empty we use the name field this can happen on other assets or on an broken instrument
      if (instrument?.otherAsset || !value) {
        label = instrument?.name || instrument?.mName;
      }
      if (
        instrument?.identifier === TOTALS_ROW_IDENTIFIER ||
        (instrument.otherAsset && !instrument?.fullquoteUri)
      ) {
        return (
          <div className={styles.NameCell}>
            <span title={label}>{label}</span>
          </div>
        );
      }
      let tealiumTrackPayload: PortfolioTealiumTrackPayload = {
        event_name: 'portfolio_click_instrument',
        event_category: 'portfolio',
        event_action: 'portfolio_click_instrument',
        portfolio_key: data?.portfolioKey,
      };
      if (data?.__typename === 'Watchlist') {
        tealiumTrackPayload = {
          event_name: 'watchlist_click_instrument',
          event_category: 'watchlist',
          event_action: 'watchlist_click_instrument',
          watchlist_key: data?.watchlistKey,
        };
      }

      if (origin) {
        tealiumTrackPayload.from = origin;
      }
      return (
        <div className={styles.NameCell}>
          <Link
            className={classNames({
              [styles.Link]: !instrument.otherAsset && instrument?.fullquoteUri,
            })}
            path={
              (!instrument.otherAsset &&
                instrument?.fullquoteUri &&
                `/${instrument?.fullquoteUri}`) ||
              ''
            }
            onClick={
              instrument?.fullquoteUri
                ? () => {
                    tealiumTrackEvent({
                      type: 'link',
                      payload: {
                        ...tealiumTrackPayload,
                        instrument_isin: instrument?.isin,
                        instrument_valor: instrument?.mValor,
                        instrument_other_asset: instrument?.otherAsset || false,
                        instrument_type: instrument?.instrumentType,
                        instrument_key: instrument?.instrumentKey,
                      },
                    });
                  }
                : undefined
            }
            label={label}
            title={label}
          />
        </div>
      );
    },
  },
  mCur: {
    name: 'Whg.',
    description: 'Währung',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value, instrument }) => {
      return formatQuotation(value, instrument?.pricingQuotationId);
    },
  },
  quantity: {
    name: 'Anzahl',
    description: 'Anzahl',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Portfolio',
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'Volume');
    },
  },
  currency: {
    name: 'Whg.',
    description: 'Währung',
    style: styles.TableRow,
    sortable: true,
    group: 'Portfolio',
    formatter: ({ value, instrument }) => {
      return formatQuotation(value, instrument?.pricingQuotationId);
    },
  },
  currentPrice: {
    name: 'Aktuell',
    description: 'Aktuell',
    style: DEFAULT_STYLES,
    sortable: true,
    group: ['Portfolio', 'Watchlist'],
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return '';
        }
        return formatPriceWithCache(value, instrument.scGrouped);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => formatPriceWithCache(val, instrument.scGrouped)}
          field="currentPrice"
        />
      );
    },
  },
  paidPrice: {
    name: 'Preis',
    description: 'Kaufpreis in Portfolio-Währung',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Portfolio',
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'Value');
    },
  },
  actualPrice: {
    name: 'Wert',
    description: 'Aktueller Wert in Portfolio-Währung',
    style: DEFAULT_STYLES,
    sortable: true,
    group: ['Portfolio', 'Watchlist'],
    formatter: ({ value, instrument }) => {
      return formatPriceWithCache(value, instrument?.scGrouped || 'Value');
    },
  },
  accountPercent: {
    name: '+/- %',
    description: 'Veränderung in %',
    style: DEFAULT_STYLES,
    sortable: true,
    group: ['Portfolio'],
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return (
        <span
          className={getTrendClass(value, styles.Positive, styles.Negative)}
        >
          {value && `${formatPercentageWithCache(value)}%`}
        </span>
      );
    },
  },
  perfPercentage: {
    name: '+/- %',
    description: '+/- in %',
    style: DEFAULT_STYLES,
    sortable: true,
    group: ['Portfolio', 'Watchlist'],
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return (
          <span
            className={getTrendClass(value, styles.Positive, styles.Negative)}
          >
            {value && `${formatPercentageWithCache(value)}%`}
          </span>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            if (!val) {
              return null;
            }
            return (
              <span
                className={getTrendClass(
                  `${val}`,
                  styles.Positive,
                  styles.Negative,
                )}
              >
                {`${formatPercentageWithCache(val)}%`}
              </span>
            );
          }}
          field="perfPercentage"
        />
      );
    },
  },
  accountPlusMinus: {
    name: '+/-',
    description: 'Veränderung',
    style: DEFAULT_STYLES,
    sortable: true,
    group: ['Portfolio'],
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }

      return formatPriceWithCache(value, 'Value');
    },
  },
  'alert-checkbox-delete': {
    name: '',
    description: '',
    style: classNames(styles.TableRow, styles.Checkbox),
    sortable: false,
    formatter: ({ instrument }) => {
      return <ListCheckbox list="alerts" id={instrument.id} />;
    },
  },
  account: {
    name: 'Gewinn/Verlust CHF',
    description: 'Gewinn/Verlust CHF',
    style: DEFAULT_STYLES,
    sortable: false,
    formatter: ({ value }) => {
      const price = formatPriceWithCache(value, 'Value');

      if (!price) {
        return null;
      }
      return (
        <span
          className={getTrendClass(value, styles.Positive, styles.Negative)}
        >
          {!price.startsWith('-') ? `+${price}` : price}
        </span>
      );
    },
  },
  paidAverageWithFeesOrigCurrency: {
    name: 'Einstand Stück OW',
    description: 'Einstands-Preis pro Stück inkl. Gebühren in Original-Währung',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Portfolio',
    formatter: ({ value, instrument }) => {
      return formatPriceWithCache(value, instrument.scGrouped);
    },
  },
  paidAverage: {
    name: 'Einstand Stück',
    description: 'Einstands-Preis pro Stück',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Portfolio',
    formatter: ({ value, instrument }) => {
      return formatPriceWithCache(value, instrument.scGrouped);
    },
  },
  alertsUpperLimit: {
    name: 'Obere Limite',
    description: 'Obere Limite',
    style: DEFAULT_STYLES,
    sortable: false,
    group: 'Alerts',
    formatter: ({ instrument, navigate, location }) => {
      if (
        instrument.identifier === TOTALS_ROW_IDENTIFIER ||
        instrument?.otherAsset
      ) {
        return;
      }

      if (instrument?.alertsData?.upper?.value) {
        return formatPriceWithCache(instrument?.alertsData?.upper?.value);
      }

      return (
        <Link
          className={styles.Link}
          onClick={() =>
            alertsFormOverlay({
              alertKey: '',
              fullquoteUri: instrument?.fullquoteUri,
              navigate,
              location,
            })
          }
        >
          hinzufügen
        </Link>
      );
    },
  },
  alertsLowerLimit: {
    name: 'Untere Limite',
    description: 'Untere Limite',
    style: DEFAULT_STYLES,
    sortable: false,
    group: 'Alerts',
    formatter: ({ instrument, navigate, location }) => {
      if (
        instrument.identifier === TOTALS_ROW_IDENTIFIER ||
        instrument?.otherAsset
      ) {
        return;
      }

      if (instrument?.alertsData?.lower?.value) {
        return formatPriceWithCache(instrument?.alertsData?.lower?.value);
      }

      return (
        <Link
          className={styles.Link}
          onClick={() =>
            alertsFormOverlay({
              alertKey: '',
              fullquoteUri: instrument?.fullquoteUri,
              navigate,
              location,
            })
          }
        >
          hinzufügen
        </Link>
      );
    },
  },
  paidOrigCurrency: {
    name: 'Kaufpreis OW',
    description: 'Kaufpreis in Original-Währung',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Portfolio erweitert',
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'Value');
    },
  },
  actualPriceOrigCurrency: {
    name: 'Wert OW',
    description: 'Aktueller Wert in Original-Währung',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Portfolio erweitert',
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'Value');
    },
  },
  accountPlusMinusOrigCurrency: {
    name: 'Veränderung OW',
    description: 'Veränderung in OW',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Portfolio erweitert',
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'Value');
    },
  },
  accountPercentOrigCurrency: {
    name: 'Veränderung OW %',
    description: 'Veränderung in % in OW',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Portfolio erweitert',
    formatter: ({ value }) => {
      return (
        <span
          className={getTrendClass(value, styles.Positive, styles.Negative)}
        >
          {value && `${formatPercentageWithCache(value)}%`}
        </span>
      );
    },
  },
  market: {
    name: 'Börse',
    description: 'Börsenplatz-Kürzel',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  marketDescription: {
    name: 'Börse',
    description: 'Börsenplatz',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  isin: {
    name: 'ISIN',
    description: 'ISIN',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  scGrouped: {
    name: 'Kategorie',
    description: 'Kategorie',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  mSymb: {
    name: 'Symbol',
    description: 'Symbol',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  mValor: {
    name: 'Valor',
    description: 'Valor',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  monitorFontIcon: {
    name: 'Monitor',
    description: 'Monitor',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Aktienmonitor',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return (
          instrument?.monitorFontIcon &&
          renderIconsByFieldType(
            MONITOR_ICON,
            value,
            getIconByValue(value) || <>&ndash;</>,
          )
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) =>
            val &&
            renderIconsByFieldType(
              MONITOR_ICON,
              val,
              getIconByValue(val) || <>&ndash;</>,
            )
          }
          field="monitorFontIcon"
        />
      );
    },
  },
  chanceFontIcon: {
    name: 'Chance',
    description: 'Chance',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Aktienmonitor',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return (
          (value &&
            renderIconsByFieldType(
              CHANCE_ICON,
              value,
              getIconByValue(value),
            )) ||
          null
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) =>
            (val &&
              renderIconsByFieldType(CHANCE_ICON, val, getIconByValue(val))) ||
            null
          }
          field="chanceFontIcon"
        />
      );
    },
  },
  sensitivityFontIcon: {
    name: 'Sensi tivität', // on classic we have Risiko
    description: 'Sensitivität',
    style: styles.TableRow,
    sortable: true,
    group: 'Aktienmonitor',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return (
          (value &&
            renderIconsByFieldType(
              SENSITIVITY_ICON,
              value,
              getIconByValue(value),
            )) ||
          null
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) =>
            (val &&
              renderIconsByFieldType(
                SENSITIVITY_ICON,
                val,
                getIconByValue(val),
              )) ||
            null
          }
          field="sensitivityFontIcon"
        />
      );
    },
  },
  relativePerformance: {
    name: 'Rel. Performance',
    description: '+/-% 4 Wochen gegenüber Index',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Aktienmonitor',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return (
          <span
            className={getTrendClass(value, styles.Positive, styles.Negative)}
          >
            {value && `${formatPercentageWithCache(value)}%`}
          </span>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return (
              <span
                className={getTrendClass(
                  `${val}`,
                  styles.Positive,
                  styles.Negative,
                )}
              >
                {val && `${formatPercentageWithCache(val)}%`}
              </span>
            );
          }}
          field="relativePerformance"
        />
      );
    },
  },
  kgv: {
    name: 'KGV',
    description: 'Langfristiges KGV (PE)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Aktienmonitor',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, 'Value');
      }
      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => formatPriceWithCache(val, 'Value')}
          field="kgv"
        />
      );
    },
  },
  lastDividend: {
    name: 'Letzte Dividende',
    description: 'Letzte Dividende',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Fundamentaldaten',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, 'Value');
      }
      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => formatPriceWithCache(val, 'Value')}
          field="lastDividend"
        />
      );
    },
  },
  lastDividendDatetime: {
    name: 'Dividende Datum',
    description: 'Dividende Datum',
    style: styles.TableRow,
    sortable: true,
    group: 'Fundamentaldaten',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return formatDateWithCache(value, DATE_FORMAT_SHORT);
    },
  },
  // todo: cleanup typo (dividenYield throughout graphql and solid-service)
  dividenYield: {
    name: 'Divid. Rendite',
    description: 'Dividenden Rendite',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Aktienmonitor',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return (
          <span
            className={getTrendClass(value, styles.Positive, styles.Negative)}
          >
            {value && `${formatPercentageWithCache(value)}%`}
          </span>
        );
      }
      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return (
              <span
                className={getTrendClass(
                  `${val}`,
                  styles.Positive,
                  styles.Negative,
                )}
              >
                {val && `${formatPercentageWithCache(val)}%`}
              </span>
            );
          }}
          field="dividenYield"
        />
      );
    },
  },
  yldeq: {
    name: 'Hist. Div.-Rendite',
    description: 'Hist. Dividenden-Rendite',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Fundamentaldaten',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return `${formatPercentageWithCache(value)}%`;
    },
  },
  perfPercentage1w: {
    name: '1W +/-%',
    description: '+/-% 1 Woche',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return (
          <span
            className={getTrendClass(value, styles.Positive, styles.Negative)}
          >
            {value && `${formatPercentageWithCache(value)}%`}
          </span>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return (
              val && (
                <span
                  className={getTrendClass(
                    `${val}`,
                    styles.Positive,
                    styles.Negative,
                  )}
                >
                  {`${formatPercentageWithCache(val)}%`}
                </span>
              )
            );
          }}
          field="perfPercentage1w"
        />
      );
    },
  },
  perfPercentage4w: {
    name: '4W +/-%',
    description: '+/-% 4 Wochen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return (
          <span
            className={getTrendClass(value, styles.Positive, styles.Negative)}
          >
            {value && `${formatPercentageWithCache(value)}%`}
          </span>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return (
              val && (
                <span
                  className={getTrendClass(
                    `${val}`,
                    styles.Positive,
                    styles.Negative,
                  )}
                >
                  {`${formatPercentageWithCache(val)}%`}
                </span>
              )
            );
          }}
          field="perfPercentage4w"
        />
      );
    },
  },
  perfPercentage12w: {
    name: '12W +/-%',
    description: '+/-% 12 Wochen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return (
          <span
            className={getTrendClass(value, styles.Positive, styles.Negative)}
          >
            {value && `${formatPercentageWithCache(value)}%`}
          </span>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return (
              val && (
                <span
                  className={getTrendClass(
                    `${val}`,
                    styles.Positive,
                    styles.Negative,
                  )}
                >
                  {`${formatPercentageWithCache(val)}%`}
                </span>
              )
            );
          }}
          field="perfPercentage12w"
        />
      );
    },
  },
  perfPercentage26w: {
    name: '26W +/-%',
    description: '+/-% 26 Wochen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return (
          <span
            className={getTrendClass(value, styles.Positive, styles.Negative)}
          >
            {value && `${formatPercentageWithCache(value)}%`}
          </span>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return (
              val && (
                <span
                  className={getTrendClass(
                    `${val}`,
                    styles.Positive,
                    styles.Negative,
                  )}
                >
                  {`${formatPercentageWithCache(val)}%`}
                </span>
              )
            );
          }}
          field="perfPercentage26w"
        />
      );
    },
  },
  perfPercentage52w: {
    name: '52W +/-%',
    description: '+/-% 52 Wochen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return (
          <span
            className={getTrendClass(value, styles.Positive, styles.Negative)}
          >
            {value && `${formatPercentageWithCache(value)}%`}
          </span>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return (
              val && (
                <span
                  className={getTrendClass(
                    `${val}`,
                    styles.Positive,
                    styles.Negative,
                  )}
                >
                  {`${formatPercentageWithCache(val)}%`}
                </span>
              )
            );
          }}
          field="perfPercentage52w"
        />
      );
    },
  },
  perfPercentageYTD: {
    name: 'YTD',
    description: '+/-% seit 1.1.',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return (
          <span
            className={getTrendClass(value, styles.Positive, styles.Negative)}
          >
            {value && `${formatPercentageWithCache(value)}%`}
          </span>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return (
              val && (
                <span
                  className={getTrendClass(
                    `${val}`,
                    styles.Positive,
                    styles.Negative,
                  )}
                >
                  {`${formatPercentageWithCache(val)}%`}
                </span>
              )
            );
          }}
          field="perfPercentageYTD"
        />
      );
    },
  },
  perfPercentage3Y: {
    name: '3J%',
    description: '% 3 Jahre',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return (
          <span
            className={getTrendClass(value, styles.Positive, styles.Negative)}
          >
            {value && `${formatPercentageWithCache(value)}%`}
          </span>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return (
              val && (
                <span
                  className={getTrendClass(
                    `${val}`,
                    styles.Positive,
                    styles.Negative,
                  )}
                >
                  {`${formatPercentageWithCache(val)}%`}
                </span>
              )
            );
          }}
          field="perfPercentage3Y"
        />
      );
    },
  },
  hi52w: {
    name: '52 W Hoch',
    description: '52 Wochen Hoch (per Vortag)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, instrument.scGrouped);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => formatPriceWithCache(val, instrument.scGrouped)}
          field="hi52w"
        />
      );
    },
  },
  lo52w: {
    name: '52 W Tief',
    description: '52 Wochen Tief (per Vortag)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, instrument.scGrouped);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => formatPriceWithCache(val, instrument.scGrouped)}
          field="lo52w"
        />
      );
    },
  },
  buyingDate: {
    name: 'Kaufdatum',
    description: 'Kaufdatum',
    style: styles.TableRow,
    sortable: true,
    group: 'Portfolio erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return <span>{formatDateWithCache(value, DATE_FORMAT_SHORT)}</span>;
    },
  },
  date: {
    name: 'Datum',
    description: 'Datum',
    style: styles.TableRow,
    sortable: false,
    formatter: ({ value, instrument }) => {
      if (
        instrument.identifier === TOTALS_ROW_IDENTIFIER &&
        instrument.name === 'Total'
      ) {
        return 'Total';
      }

      if (!value) {
        return null;
      }
      return <span>{formatDateWithCache(value, DATE_FORMAT_SHORT)}</span>;
    },
  },
  comment: {
    name: 'Kommentar',
    description: 'Kommentar',
    style: styles.CommentsWrapper,
    sortable: false,
    formatter: ({ value, instrument }) => {
      if (
        instrument.identifier === TOTALS_ROW_IDENTIFIER &&
        instrument.name === 'Total'
      ) {
        return ' ';
      }
      if (!value) {
        return null;
      }
      return <span>{value}</span>;
    },
  },
  fees: {
    name: 'Gebühr',
    description: 'Gebühr',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Portfolio',
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'UserInput');
    },
  },
  partInPercent: {
    name: 'Pf-Anteil %',
    description: 'Portfolio-Anteil in %',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Portfolio erweitert',
    formatter: ({ value, instrument }) => {
      if (
        (instrument.identifier === TOTALS_ROW_IDENTIFIER &&
          instrument.name === 'Total') ||
        (instrument.identifier === TOTALS_ROW_IDENTIFIER &&
          Number(value) * 100 >= 10000)
      ) {
        // calculating percentage values in JS is always a bit tricky, so we just return 100% for the total row. I could not find a solution that would not result in rounding errors.
        return '100.00%';
      } else if (instrument.identifier === TOTALS_ROW_IDENTIFIER) {
        return `${formatPercentageWithCache(value)}%`;
      }
      return `${formatPercentageWithCache(value)}%`;
    },
  },
  lval: {
    name: 'Aktuell',
    description: 'Bezahlter Kurs oder Bewertung',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday',
    // formatter: FormatterLval,
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, instrument.scGrouped);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => formatPriceWithCache(val, instrument.scGrouped)}
          field="lval"
        />
      );
    },
  },
  // LVAL_NORM
  currentValue: {
    name: 'Aktuell',
    // TODO: tbd when we defined the description
    description: 'Bezahlter Kurs oder Bewertung',
    style: DEFAULT_STYLES,
    sortable: true,
    // group: 'Intraday',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, instrument.scGrouped);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => formatPriceWithCache(val, instrument.scGrouped)}
          field="currentValue"
        />
      );
    },
  },
  lvalDatetime: {
    name: 'Aktuell Zeit',
    description: 'Zeit bezahlter Kurs oder Bewertung',
    style: styles.TableRow,
    sortable: true,
    group: 'Intraday',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return formatDateOrTimeWithCache(value);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          defaultUpdateStatus="neutral"
          formatFn={(v) => {
            let val = v;
            if (!val) {
              return null;
            }

            if (!isNaN(Number(val))) {
              val = Number(val) * 1000;
            }

            return formatDateOrTimeWithCache(val);
          }}
          field="lvalDatetime"
        />
      );
    },
  },
  dayBeforeDate: {
    name: 'Vortag Zeit',
    description: 'Zeit der Vortag oder Bewertung Vortag',
    style: styles.TableRow,
    sortable: true,
    group: 'Intraday',
    tooltip:
      'Tag an dem das Instrument zuletzt gehandelt wurde im Vergleich zum aktuellen Tag',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return formatDateOrTimeWithCache(value);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          defaultUpdateStatus="neutral"
          formatFn={(v) => {
            let val = v;

            if (!isNaN(Number(val))) {
              val = Number(val) * 1000;
            }

            return formatDateOrTimeWithCache(val);
          }}
          field="dayBeforeDate"
        />
      );
    },
  },
  dayBefore: {
    name: 'Vortag',
    description: 'Vortag oder Bewertung Vortag',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, instrument.scGrouped);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => formatPriceWithCache(val, instrument.scGrouped)}
          field="dayBefore"
        />
      );
    },
  },
  iNetVperprV: {
    name: '+/-',
    description: 'Veränderung',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      const intlFormatter = getFormatterByValue(
        instrument.lval,
        instrument.scGrouped,
      );

      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        const formattedPrice = formatPriceWithCache(
          value,
          'Volume',
          intlFormatter,
        );
        return (
          <span
            className={getTrendClass(
              `${value}`,
              styles.Positive,
              styles.Negative,
            )}
          >
            {!formattedPrice.startsWith('-')
              ? `${formattedPrice === '0' ? '' : '+'}${formattedPrice}`
              : formattedPrice}
          </span>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            if (!val) {
              return null;
            }
            const intlFormatter = getFormatterByValue(
              instrument.lval,
              instrument.scGrouped,
            );
            const formattedPrice = formatPriceWithCache(
              val,
              'Volume',
              intlFormatter,
            );
            return (
              <span
                className={getTrendClass(
                  `${val}`,
                  styles.Positive,
                  styles.Negative,
                )}
              >
                {!formattedPrice.startsWith('-')
                  ? `${formattedPrice === '0' ? '' : '+'}${formattedPrice}`
                  : formattedPrice}
              </span>
            );
          }}
          field="iNetVperprV"
        />
      );
    },
  },
  iNetVperprVPr: {
    name: '+/-%',
    description: 'Veränderung in %',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return (
          <span
            className={getTrendClass(value, styles.Positive, styles.Negative)}
          >
            {value &&
              `${formatPercentageWithCache(formatPriceWithCache(value))}%`}
          </span>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            if (!val) {
              return null;
            }
            return (
              <span
                className={getTrendClass(
                  `${val}`,
                  styles.Positive,
                  styles.Negative,
                )}
              >
                {val &&
                  `${formatPercentageWithCache(formatPriceWithCache(val))}%`}
              </span>
            );
          }}
          field="iNetVperprVPr"
        />
      );
    },
  },
  bid: {
    name: 'Geld',
    description: 'Geld',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday',
    tooltip:
      'Der Preis, zu dem ein Käufer bereit ist, ein bestimmtes Wertpapier oder Finanzprodukt zu kaufen.',
    formatter: (
      { value, instrument, fieldType = 'bid' },
      disableAutoUpdate = false,
    ) => {
      const isEmpty = (val, fieldType): boolean =>
        (!val || val === '0') && ['bid', 'bidValue1st'].includes(fieldType);

      if (disableAutoUpdate) {
        if (!value && !instrument.bidVolume) {
          return null;
        }

        if (isEmpty(value, fieldType)) {
          return 'Market';
        }
        return formatPriceWithCache(value, instrument.scGrouped);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            if (isEmpty(val, fieldType)) {
              return 'Market';
            }
            return formatPriceWithCache(val, instrument.scGrouped);
          }}
          field={fieldType}
        />
      );
    },
  },
  ask: {
    name: 'Brief',
    description: 'Brief',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday',
    tooltip:
      'Der Preis, zu dem ein Verkäufer bereit ist, ein bestimmtes Wertpapier oder Finanzprodukt zu verkaufen.',
    formatter: (
      { value, instrument, fieldType = 'ask' },
      disableAutoUpdate = false,
    ) => {
      const isEmpty = (val, fieldType): boolean =>
        (!val || val === '0') && ['ask', 'askValue1st'].includes(fieldType);

      if (disableAutoUpdate) {
        if (!value && !instrument.askVolume) {
          return null;
        }

        if (isEmpty(value, fieldType)) {
          return 'Market';
        }
        return formatPriceWithCache(value, instrument.scGrouped);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            if (isEmpty(val, fieldType)) {
              return 'Market';
            }
            return formatPriceWithCache(val, instrument.scGrouped);
          }}
          field={fieldType}
        />
      );
    },
  },
  high: {
    name: 'Hoch',
    description: 'Tageshoch',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return formatPriceWithCache(value, instrument.scGrouped);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) =>
            (val && formatPriceWithCache(val, instrument.scGrouped)) ||
            (value && formatPriceWithCache(value, instrument.scGrouped)) ||
            null
          }
          field="high"
        />
      );
    },
  },
  highDate: {
    name: 'Zeit Tageshoch',
    description: 'Zeit Tageshoch',
    style: styles.TableRow,
    sortable: true,
    group: 'Intraday',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        // check if date is today
        const today = new Date();
        const date = new Date(value);

        if (today.getDate() === date.getDate()) {
          return formatDateWithCache(value, DATE_FORMAT_FULL_TIME);
        }
        return formatDateWithCache(value, DATE_FORMAT_SHORT);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          defaultUpdateStatus="neutral"
          formatFn={(val) => {
            if (!val) {
              return null;
            }
            // check if date is today
            const today = new Date();
            const date = new Date(val);

            if (today.getDate() === date.getDate()) {
              return formatDateWithCache(val, DATE_FORMAT_FULL_TIME);
            }
            return formatDateWithCache(val, DATE_FORMAT_SHORT);
          }}
          field="highDate"
        />
      );
    },
  },
  low: {
    name: 'Tief',
    description: 'Tagestief',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return formatPriceWithCache(value, instrument.scGrouped);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => formatPriceWithCache(val, instrument.scGrouped)}
          field="low"
        />
      );
    },
  },
  lowDate: {
    name: 'Zeit Tagestief',
    description: 'Zeit Tagestief',
    style: styles.TableRow,
    sortable: true,
    group: 'Intraday',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        // check if date is today
        const today = new Date();
        const date = new Date(value);

        if (today.getDate() === date.getDate()) {
          return formatDateWithCache(value, DATE_FORMAT_FULL_TIME);
        }
        return formatDateWithCache(value, DATE_FORMAT_SHORT);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          defaultUpdateStatus="neutral"
          formatFn={(val) => {
            if (!val) {
              return null;
            }
            // check if date is today
            const today = new Date();
            const date = new Date(val);

            if (today.getDate() === date.getDate()) {
              return formatDateWithCache(val, DATE_FORMAT_FULL_TIME);
            }
            return formatDateWithCache(val, DATE_FORMAT_SHORT);
          }}
          field="lowDate"
        />
      );
    },
  },
  bidVolume: {
    name: 'Geld Volumen',
    description: 'Geld Volumen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday erweitert',
    formatter: (
      { value, instrument, fieldType = 'bidVolume' },
      disableAutoUpdate = false,
    ) => {
      if (disableAutoUpdate) {
        if (isNaN(value)) {
          return null;
        }
        return formatPriceWithCache(value, 'Volume');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          defaultUpdateStatus="neutral"
          formatFn={(val) => {
            if (isNaN(Number(val))) {
              return value;
            }
            return formatPriceWithCache(val, 'Volume');
          }}
          field={fieldType}
        />
      );
    },
  },
  bidDatetime: {
    name: 'Geld Zeit',
    description: 'Geld Zeit',
    style: styles.TableRow,
    sortable: true,
    group: 'Intraday erweitert',
    formatter: (
      { value, instrument, fieldType = 'bidDatetime' },
      disableAutoUpdate = false,
    ) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return formatDateOrTimeWithCache(value);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          defaultUpdateStatus="neutral"
          formatFn={(v) => {
            let val = v;

            if (!isNaN(Number(val))) {
              val = Number(val) * 1000;
            }

            return formatDateOrTimeWithCache(val);
          }}
          field={fieldType}
        />
      );
    },
  },
  askVolume: {
    name: 'Brief Volumen',
    description: 'Brief Volumen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday erweitert',
    formatter: (
      { value, instrument, fieldType = 'askVolume' },
      disableAutoUpdate = false,
    ) => {
      if (disableAutoUpdate) {
        if (isNaN(value)) {
          return null;
        }
        return formatPriceWithCache(value, 'Volume');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          defaultUpdateStatus="neutral"
          formatFn={(val) => {
            if (isNaN(Number(val))) {
              return value;
            }
            return formatPriceWithCache(val, 'Volume');
          }}
          field={fieldType}
        />
      );
    },
  },

  askDatetime: {
    name: 'Brief Zeit',
    description: 'Brief Zeit',
    style: styles.TableRow,
    sortable: true,
    group: 'Intraday erweitert',
    formatter: (
      { value, instrument, fieldType = 'askDatetime' },
      disableAutoUpdate = false,
    ) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return formatDateOrTimeWithCache(value);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          defaultUpdateStatus="neutral"
          formatFn={(v) => {
            let val = v;

            if (!isNaN(Number(val))) {
              val = Number(val) * 1000;
            }

            return formatDateOrTimeWithCache(val);
          }}
          field={fieldType}
        />
      );
    },
  },
  open: {
    name: 'Eröffnung',
    description: 'Eröffnung',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday erweitert',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return formatPriceWithCache(value, instrument.scGrouped);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => formatPriceWithCache(val, instrument.scGrouped)}
          field="open"
        />
      );
    },
  },
  openDatetime: {
    name: 'Eröffnung Zeit',
    description: 'Eröffnung Zeit',
    style: styles.TableRow,
    sortable: true,
    group: 'Intraday erweitert',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!value) {
          return null;
        }
        return formatDateOrTimeWithCache(value);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          defaultUpdateStatus="neutral"
          formatFn={(val) => {
            if (!val) {
              return null;
            }

            if (!isNaN(Number(val))) {
              val = Number(val) * 1000;
            }

            return formatDateOrTimeWithCache(val);
          }}
          field="openDatetime"
        />
      );
    },
  },
  lvalVolume: {
    name: 'Volumen',
    description: 'Volumen Letzter Kurs',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Intraday erweitert',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        if (!Number(value) || value === 'NaN') {
          return '';
        }
        return formatPriceWithCache(value, 'Volume');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => formatPriceWithCache(val, 'Volume')}
          field="lvalVolume"
        />
      );
    },
  },
  maturity: {
    name: 'Fälligkeit',
    description: 'Fälligkeit (nur für Obligationen)',
    style: styles.TableRow,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }

      return formatDateWithCache(value, DATE_FORMAT_SHORT);
    },
  },
  callbyissuer: {
    name: 'Kündbar Emittent ja/nein?',
    description: 'Kündigungsrecht Emittent (nur für Obligationen)',
    style: styles.TableRow,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }

      if (value === 'Y') {
        return 'Ja';
      }

      if (value === 'N') {
        return 'Nein';
      }
    },
  },
  callbyholder: {
    name: 'Kündbar Emittent ja/nein?',
    description: 'Kündigungsrecht Gläubiger (nur für Obligationen)',
    style: styles.TableRow,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }

      if (value === 'Y') {
        return 'Ja';
      }

      if (value === 'N') {
        return 'Nein';
      }
    },
  },
  rlife: {
    name: 'Rest-Laufzeit',
    description: 'Rest-Laufzeit in Jahren (nur für Obligationen)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'Value');
    },
  },
  interest: {
    name: 'Zinssatz',
    description: 'Zinssatz (p.a.) (nur für Obligationen)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      // TODO: check if the formatting is correct here
      return value && `${formatPriceWithCache(value, 'Value')}%`;
    },
  },
  yield: {
    name: 'Verfallrendite (Yield)',
    description: 'Verfallrendite (Yield) (nur für Obligationen)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return value && `${formatPriceWithCache(value, 'Value')}%`;
    },
  },
  cyield: {
    name: 'Direkte Rendite',
    description: 'Direkte Rendite (nur für Obligationen)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return value && `${formatPriceWithCache(value, 'Value')}%`;
    },
  },
  mdur: {
    name: 'Modified Duration',
    description: 'Modified Duration (nur für Obligationen)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'Value');
    },
  },
  pcalc: {
    name: 'Preis Berechnung',
    description: 'Preis Berechnung (nur für Obligationen)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value, instrument }) => {
      return formatPriceWithCache(value, instrument.scGrouped);
    },
  },
  strike: {
    name: 'Strike',
    description: 'Strike (nur für Derivate)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value, instrument }) => {
      return formatPriceWithCache(value, instrument.scGrouped);
    },
  },
  leadMan: {
    name: 'Gesellschaft',
    description: 'Gesellschaft',
    style: styles.TableRow,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return value;
    },
  },
  eusipaId: {
    name: 'SVSP-ID',
    description: 'Instrument-Klassifizierung/Kategorie (nur für Derivate)',
    style: styles.TableRow,
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      // most probably the only unformatted "number" -> category Text
      return value;
    },
  },
  perf1w: {
    name: '+/- 1W',
    description: 'Schlusskurs 1 Woche',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value, instrument }) => {
      return headerMapping['iNetVperprV'].formatter(
        {
          instrument,
          value: value,
        },
        true,
      );
    },
  },
  perf4w: {
    name: '+/- 4W',
    description: 'Schlusskurs 4 Woche',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value, instrument }) => {
      return headerMapping['iNetVperprV'].formatter(
        {
          instrument,
          value: value,
        },
        true,
      );
    },
  },
  perf12w: {
    name: '+/- 12W',
    description: 'Schlusskurs 12 Woche',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value, instrument }) => {
      return headerMapping['iNetVperprV'].formatter(
        {
          instrument,
          value: value,
        },
        true,
      );
    },
  },
  perf26w: {
    name: '+/- 26W',
    description: 'Schlusskurs 26 Woche',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value, instrument }) => {
      return headerMapping['iNetVperprV'].formatter(
        {
          instrument,
          value: value,
        },
        true,
      );
    },
  },
  perf52w: {
    name: '+/- 52W',
    description: 'Schlusskurs 52 Woche',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value, instrument }) => {
      return headerMapping['iNetVperprV'].formatter(
        {
          instrument,
          value: value,
        },
        true,
      );
    },
  },
  hi52wDatetime: {
    name: 'Datum 52 W Hoch',
    description: 'Datum 52 Wochen Hoch (per Vortag)',
    style: styles.TableRow,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        // atm we sometimes seem to get bad data from SOLID like on BTC (999999915312-9910014-333), we just treat 1970 as no data
        if (!value || new Date(value).getFullYear() === 1970) {
          return null;
        }
        return formatDateOrTimeWithCache(value, true);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          defaultUpdateStatus="neutral"
          formatFn={(val) => {
            if (new Date(val).getFullYear() === 1970) {
              return null;
            }
            if (!val) {
              return (value && formatDateOrTimeWithCache(value, true)) || null;
            }

            return formatDateOrTimeWithCache(val, true);
          }}
          field="hi52wDatetime"
        />
      );
    },
  },
  lo52wDatetime: {
    name: 'Datum 52 W Tief',
    description: 'Datum 52 Wochen Tief (per Vortag)',
    style: styles.TableRow,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        // atm we sometimes seem to get bad data from SOLID like on BTC (999999915312-9910014-333), we just treat 1970 as no data
        if (!value || new Date(value).getFullYear() === 1970) {
          return null;
        }
        return formatDateOrTimeWithCache(value, true);
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          defaultUpdateStatus="neutral"
          formatFn={(val) => {
            if (new Date(val).getFullYear() === 1970) {
              return null;
            }
            if (!val) {
              return (value && formatDateOrTimeWithCache(value, true)) || null;
            }

            return formatDateOrTimeWithCache(val, true);
          }}
          field="lo52wDatetime"
        />
      );
    },
  },
  yHi: {
    name: 'Jahreshoch',
    description: 'Hoch aktuelles Jahr (per Vortag)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value, instrument }) => {
      return formatPriceWithCache(value, instrument.scGrouped);
    },
  },
  yHiDatetime: {
    name: 'Datum Jahreshoch',
    description: 'Datum Jahreshoch (per Vortag)',
    style: styles.TableRow,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }

      return formatDateOrTimeWithCache(value);
    },
  },
  yLo: {
    name: 'Jahrestief',
    description: 'Tief aktuelles Jahr (per Vortag)',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value, instrument }) => {
      return formatPriceWithCache(value, instrument.scGrouped);
    },
  },
  yLoDatetime: {
    name: 'Datum Jahrestief',
    description: 'Datum Jahrestief (per Vortag)',
    style: styles.TableRow,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }

      return formatDateOrTimeWithCache(value);
    },
  },
  pyClose: {
    name: 'Schlusskurs 31.12.',
    description: 'Schlusskurs 31.12.',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Kursentwicklung erweitert',
    formatter: ({ value, instrument }) => {
      return formatPriceWithCache(value, instrument.scGrouped);
    },
  },
  totvol: {
    name: 'Volumen Total',
    description: 'Heutiges Volumen (Stk) in Millionen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, 'Volume');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatPriceWithCache(val, 'Volume');
          }}
          field="totvol"
        />
      );
    },
  },
  tottur: {
    name: 'Umsatz Total',
    description: 'Heutiger Umsatz in Millionen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, 'default');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatPriceWithCache(val, 'default');
          }}
          field="tottur"
        />
      );
    },
  },
  avVol12w: {
    name: <>&oslash; Vol. 3 Monate</>,
    description: 'Durchschnittliches Volumen 3 Monate in Millionen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'Volume');
    },
  },
  vol: {
    name: 'Vol. Börse',
    description: 'Volumen (Stk) an der Börse',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    tooltip:
      'Die Gesamtanzahl der gehandelten Wertpapiere innerhalb eines einzigen Handelstages. Das Tagesvolumen ist ein wichtiger Indikator für die Aktivität und Liquidität eines bestimmten Wertpapiers oder eines gesamten Marktes.',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, 'Volume');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatPriceWithCache(val, 'Volume');
          }}
          field="vol"
        />
      );
    },
  },
  offvol: {
    name: 'Vol. Rest',
    description: 'Volumen (Stk) ausserhalb Börse in Millionen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, 'Volume');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatPriceWithCache(val, 'Volume');
          }}
          field="offvol"
        />
      );
    },
  },
  pOffvol: {
    name: 'Vol. Rest Vortag',
    description: 'Vortag Volumen (Stk) ausserhalb Börse in Millionen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, 'Volume');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatPriceWithCache(val, 'Volume');
          }}
          field="pOffvol"
        />
      );
    },
  },
  pVol: {
    name: 'Vol. Vortag Börse',
    description: 'Volumen (Stk) an der Börse',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, 'Volume');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatPriceWithCache(val, 'Volume');
          }}
          field="pVol"
        />
      );
    },
  },
  pTur: {
    name: 'Umsatz Vortag Total',
    description: 'Vortag Umsatz in Millionen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, 'default');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatPriceWithCache(val, 'default');
          }}
          field="pTur"
        />
      );
    },
  },
  pmAvVol: {
    name: 'Ø Vol. Vormonat',
    description: 'Durchschnittliches Volumen Vormonat in Millionen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, 'Volume');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatPriceWithCache(val, 'Volume');
          }}
          field="pmAvVol"
        />
      );
    },
  },
  tur: {
    name: 'Ums. Börse',
    description: 'Umsatz an der Börse',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    tooltip: 'Tagesvolumen multipliziert mit dem Preis.',
    formatter: ({ value, instrument, disableAutoUpdate }) => {
      if (disableAutoUpdate) {
        return formatPriceWithCache(value, 'default');
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatPriceWithCache(val, 'default');
          }}
          field="tur"
        />
      );
    },
  },
  offtur: {
    name: 'Ums. Rest',
    description: 'Umsatz ausserhalb der Börse in Millionen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'default');
    },
  },
  pOfftur: {
    name: 'Ums. Rest Vortag',
    description: 'Vortag Umsatz ausserhalb der Börse in Millionen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'default') || '–';
    },
  },
  volDatetime: {
    name: 'Vol. Datum',
    description: 'Volumen Datum',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ instrument, value }) => {
      if (!value) {
        return null;
      }
      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatDateOrTimeWithCache(val);
          }}
          field="volDatetime"
        />
      );
    },
  },
  totvolDatetime: {
    name: 'Datum Volumen Total',
    description: 'DatumHeutiges Volumen (Stk) in Millionen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ instrument, value }) => {
      if (!value) {
        return null;
      }
      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatDateOrTimeWithCache(val);
          }}
          field="totvolDatetime"
        />
      );
    },
  },
  pOffvolDatetime: {
    name: 'Datum Vol. Rest Vortag',
    description: 'Datum Vortag Volumen (Stk) ausserhalb Börse in Millionen',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ instrument, value }) => {
      if (!value) {
        return null;
      }
      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatDateOrTimeWithCache(val);
          }}
          field="pOffvolDatetime"
        />
      );
    },
  },
  pVolDatetime: {
    name: 'Datum Vol. Vortag Börse',
    description: 'Datum Volumen (Stk) an der Börse',
    style: DEFAULT_STYLES,
    sortable: true,
    group: 'Volumen',
    formatter: ({ instrument, value }) => {
      if (!value) {
        return null;
      }
      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) => {
            return formatDateOrTimeWithCache(val);
          }}
          field="pVolDatetime"
        />
      );
    },
  },
  convexity: {
    name: 'Konv.',
    description: 'Konvexität',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      // TODO: check if the formatting is correct here
      return formatPrice(value, 'Value');
    },
  },
  dayCountConvention: {
    name: 'Zinsber.',
    description: 'Zinsberechnungsmethode',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return value;
    },
  },
  denomination: {
    name: 'Stk.',
    description: 'Stückelung/Nennwert',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return formatPrice(value);
    },
  },
  dividendPolicy: {
    name: 'Gewinnverw.',
    description: 'Gewinnverwendung (nur für Fonds)',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  companyFullName: {
    name: 'Emittent',
    description: 'Emittent',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  domicile: {
    name: 'Dom.',
    description: 'Domizil',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  duration: {
    name: 'Dauer',
    description: 'Dauer',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return formatPrice(value, 'Value');
    },
  },
  exchangeDomicile: {
    name: 'Dom. Bö.',
    description: 'Domizil Börse',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  instrumentType: {
    name: 'Instr. Typ',
    description: 'Instrumententyp',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  instrumentUnit: {
    name: 'Einheit',
    description: 'Einheit des Instruments',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  interestDate: {
    name: 'Zins Dat.',
    description: 'Zinsdatum',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return <span>{formatDate(value, DATE_FORMAT_SHORT)}</span>;
    },
  },
  issueAmt: {
    name: 'Em Vol. Mia.',
    description: 'Emissionsvolumen in Mia.',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return formatPrice(value / 1_000_000_000);
    },
  },
  issueDate: {
    name: 'Em. Dat.',
    description: 'Emissionsdatum',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return <span>{formatDate(value, DATE_FORMAT_SHORT)}</span>;
    },
  },
  issuePrice: {
    name: 'Em. Preis',
    description: 'Emissionspreis',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return <span>{formatPrice(value, 'Value')}</span>;
    },
  },
  issueVolume: {
    name: 'Em. Vol.',
    description: 'Emissionsvolumen',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return <span>{formatPrice(value, 'Volume')}</span>;
    },
  },
  lastTradingDate: {
    name: 'Letzter Handelstag',
    description: 'Letzter Handelstag',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return <span>{formatDate(value, DATE_FORMAT_SHORT)}</span>;
    },
  },
  marketCap: {
    name: 'Mk. Cap.',
    description: 'Market Cap.',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return <span>{formatPrice(value, 'default')}</span>;
    },
  },
  nominal: {
    name: 'Nom.',
    description: 'Nominalwert',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return <span>{formatPrice(value, 'Value')}</span>;
    },
  },
  nominalCurrency: {
    name: 'Nom. Whg.',
    description: 'Nominalwährung',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return value;
    },
  },
  paymentFrequency: {
    name: 'Zah. Häuf.',
    description: 'Zinshäufigkeit',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return value;
    },
  },
  paymentFrequencyUnit: {
    name: 'Dom.',
    description: 'Einheit Verzinsungsfrequenz',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return value;
    },
  },
  redemptionPayment: {
    name: 'Rückzahlung',
    description: 'Rückzahlung',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return formatPrice(value);
    },
  },
  sector: {
    name: 'Sec.',
    description: 'Sektor',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  seniority: {
    name: 'Sen.',
    description: 'Seniorität',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return value;
    },
  },
  tradedShares: {
    name: 'Gehandelte',
    description: 'Gehandelte Wertpapiere',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      if (!value) {
        return null;
      }
      return <span>{formatPrice(value, 'Volume')}</span>;
    },
  },
  unit: {
    name: 'Einheit',
    description: 'Einheit',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  wkn: {
    name: 'WKN',
    description: 'WKN',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  compFullName: {
    name: 'Emittent',
    description: 'Emittent',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Stammdaten',
    formatter: ({ value }) => {
      return value;
    },
  },
  accInt: {
    name: 'Aufgel. Zinsen',
    description: 'Aufgelaufene Zinsen',
    style: classNames(styles.TableRow),
    sortable: true,
    group: 'Fundamentaldaten erweitert',
    formatter: ({ value }) => {
      return `${formatPrice(value, 'Value')}%`;
    },
  },
  lastToggleItem: {
    name: '',
    style: classNames(styles.TableRow, styles.Last),
    sortable: false,
  },
  trendArrow: {
    name: 'Trend',
    description: 'Trend',
    style: classNames(styles.TableRow, styles.Center),
    sortable: true,
    group: 'Intraday',
    valueOverride: 'iNetVperprVPr',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return (
          <>
            {value && parseFloat(value) !== 0 && (
              <Icon
                addClass={getTrendClass(
                  `${value}`,
                  classNames(styles.Positive, styles.Up),
                  classNames(styles.Negative, styles.Down),
                )}
                type={'IconArrowRight'}
              />
            )}
          </>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) =>
            val &&
            parseFloat(`${val}`) !== 0 && (
              <Icon
                addClass={getTrendClass(
                  `${val}`,
                  classNames(styles.Positive, styles.Up),
                  classNames(styles.Negative, styles.Down),
                )}
                type={'IconArrowRight'}
              />
            )
          }
          field="trendArrow"
        />
      );
    },
  },
  trendArrowPerfPercentage: {
    name: 'Trend',
    description: 'Trend',
    style: classNames(styles.TableRow, styles.Center),
    sortable: true,
    group: null,
    valueOverride: 'perfPercentage',
    formatter: ({ value, instrument }, disableAutoUpdate = false) => {
      if (disableAutoUpdate) {
        return (
          <>
            {value && parseFloat(value) !== 0 && (
              <Icon
                addClass={getTrendClass(
                  `${value}`,
                  classNames(styles.Positive, styles.Up),
                  classNames(styles.Negative, styles.Down),
                )}
                type={'IconArrowRight'}
              />
            )}
          </>
        );
      }

      return (
        <InstrumentField
          instrumentKey={instrument.instrumentKey}
          initialValue={value}
          formatFn={(val) =>
            val &&
            parseFloat(`${val}`) !== 0 && (
              <Icon
                addClass={getTrendClass(
                  `${val}`,
                  classNames(styles.Positive, styles.Up),
                  classNames(styles.Negative, styles.Down),
                )}
                type={'IconArrowRight'}
              />
            )
          }
          field="trendArrowPerfPercentage"
        />
      );
    },
  },
  // used for transactions overview
  price: {
    name: 'Preis',
    description: '',
    style: DEFAULT_STYLES,
    sortable: true,
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'UserInput');
    },
  },
  // used for alerts overview
  value: {
    name: 'Alert Wert',
    description: 'Alert Wert ',
    style: DEFAULT_STYLES,
    sortable: true,
    formatter: ({ value }) => {
      return formatPriceWithCache(value, 'UserInput');
    },
  },
  // used for alerts overview
  type: {
    name: 'Type',
    description: 'Type',
    style: styles.TableRow,
    sortable: true,
    formatter: ({ value, instrument }) => {
      return (
        <>
          {(value === 'UPPER' && (
            <div
              className={classNames(styles.UpperAlert, {
                [styles.IsBroken]: instrument?.status === 'BROKEN',
              })}
            >
              <Icon type="IconArrowUp"></Icon> oberer
            </div>
          )) || (
            <div
              className={classNames(styles.LowerAlert, {
                [styles.IsBroken]: instrument?.status === 'BROKEN',
              })}
            >
              <Icon type="IconArrowDown"></Icon> unterer
            </div>
          )}
        </>
      );
    },
  },
  // used for alerts overview overview
  expiration: {
    name: 'Gültig Bis',
    description: 'Gültig Bis ',
    style: styles.TableRow,
    sortable: true,
    formatter: ({ value }) => {
      return formatDateWithCache(new Date(value).getTime(), DATE_FORMAT_SHORT);
    },
  },
  // used for alerts overview overview
  brokenTime: {
    name: 'Ausgelöst',
    description: 'Ausgelöst',
    style: styles.TableRow,
    sortable: true,
    formatter: ({ value }) => {
      return (
        (value && formatDateOrTimeWithCache(new Date(value).getTime())) || null
      );
    },
  },
  // used for alerts overview overview
  receiveType: {
    name: 'Kanal',
    description: 'Kanal',
    style: styles.TableRow,
    sortable: true,
  },
  chartColors: {
    name: '',
    description: '',
    style: styles.TableRow,
    formatter: ({ instrument, rowIndex }) => {
      return (
        <ColorTableCell
          rowIndex={rowIndex}
          listingId={instrument.instrumentKey}
        />
      );
    },
  },
  // used for cashItems overview
  amount: {
    name: 'Betrag',
    description: 'Betrag ',
    style: DEFAULT_STYLES,
    sortable: false,
    formatter: ({ value, instrument }) => {
      if (
        instrument.identifier === TOTALS_ROW_IDENTIFIER &&
        instrument.name === 'Total'
      ) {
        return formatPriceWithCache(instrument.total, 'Value');
      }
      return formatPriceWithCache(value, 'Value');
    },
  },
  // used for cashItems overview
  instrumentTitle: {
    name: 'Title',
    description: 'Title',
    style: styles.TableRow,
    sortable: false,
    formatter: ({ value, instrument }) => {
      let label = value;

      if (instrument?.amount > 0 && !label) {
        label = 'Einzahlung';
      } else if (instrument?.amount < 0 && !label) {
        label = 'Auszahlung';
      }

      if (
        instrument?.identifier === TOTALS_ROW_IDENTIFIER ||
        (instrument.otherAsset && !instrument?.fullquoteUri)
      ) {
        return (
          <div className={styles.NameCell}>
            <span title={label}>{label}</span>
          </div>
        );
      }
      return (
        <div className={styles.NameCell}>
          <Link
            className={classNames({
              [styles.Link]: !instrument.otherAsset && instrument?.fullquoteUri,
            })}
            path={
              (!instrument.otherAsset &&
                instrument?.fullquoteUri &&
                `/${instrument?.fullquoteUri}`) ||
              ''
            }
            label={label}
            title={label}
          />
        </div>
      );
    },
  },
} as const;

import { pianoOfferConfig } from '../config';

// TODO: move config switch to our env files so we don't need a if statement by __DOT_ENV__ here
export const showPianoOfferTemplate = (title: string) => {
  const templateId =
    pianoOfferConfig[__DOT_ENV__ === 'master' ? 'production' : 'sandbox'][
      title.toLowerCase()
    ].templateId;

  const offerId =
    pianoOfferConfig[__DOT_ENV__ === 'master' ? 'production' : 'sandbox'][
      title.toLowerCase()
    ].offerId;

  const templateVariantId =
    pianoOfferConfig[__DOT_ENV__ === 'master' ? 'production' : 'sandbox'][
      title.toLowerCase()
    ].variantId;

  if (global?.tp?.offer) {
    global.tp.offer.show({
      templateId,
      offerId,
      templateVariantId,
      displayMode: 'modal',
    });
  }
};

import locationStateSelector from '../../../shared/selectors/locationStateSelector';
import windowStateSelector from '../../../shared/selectors/windowStateSelector';
import withAppNexusFactory from '../../../shared/decorators/withAppNexusFactory';
import {
  mapPathSegmentFullPath,
  mapPathSegmentToVertical,
} from '../reducers/route';
import { DEFAULT, HOME, VERTICAL_TITLES } from '../actions/route';

const mapVerticalToAdCategory = () => ({
  ...VERTICAL_TITLES,
  [DEFAULT]: 'ROS',
  [HOME]: 'Home',
});

export default withAppNexusFactory({
  locationStateSelector,
  windowStateSelector,
  mapPathSegmentToVertical,
  mapPathSegmentToOnmeda: mapPathSegmentFullPath,
  mapVerticalToAdCategory,
  defaultVertical: DEFAULT,
});

import React from 'react';
import classNames from 'classnames';
import Img from '../../../../../Img';
import modal from '../../../../../Modal';
import styles from './styles.legacy.css';
import { SelectOption } from '../SelectField/typings';

type SelectDropdownProps = {
  options: SelectOption[];
  setCurrentSelect: (option: SelectOption) => void;
  setIsSelectOpen: (boolan: boolean) => void;
  onChange: (option: SelectOption) => void;
};

const SelectDropdown = ({
  options,
  setCurrentSelect,
  setIsSelectOpen,
  onChange,
}: SelectDropdownProps) => {
  const isMobile = global.innerWidth < 760;

  const handleUpdate = (event, option: SelectOption) => {
    event.preventDefault();
    setCurrentSelect(option);
    setIsSelectOpen(false);
    onChange(option);
  };

  const renderOptions = (close = () => null) =>
    options.map((option: SelectOption, index) => {
      return (
        <button
          key={`webform-select-option-${index}`}
          className={classNames(styles.OptionItem)}
          tabIndex={0}
          onClick={(event) => {
            handleUpdate(event, option);
            close();
          }}
          role="option"
          aria-selected="false"
        >
          <>
            <Img url={option?.flag?.url} addClass={option?.flag?.styles} />
            {option?.value}
          </>
        </button>
      );
    });

  if (isMobile) {
    return (
      <>
        {modal({
          type: 'drawer',
          hasStickyHeader: true,
          title: 'Währungen',
          hasStickyFooter: false,
          hideDefaultButtons: true,
          customUi: ({ close }) => {
            return (
              <div className={styles.DrawerWrapper}>{renderOptions(close)}</div>
            );
          },
        })}
      </>
    );
  }

  return <>{renderOptions()}</>;
};

export default SelectDropdown;

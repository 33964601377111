import styles from './styles.legacy.css';
import AustraliaFlag from './assets/australia.png';
import CanadaFlag from './assets/canada.png';
import DenmarkFlag from './assets/denmark.png';
import EuropeFlag from './assets/europe.png';
import GreatBritainFlag from './assets/greatbritain.png';
import HongkongFlag from './assets/hongkong.png';
import JapanFlag from './assets/japan.png';
import NorwegenFlag from './assets/norwegen.png';
import SingapurFlag from './assets/singapur.png';
import SwedenFlag from './assets/sweden.png';
import SwitzerlandFlag from './assets/switzerland.png';
import TurkeyFlag from './assets/turkey.png';
import UsaFlag from './assets/usa.png';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [
  styles.Wrapper,
  styles.ContentWrapper,
  styles.Title,
  styles.FormWrapper,
  styles.InputRow,
  styles.SwitchButtons,
  styles.Icon,
  styles.InputFieldWrapper,
  styles.SelectFieldWrapper,
  styles.Link,
  styles.CurrencyInfoWrapper,
  styles.SkeletonWrapper,
  styles.Hidden,
  styles.SmallLayout,
];

export const listingKeysMapping = {
  AUD: {
    'AUD/CAD': '275722-149-184',
    'AUD/CHF': '499048-149-1',
    'AUD/EUR': '968876-149-814',
    'AUD/DKK': '275307-149-272',
    'AUD/GBP': '275682-149-402',
    'AUD/HKD': '340944-149-470',
    'AUD/NOK': '1117561-149-662',
    'AUD/SEK': '1117632-149-864',
    'AUD/SGD': '341762-149-846',
    'AUD/TRY': '1992428-149-921',
    'AUD/USD': '275125-149-333',
  },
  CAD: {
    'CAD/AUD': '275719-149-88',
    'CAD/CHF': '275003-149-1',
    'CAD/EUR': '968879-149-814',
    'CAD/DKK': '275306-149-272',
    'CAD/GBP': '275680-149-402',
    'CAD/HKD': '1117451-149-470',
    'CAD/JPY': '275681-149-534',
    'CAD/NOK': '1117446-149-662',
    'CAD/SEK': '1117448-149-864',
    'CAD/SGD': '1117457-149-846',
    'CAD/TRY': '1992384-149-921',
    'CAD/USD': '275114-149-333',
  },
  CHF: {
    'CHF/AUD': '275276-149-88',
    'CHF/CAD': '275270-149-184',
    'CHF/EUR': '968880-149-814',
    'CHF/DKK': '275757-149-272',
    'CHF/GBP': '274248-149-402',
    'CHF/HKD': '611306-149-470',
    'CHF/JPY': '275327-149-534',
    'CHF/NOK': '157937-149-662',
    'CHF/SEK': '158124-149-864',
    'CHF/SGD': '611786-149-846',
    'CHF/TRY': '1992411-149-921',
    'CHF/USD': '275164-149-333',
  },
  DKK: {
    'DKK/AUD': '275740-149-88',
    'DKK/CAD': '275739-149-184',
    'DKK/CHF': '275015-149-1',
    'DKK/EUR': '968882-149-814',
    'DKK/GBP': '275698-149-402',
    'DKK/HKD': '614554-149-470',
    'DKK/JPY': '275699-149-534',
    'DKK/NOK': '1117502-149-662',
    'DKK/SEK': '1117510-149-864',
    'DKK/SGD': '614556-149-846',
    'DKK/TRY': '1992390-149-921',
    'DKK/USD': '275697-149-333',
  },
  EUR: {
    'EUR/AUD': '946687-149-88',
    'EUR/CAD': '946690-149-184',
    'EUR/CHF': '897789-149-1',
    'EUR/DKK': '946836-149-272',
    'EUR/GBP': '946684-149-402',
    'EUR/HKD': '946843-149-470',
    'EUR/JPY': '946686-149-534',
    'EUR/NOK': '946867-149-662',
    'EUR/SEK': '946692-149-864',
    'EUR/SGD': '946870-149-846',
    'EUR/TRY': '1991307-149-921',
    'EUR/USD': '946681-149-333',
  },
  GBP: {
    'GBP/AUD': '275200-149-88',
    'GBP/CAD': '275196-149-184',
    'GBP/CHF': '275001-149-1',
    'GBP/DKK': '275191-149-272',
    'GBP/EUR': '947552-149-814',
    'GBP/HKD': '275198-149-470',
    'GBP/JPY': '275195-149-534',
    'GBP/NOK': '275192-149-662',
    'GBP/SEK': '275193-149-864',
    'GBP/SGD': '275199-149-846',
    'GBP/TRY': '1991436-149-921',
    'GBP/USD': '275017-149-333',
  },
  HKD: {
    'HKD/AUD': '341786-149-88',
    'HKD/CAD': '614662-149-184',
    'HKD/CHF': '506042-149-1',
    'HKD/DKK': '1117696-149-272',
    'HKD/EUR': '968893-149-814',
    'HKD/GBP': '1117694-149-402',
    'HKD/JPY': '329050-149-534',
    'HKD/NOK': '1117699-149-662',
    'HKD/SEK': '1117701-149-864',
    'HKD/SGD': '341755-149-846',
    'HKD/TRY': '1992402-149-921',
    'HKD/USD': '610491-149-333',
  },
  JPY: {
    'JPY/AUD': '275275-149-88',
    'JPY/CAD': '275268-149-184',
    'JPY/CHF': '275018-149-1',
    'JPY/DKK': '275749-149-272',
    'JPY/EUR': '968902-149-814',
    'JPY/GBP': '275322-149-402',
    'JPY/HKD': '340808-149-470',
    'JPY/NOK': '209686-149-662',
    'JPY/SEK': '209586-149-864',
    'JPY/SGD': '341737-149-846',
    'JPY/TRY': '1992422-149-921',
    'JPY/USD': '275032-149-333',
  },

  NOK: {
    'NOK/AUD': '1117554-149-88',
    'NOK/CAD': '1117466-149-184',
    'NOK/CHF': '275014-149-1',
    'NOK/DKK': '1117533-149-272',
    'NOK/EUR': '968927-149-814',
    'NOK/GBP': '1117493-149-402',
    'NOK/HKD': '614569-149-470',
    'NOK/JPY': '1117543-149-534',
    'NOK/SEK': '1126404-149-864',
    'NOK/SGD': '614571-149-846',
    'NOK/TRY': '1992392-149-921',
    'NOK/USD': '610485-149-333',
  },
  SEK: {
    'SEK/AUD': '1117583-149-88',
    'SEK/CAD': '497977-149-184',
    'SEK/CHF': '275013-149-1',
    'SEK/DKK': '1117535-149-272',
    'SEK/EUR': '968934-149-814',
    'SEK/GBP': '497930-149-402',
    'SEK/HKD': '614583-149-470',
    'SEK/JPY': '497980-149-534',
    'SEK/NOK': '1117575-149-662',
    'SEK/SGD': '614585-149-846',
    'SEK/TRY': '1992396-149-921',
    'SEK/USD': '497924-149-333',
  },
  SGD: {
    'SGD/AUD': '341789-149-88',
    'SGD/CAD': '614855-149-184',
    'SGD/CHF': '506034-149-1',
    'SGD/DKK': '1117763-149-272',
    'SGD/EUR': '968976-149-814',
    'SGD/GBP': '1117762-149-402',
    'SGD/HKD': '340809-149-470',
    'SGD/JPY': '329149-149-534',
    'SGD/NOK': '1117765-149-662',
    'SGD/SEK': '1117770-149-864',
    'SGD/TRY': '1992407-149-921',
    'SGD/USD': '610497-149-333',
  },
  TRY: {
    'TRY/AUD': '1992426-149-88',
    'TRY/CAD': '1992386-149-184',
    'TRY/CHF': '1992409-149-1',
    'TRY/DKK': '1992391-149-272',
    'TRY/EUR': '1991471-149-814',
    'TRY/GBP': '1992388-149-402',
    'TRY/HKD': '1991467-149-470',
    'TRY/JPY': '1992418-149-534',
    'TRY/NOK': '1992393-149-662',
    'TRY/SEK': '1992398-149-864',
    'TRY/SGD': '1991448-149-846',
    'TRY/USD': '1992412-149-333',
  },
  USD: {
    'USD/AUD': '275274-149-88',
    'USD/CAD': '275027-149-184',
    'USD/CHF': '275000-149-1',
    'USD/DKK': '275031-149-272',
    'USD/EUR': '968984-149-814',
    'USD/GBP': '275321-149-402',
    'USD/HKD': '275126-149-470',
    'USD/JPY': '275023-149-534',
    'USD/NOK': '275030-149-662',
    'USD/SEK': '275029-149-864',
    'USD/SGD': '275129-149-846',
    'USD/TRY': '1991420-149-921',
  },
};

export const currencySelectOptions = [
  {
    currency: 'CHF',
    value: 'CHF: Schweizer Franken',
    flag: {
      url: SwitzerlandFlag,
      styles: styles.SwissFlag,
    },
  },
  {
    currency: 'EUR',
    value: 'EUR: Euro',
    flag: {
      url: EuropeFlag,
      styles: styles.Flag,
    },
  },
  {
    currency: 'AUD',
    value: 'AUD: AustralischerDollar',
    flag: {
      url: AustraliaFlag,
      styles: styles.Flag,
    },
  },
  {
    currency: 'CAD',
    value: 'CAD: Kanadischer Dollar',
    flag: {
      url: CanadaFlag,
      styles: styles.Flag,
    },
  },
  {
    currency: 'DKK',
    value: 'DKK: Dänische Krone',
    flag: {
      url: DenmarkFlag,
      styles: styles.Flag,
    },
  },
  {
    currency: 'GBP',
    value: 'GBP: Britisches Pfund',
    flag: {
      url: GreatBritainFlag,
      styles: styles.Flag,
    },
  },
  {
    currency: 'HKD',
    value: 'HKD: Hongkong-Dollar',
    flag: {
      url: HongkongFlag,
      styles: styles.Flag,
    },
  },
  {
    currency: 'JPY',
    value: 'JPY: Japanischer Yeng',
    flag: {
      url: JapanFlag,
      styles: styles.Flag,
    },
  },
  {
    currency: 'NOK',
    value: 'NOK: Norwegische Krone',
    flag: {
      url: NorwegenFlag,
      styles: styles.Flag,
    },
  },
  {
    currency: 'SEK',
    value: ' SEK: Schwedische Krone',
    flag: {
      url: SwedenFlag,
      styles: styles.Flag,
    },
  },
  {
    currency: 'SGD',
    value: 'SGD: Singapur-Dollar',
    flag: {
      url: SingapurFlag,
      styles: styles.Flag,
    },
  },
  {
    currency: 'TRY',
    value: 'TRY: Türkische Lyra',
    flag: {
      url: TurkeyFlag,
      styles: styles.Flag,
    },
  },
  {
    currency: 'USD',
    value: 'USD: Amerikanischer Dollar',
    flag: {
      url: UsaFlag,
      styles: styles.Flag,
    },
  },
];

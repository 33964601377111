/* istanbul ignore file */

import textParagraphTableFactory from '../../../../../../../../../common/components/Paragraphs/components/TextParagraph/components/Table/factory';
import { getHeaderByProps, getStylesByProps } from '../../shared/helpers';

const TextParagraphTable = textParagraphTableFactory({
  styles: getStylesByProps,
  header: getHeaderByProps,
});

export default TextParagraphTable;

import React from 'react';
import { EventConfig, EventProps } from './typings';
import { EventData } from '../../typings';
import sackDollar from '../../../../../../assets/icons/sack-dollar.svg';
import screenUsers from '../../../../../../assets/icons/screen-users.svg';
import question from '../../../../../../assets/icons/question.svg';
import coins from '../../../../../../assets/icons/coins.svg';
import styles from './styles.legacy.css';
import { cleanDate } from '../../helpers';
import RestrictedContent from '../../../../../RestrictedContent';

const Event = ({ event, hasSubscription }: EventProps) => {
  const eventConfig = (event: EventData): EventConfig => {
    return {
      DIV: {
        icon: sackDollar,
        title: 'Barausschüttung (Dividende)',
        date: cleanDate(event.exdt),
        text: [
          `Bruttobetrag: ${event.ratecurencd} ${event.grossdividend || 0}`,
          `Ex-Datum: ${cleanDate(event.exdt)}`,
          event.declarationdt
            ? `Ankündigungsdatum: ${cleanDate(event.declarationdt)}`
            : '',
          `Zahlungsdatum: ${cleanDate(event.paydt)}`,
        ].filter((text) => text),
      },
      RCAP: {
        icon: coins,
        title: 'Barausschüttung (Kapitalrückzahlung)',
        date: cleanDate(event.effectivedt),
        text: [
          `Bruttobetrag: ${event.ratecurencd} ${event.cashback || 0}`,
          `Ex-Datum: ${cleanDate(event.effectivedt)}`,
          event.ntschangedt
            ? `Ankündigungsdatum: ${cleanDate(event.ntschangedt)}`
            : '',
          `Zahlungsdatum: ${cleanDate(event.paydt)}`,
        ].filter((text) => text),
      },
      AGM: {
        icon: screenUsers,
        title: 'Generalversammlung',
        date: cleanDate(event.meetingdt),
        text: [
          `Versammlungsdatum: ${cleanDate(event.meetingdt)}`,
          `${
            event.eventsubtypecd === 'EGM'
              ? 'Ausserordentliche Generalversammlung'
              : ''
          }`,
        ].filter((text) => text),
      },
    };
  };

  const config = eventConfig(event)[event.eventcd];
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Row}>
        <RestrictedContent isActive={!hasSubscription}>
          <img
            className={styles.Icon}
            src={!hasSubscription ? question : config.icon}
            alt="icon"
          />
        </RestrictedContent>
        <RestrictedContent isActive={!hasSubscription}>
          <span className={styles.Date}>{config.date}</span>
        </RestrictedContent>
        <div className={styles.Text}>
          <RestrictedContent isActive={!hasSubscription}>
            <span className={styles.Title}>{config.title}</span>
          </RestrictedContent>
          {config.text.map((text) => (
            <RestrictedContent key={text} isActive={!hasSubscription}>
              <span className={styles.Text}>{text}</span>
            </RestrictedContent>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Event;

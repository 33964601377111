export const ensureWidgetItem = (item: WidgetParagraph) => {
  if (item.widget) {
    return {
      ...item,
      id: item.widget.id,
      title: item.widget.title,
      link: {
        path: item.widget.url.path,
      },
      widget: item.widget,
    };
  }

  return item;
};

export const INTEGRATION_INSRUMENT_IDENTIFIER_MAPPING = {
  CH0012221716: 5501,
  CH0432492467: 12679,
  CH0210483332: 813,
  CH0025238863: 14159,
  CH0030170408: 47,
  CH0010645932: 2769,
  CH0012214059: 14832,
  CH0013841017: 3442,
  CH0038863350: 50,
  CH0012005267: 41,
  CH0024608827: 10581,
  CH0012032048: 42,
  CH0002497458: 1093,
  CH0418792922: 6214,
  CH0014852781: 6446,
  CH0126881561: 1096,
  CH0008742519: 4815,
  CH0012255151: 949,
  CH0244767585: 46,
  CH0011075394: 1013,
  CH0025751329: 5746,
  CH0012549785: 45,
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import raf from 'raf';
import authStateSelector from '../../../../../shared/selectors/authStateSelector';
import AppNexus from '../AppNexus';
import LoadingSpinner from '../LoadingSpinner';
import { Auth0 } from '../../../../../common/components/Auth0Provider';
import { useSSRContext } from '../../../../../common/components/SSRContext';
import { TOP_AD_1 } from '../AppNexus/constants';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { useStableNavigate } from '../../../../../shared/hooks/useStableNavigateContext';

const redirectToLogin = ({ navigate, location, loginPageProps }) => {
  raf(() => {
    navigate('/login', {
      ...location,
      replace: true,
      redirect: true,
      key: 'login',
      pathname: '/login',
      href: '/login',
      state: {
        key: 'login',
        prevLocation: location.href,
        loginPageProps,
      },
    });
  });
  return null;
};
const ProtectedRoute = ({ location, children, loginPageProps }) => {
  const { isSSR } = useSSRContext();
  const [jsx, setJsx] = useState(null);
  const navigate = useStableNavigate();
  const isAuthenticated = useSelector(
    (state) => authStateSelector(state).isAuthenticated,
  );

  if (!isSSR && isAuthenticated) {
    return children ? children : <Outlet />;
  } else if (!isSSR) {
    Auth0.hasToken()
      .then((hasToken) => {
        if (hasToken && !jsx) {
          setJsx(children ? children : <Outlet />);
        } else if (!jsx) {
          setJsx(
            redirectToLogin({
              navigate,
              location,
              loginPageProps,
            }),
          );
        }
      })
      .catch(() => {
        if (!jsx) {
          setJsx(
            redirectToLogin({
              navigate,
              location,
              loginPageProps,
            }),
          );
        }
      });
  }

  return (
    <>
      {(!isSSR && jsx) || (
        <>
          <div className="ad-wrapper ad-wrapper-mobile header-apn-zone">
            <div className={grid.Container}>
              <AppNexus slot={TOP_AD_1} deviceType="mobile" />
            </div>
          </div>
          <div className={grid.Container}>
            <div className={styles.Wrapper}>
              <LoadingSpinner />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProtectedRoute;

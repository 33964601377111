import React, {
  ReactElement,
  RefObject,
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { getSearchResults } from './helpers';
import AutoSuggestSearchForm from './components/AutoSuggestSearchForm';
import AutoSuggestSearchResults from './components/AutoSuggestSearchResults';
import { SEARCH_RESULT_CONFIG } from './constants';
import { GET_AUTO_SUGGEST_SEARCH_ENTITIES } from './queries';
import resultStyles from './components/AutoSuggestSearchResults/styles.legacy.css';
import styles from './styles.legacy.css';
import { AutoSuggestSearchProps } from './typings';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [
  resultStyles.Wrapper,
  resultStyles.ItemName,
  resultStyles.ItemAttributes,
  resultStyles.ResultTitle,
  resultStyles.Link,
  resultStyles.LinkColor,
  resultStyles.IsActive,
];

function useRefCallback(): [React.LegacyRef<HTMLDivElement>, number] {
  // used for smooth transitions after having found search results
  const [refValue, setRefValue] = useState(0);
  const setRef = useCallback((node) => {
    setRefValue(node?.clientHeight || 0);
  }, []);

  return [setRef, refValue];
}

const QUERY_LIMIT = 5;

type AutoSuggestSearchQueryComponentProps = {
  textSearch: SearchResults;
};

const AutoSuggestSearch = forwardRef(
  (
    {
      isDisabled = false,
      showErrorMessage = false,
      placeholder = 'Suche',
      onClickResult,
      appInputAriaLabel,
      searchResultConfig = SEARCH_RESULT_CONFIG,
      resultWithBorder = true,
      placeholderStyle = '',
      errorMessage = '',
      searchResultHeight,
      isInsideDrawer = false,
    }: AutoSuggestSearchProps,
    ref,
  ): ReactElement => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [hasInputFocus, setHasInputFocus] = useState(false);
    const autoSuggestSearchRef = useRef<HTMLDivElement>();
    const [setRef, SearchResultRefHeight] = useRefCallback();
    const inputRef: RefObject<HTMLInputElement> = createRef();
    const variables = {
      query: searchQuery,
      limit: QUERY_LIMIT,
    };
    const adjustHeightTransition =
      (showAutocomplete && SearchResultRefHeight) || 0;

    const { data: searchResultsData, loading } =
      useQuery<AutoSuggestSearchQueryComponentProps>(
        GET_AUTO_SUGGEST_SEARCH_ENTITIES,
        {
          variables,
          skip: !showAutocomplete,
        },
      );
    const { textSearch } = searchResultsData || {};
    const searchResults = getSearchResults(textSearch);
    const filteredSearchResults = searchResults.filter(
      ({ items, type }) =>
        items && items.length > 0 && searchResultConfig.includes(type),
    );

    const noSearchResults = searchResults.every(
      ({ items }) => !items || items.length === 0,
    );

    let focusIndex = -1;

    const handleKeyUp = ({ key, shiftKey }) => {
      const ref = autoSuggestSearchRef.current;
      const scrollContainer =
        ref.closest('#scrollable-drawer-content') ||
        ref.querySelector('.autosuggest-result-wrapper');
      const results = ref.querySelectorAll('.' + resultStyles.ResultListItem);
      const inputElement = ref.querySelector(
        '.autosuggest-input',
      ) as HTMLElement;

      const resultsArray = [...results] as HTMLElement[];
      const isUp = key === 'ArrowUp' || (shiftKey && key === 'Tab');
      const isDown = key === 'ArrowDown' || (!shiftKey && key === 'Tab');
      const maxIndex = results.length - 1;

      if (isUp) {
        focusIndex = focusIndex > -1 ? focusIndex - 1 : 0;

        if (focusIndex === -1) inputElement.focus();
      }

      if (isDown) {
        focusIndex = focusIndex < maxIndex ? focusIndex + 1 : maxIndex;
      }

      if (key === 'Enter') {
        const selectedResult = resultsArray[focusIndex]?.querySelector('a');
        selectedResult?.click();
      }

      resultsArray.forEach((el) => el.classList.remove(resultStyles.IsActive));
      resultsArray[focusIndex]?.classList.add(resultStyles.IsActive);

      if (scrollContainer) {
        scrollContainer.scrollTop =
          resultsArray[focusIndex - 1]?.offsetTop + 18;
      }
    };

    const handleSubmit = (event) => {
      event.preventDefault();
    };

    const handleScroll = () => {
      if (hasInputFocus && showAutocomplete) {
        inputRef?.current?.blur();
      }
    };

    const handleUpdateQuery = ({ target }) => {
      setSearchQuery(target.value);
      if (target.value.length > 2) {
        setShowAutocomplete(true);
      } else if (target.value.length < 1) {
        setShowAutocomplete(false);
      } else {
        setShowAutocomplete(false);
      }
    };

    const disableArrowKeysForInput = useCallback((event) => {
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        event.preventDefault();
      }
    }, []);

    const handleOutsideClick = useCallback(() => {
      if (showAutocomplete && !isInsideDrawer) {
        setShowAutocomplete(false);
        setSearchQuery('');
      }

      if (isInsideDrawer && showAutocomplete && searchQuery.length < 3) {
        setShowAutocomplete(false);
      }
    }, [showAutocomplete, isInsideDrawer, searchQuery?.length]);

    useImperativeHandle(ref, () => ({
      closeSearchResult() {
        setShowAutocomplete(false);
      },
      clearInputfield() {
        setSearchQuery('');
      },
    }));

    useEffect(() => {
      document.addEventListener('click', handleOutsideClick);
      document.addEventListener('keydown', disableArrowKeysForInput);

      return () => {
        document.removeEventListener('click', handleOutsideClick);
        document.removeEventListener('keydown', disableArrowKeysForInput);
      };
    }, [handleOutsideClick, disableArrowKeysForInput]);

    return (
      <div
        ref={autoSuggestSearchRef}
        onTouchMove={handleScroll}
        onKeyUp={handleKeyUp}
        role="presentation"
        className={classNames(styles.Wrapper, 'hide-on-print')}
      >
        <AutoSuggestSearchForm
          formSubmit={handleSubmit}
          handleUpdateQuery={handleUpdateQuery}
          searchQuery={searchQuery}
          showLoader={loading}
          placeholder={placeholder}
          isDisabled={isDisabled}
          appInputAriaLabel={appInputAriaLabel}
          showErrorMessage={showErrorMessage}
          addClass={placeholderStyle}
          errorMessage={errorMessage}
          hasInputFocus={hasInputFocus}
          setHasInputFocus={setHasInputFocus}
          inputRef={inputRef}
        />

        <div
          style={{
            maxHeight: searchResultHeight,
            height:
              !resultWithBorder && !noSearchResults && adjustHeightTransition,
          }}
          className={classNames('autosuggest-result-wrapper', {
            [styles.ResultWrapper]: resultWithBorder,
            [styles.ResultWrapperWithoutBorder]: !resultWithBorder,
            [styles.Border]:
              resultWithBorder &&
              showAutocomplete &&
              searchResultHeight &&
              !loading,
          })}
        >
          {showAutocomplete && !loading && searchResultsData?.textSearch && (
            <>
              {noSearchResults ? (
                <div className={styles.Error}>
                  Die Suche ergab keine Treffer
                </div>
              ) : (
                <div
                  ref={setRef}
                  className={classNames({
                    [styles.HasSearchResults]: !resultWithBorder,
                  })}
                >
                  {filteredSearchResults.map(({ title, items }) => (
                    <AutoSuggestSearchResults
                      key={title}
                      title={title}
                      items={items}
                      handleSearchResults={onClickResult}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  },
);

export default AutoSuggestSearch;

/* istanbul ignore file */
import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { FULLQUOTE_SEMI_STATIC_PREFIX } from '../../constants';
import { GET_FULLQUOTE_SERVICE_PAGE } from '../FullquotePage/queries';
import { GET_FULLQUOTE_PAGE } from './queries';
import { FullquotePageProps } from './typings';

export const fullquotePageApolloConfig: RaschApolloConfig<FullquotePageProps> =
  {
    options: ({ location, params, props }) => {
      const pathname = location?.pathname || '';
      const { valorName, market, currency } = params;
      const { isHybridApp } = props;
      const pagetype = pathname.replace(/^\/+/, '').split('/')[0];
      let subtype = `${FULLQUOTE_SEMI_STATIC_PREFIX}${pagetype}`;
      let subPage = `${FULLQUOTE_SEMI_STATIC_PREFIX}${pagetype}/${valorName}`;
      const finalPathname = `/${pagetype}/${valorName}/${market}/${currency}`;

      if (isHybridApp) {
        // concatinate hybrid prefix to subtype and subPage such as hybrid-fullquote-aktien FULLQUOTE_HYBRID_SEMI_STATIC_PREFIX
        subtype = `hybrid-${subtype}`;
        subPage = `hybrid-${subPage}`;
      }
      return {
        query: GET_FULLQUOTE_SERVICE_PAGE,
        additionalQuery: GET_FULLQUOTE_PAGE,
        additionalVariables: {
          fullquoteSubtype: subtype || '',
          fullquoteSubPage: subPage || '',
        },
        variables: {
          path: finalPathname || '',
        },
      };
    },
  };

import React from 'react';

const SocialYoutube = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={true}
  >
    <circle cx="19" cy="19" r="19" fill="white" />
    <path
      d="M26.2657 10.2191C27.3611 10.2921 28.1572 10.4017 28.6538 10.5477C29.1942 10.6938 29.6616 10.9713 30.0559 11.3803C30.4649 11.7746 30.7424 12.2347 30.8885 12.7605C31.0345 13.2717 31.1441 13.8779 31.2171 14.579C31.2901 15.28 31.3413 15.9519 31.3705 16.5946C31.3997 17.2372 31.4143 17.785 31.4143 18.2377L31.4362 18.9169L31.4143 19.5961C31.4143 20.0489 31.3924 20.5966 31.3486 21.2393C31.3193 21.8673 31.2682 22.5319 31.1952 23.233C31.1222 23.934 31.0199 24.5329 30.8885 25.0295C30.757 25.5699 30.4868 26.0446 30.0778 26.4536C29.6689 26.8479 29.1942 27.1181 28.6538 27.2642C28.1572 27.3956 27.3611 27.4979 26.2657 27.5709C25.1703 27.6439 24.0602 27.6951 22.9356 27.7243C21.8109 27.7535 20.825 27.7754 19.9779 27.79H18.7071H17.4364C16.5893 27.7754 15.6034 27.7535 14.4787 27.7243C13.3687 27.6951 12.2586 27.6439 11.1486 27.5709C10.0531 27.4979 9.25712 27.3956 8.76052 27.2642C8.2201 27.1035 7.74541 26.826 7.33644 26.4317C6.92747 26.0227 6.64996 25.548 6.5039 25.0076C6.37245 24.511 6.27751 23.9194 6.21909 23.233C6.14606 22.5319 6.08764 21.86 6.04382 21.2173C6.01461 20.5747 6 20.027 6 19.5742V18.895V18.2158C6 17.763 6.01461 17.2153 6.04382 16.5727C6.08764 15.93 6.14606 15.2581 6.21909 14.557C6.27751 13.8706 6.37245 13.2717 6.5039 12.7605C6.64996 12.2201 6.92747 11.7454 7.33644 11.3364C7.74541 10.9275 8.2201 10.65 8.76052 10.5039C9.25712 10.3724 10.0531 10.2775 11.1486 10.2191C12.244 10.1461 13.3541 10.0949 14.4787 10.0657C15.6034 10.0219 16.5893 10 17.4364 10H18.7071H19.9779C20.825 10 21.8109 10.0219 22.9356 10.0657C24.0602 10.0949 25.1703 10.1461 26.2657 10.2191Z"
      fill="rgba(41, 46, 50, 1);"
    />
    <path
      d="M22.7384 18.8862L16.1657 22.681L16.1657 15.0915L22.7384 18.8862Z"
      fill="white"
    />
  </svg>
);

export default SocialYoutube;

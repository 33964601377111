import parallaxImageParagraphFactory from '../../../../../../../common/components/Paragraphs/components/ParallaxImageParagraph/factory';
import styles from './styles.legacy.css';

export default parallaxImageParagraphFactory({
  styles: {
    CreditsTitle: styles.CreditsTitle,
    ImageDefault: styles.ImageDefault,
    Parallax: styles.Parallax,
  },
});

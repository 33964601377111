import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { GET_TRANSACTION, GET_TRANSACTION_INFO } from './queries';

export const transactionApolloConfig: RaschApolloConfig = {
  options: ({ params }) => {
    const { portfolioKey, instrumentKey, transactionKey } = params;
    const isOtherAsset = !!params?.isOtherAsset;
    return {
      query: GET_TRANSACTION,
      variables: {
        portfolioKey: portfolioKey,
        instrumentKey: instrumentKey,
        transactionKey: transactionKey || '',
        isOtherAsset: isOtherAsset,
      },
      ssr: false,
      skip: !portfolioKey,
    };
  },
};

export const transactionInfoApolloConfig: RaschApolloConfig = {
  options: ({ params }) => {
    const { portfolioKey, instrumentKey, portfolioCurrency } = params;
    const isOtherAsset = !!params?.isOtherAsset;
    return {
      query: GET_TRANSACTION_INFO,
      variables: {
        portfolioKey: portfolioKey,
        instrumentKey: instrumentKey,
        portfolioCurrency: portfolioCurrency,
        isOtherAsset: isOtherAsset,
      },
      // need to be network-only because of the real-time price we need to get
      fetchPolicy: 'network-only',
      ssr: false,
      skip: !portfolioKey,
    };
  },
};

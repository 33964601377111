import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useWidgetParagraphQuery } from '../../helpers';
import authStateSelector from '../../../../../../../shared/selectors/authStateSelector';
import Link from '../../../../../../../common/components/Link';
import DataField from './components/DataField';
import { ROUTE_BOERSEN_ABOS } from '../../../../constants';
import styles from './styles.legacy.css';
import { InstrumentSummaryProps } from './typings';

const InstrumentSummary = ({ widgetParagraph }: InstrumentSummaryProps) => {
  const { fields, loading, error } = useWidgetParagraphQuery(widgetParagraph, [
    'perfPercentage4w',
    'perfPercentage12w',
    'perfPercentageYTD',
    'high',
    'highDate',
    'low',
    'lowDate',
    'lo52w',
    'lo52wDatetime',
    'hi52w',
    'hi52wDatetime',
  ]);

  const isRealTimeUser = useSelector(
    (state) => authStateSelector(state).realtime,
  );

  const isLoading = loading || !!error;

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.Row}>
          <span className={styles.Label}>4 Wochen</span>
          <DataField
            isLoading={isLoading}
            fields={[fields.perfPercentage4w]}
            onlyOne
          />
        </div>
        <div className={styles.Row}>
          <span className={styles.Label}>12 Wochen</span>
          <DataField
            isLoading={isLoading}
            fields={[fields.perfPercentage12w]}
            onlyOne
          />
        </div>
        <div className={styles.Row}>
          <span className={styles.Label}>Seit 1.1.</span>
          <DataField
            isLoading={isLoading}
            fields={[fields.perfPercentageYTD]}
            onlyOne
          />
        </div>
        <div className={classNames(styles.Row, styles.NoBorder)}>
          <span className={styles.Label}>Tageshoch</span>
          <DataField
            isLoading={isLoading}
            fields={[fields.high, fields.highDate]}
          />
        </div>
        <div className={styles.Row}>
          <span className={styles.Label}>Tagestief</span>
          <DataField
            isLoading={isLoading}
            fields={[fields.low, fields.lowDate]}
          />
        </div>
        <div className={classNames(styles.Row, styles.NoBorder)}>
          <span className={styles.Label}>52-Wochen-Hoch</span>
          <DataField
            isLoading={isLoading}
            fields={[fields.hi52w, fields.hi52wDatetime]}
          />
        </div>
        <div className={styles.Row}>
          <span className={styles.Label}>52-Wochen-Tief</span>
          <DataField
            isLoading={isLoading}
            fields={[fields.lo52w, fields.lo52wDatetime]}
          />
        </div>

        {!isRealTimeUser && (
          <>
            <span className={styles.InfoText}>
              Sie erhalten verzögerte Kurse.
            </span>
            <Link
              key={`get-realtime-data`}
              className={styles.Link}
              path={`/${ROUTE_BOERSEN_ABOS}`}
              label={'Jetzt Realtime Daten erhalten'}
            />
          </>
        )}
      </div>
    </>
  );
};

export default memo<InstrumentSummaryProps>(InstrumentSummary);

import React, { ReactElement, memo } from 'react';
import MainTable from './components/MainTable';
import { CUSTOM_VIEW_TABLE, DEFAULT_TABLE } from '../Table/constants';
import { TableProps } from './typings';

const initialCustomView = ['name', 'paidPrice'];

const Table = (props: TableProps): ReactElement => {
  let tableFieldHeaders = props.tableHeaders[props.component || DEFAULT_TABLE];

  if (props.component === CUSTOM_VIEW_TABLE) {
    const { customView } =
      props?.data?.[`${props.type}Settings`] || initialCustomView;

    const parsedCustomView = JSON.parse(customView || {});
    const cleanCustomView = parsedCustomView?.reduce((acc, item: any) => {
      acc.push(item.field);
      return acc;
    }, []);

    tableFieldHeaders = cleanCustomView || initialCustomView;
  }

  return <MainTable {...props} tableFieldHeaders={tableFieldHeaders} />;
};

export default memo<TableProps>(Table);

import React from 'react';
import { QueryResult, WikifolioProps } from './typings';
import { CONFIG } from './constants';
import styles from './styles.legacy.css';
import { useQuery } from '@apollo/client';
import { GET_WIKIFOLIO } from './queries';
import Link from '../../../../../../../common/components/Link';
import { headerMapping } from '../../../../screens/MyCash/components/Table/components/headerMapping';

const Wikifolio = ({ widgetParagraph }: WikifolioProps) => {
  const path = widgetParagraph?.link?.path;
  const url =
    (path && path.startsWith('/') && new URL(path, global.locationOrigin)) ||
    (path && new URL(path)) ||
    null;
  const rankingType = url?.searchParams?.get('rankingType').trim();
  const config = CONFIG[rankingType];

  const { data, error, loading } = useQuery<QueryResult>(GET_WIKIFOLIO, {
    variables: {
      rankingType: config.rankingType,
      limit: 10,
    },
    skip: !rankingType,
  });

  if (!config) {
    return <div>RankingType not defined in Widget Url</div>;
  }

  if (error || loading) {
    return <div className={styles.Skeleton}></div>;
  }

  const getValue = (portfolio: Pf) => {
    const ranking = portfolio.rankings.find(
      (r) => r.name === config.rankingName,
    );
    if (config.rankingType === 'Capital') {
      return headerMapping.currentPrice.formatter(
        { value: ranking?.value, instrument: { scGrouped: 'FullNumber' } },
        true,
      );
    }
    return headerMapping.perfPercentage.formatter(
      { value: ranking?.value, instrument: null },
      true,
    );
  };

  return (
    <div className={styles.Wrapper}>
      <p className={styles.Title}>{config.title}</p>
      <table className={styles.Table}>
        <thead>
          <tr className={styles.Heading}>
            <th className={styles.Left}>Name</th>
            <th className={styles.Right}>{config.columnTitle}</th>
          </tr>
        </thead>
        <tbody>
          {data?.integration?.wikifolio?.portfolios.map((portfolio) => {
            return (
              <tr key={portfolio.isin}>
                <td className={styles.Left}>
                  <Link path={portfolio.fullquoteUri} className={styles.Link}>
                    {portfolio.shortDescription}
                  </Link>
                </td>
                <td className={styles.Right}>{getValue(portfolio)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Wikifolio;

import React, { memo } from 'react';
import { getSearchParams, useWidgetParagraphQuery } from '../../helpers';
import ChunkProgressBar from './components/ChunkProgressBar';
import styles from './styles.legacy.css';
import { InstrumentComparisonLastYearProps } from './typings';

const InstrumentComparisonLastYear = ({
  widgetParagraph,
}: InstrumentComparisonLastYearProps) => {
  const searchParams = getSearchParams(widgetParagraph);
  const scGrouped = searchParams['scGrouped'];

  const { instrument, loading, error } = useWidgetParagraphQuery(
    widgetParagraph,
    [
      'lval',
      'yLo',
      'yHi',
      'yLoDatetime',
      'yHiDatetime',
      'prevYearHigh',
      'prevYearLow',
      'prevYearHighDatetime',
      'prevYearLowDatetime',
    ],
  );

  const isLoading = loading || !!error;

  return isLoading || (!instrument?.yLo && !instrument?.yHi) ? (
    <div className={styles.Skeleton} />
  ) : (
    <>
      <div className={styles.Wrapper}>
        <p className={styles.WidgetTitle}>Vergleich Vorjahr</p>
        <div className={styles.Row}>
          <p className={styles.Title}>Jahrestief/-hoch</p>
          <ChunkProgressBar
            min={parseFloat(instrument?.yLo)}
            max={parseFloat(instrument?.yHi)}
            current={parseFloat(instrument?.lval)}
            scGrouped={scGrouped}
            startDate={instrument?.yLoDatetime}
            endDate={instrument?.yHiDatetime}
            variant="blue"
          />
        </div>
        {instrument?.prevYearLow && instrument?.prevYearHigh && (
          <div className={styles.Row}>
            <p className={styles.Title}>Vorjahrestief/-hoch</p>
            <ChunkProgressBar
              min={parseFloat(instrument?.prevYearLow)}
              max={parseFloat(instrument?.prevYearHigh)}
              current={parseFloat(instrument?.lval)}
              scGrouped={scGrouped}
              startDate={instrument?.prevYearLowDatetime}
              endDate={instrument?.prevYearHighDatetime}
              variant="grey"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default memo<InstrumentComparisonLastYearProps>(
  InstrumentComparisonLastYear,
);

import { ExtendedInstrument } from './components/TableRow/typings';

const sortString = (a: string, b: string, direction: 'asc' | 'desc') => {
  a = a ? a.toLowerCase() : '';
  b = b ? b.toLowerCase() : '';
  if (direction === 'asc') {
    return a > b ? 1 : -1;
  }
  return a < b ? 1 : -1;
};

const sortNumber = (a: number, b: number, direction: 'asc' | 'desc') => {
  return direction === 'asc' ? a - b : b - a;
};

const convertToNumber = (str: string): number => {
  if (typeof str === 'number') {
    return str;
  }

  if (!str) {
    return 0;
  }
  const cleanString = str.replace('%', '');
  const n = +cleanString;
  return isNaN(n) ? 0 : n;
};

const findColumnType = (fields: string[]): 'string' | 'number' => {
  let str = 0;
  let num = 0;
  fields.forEach((field) => {
    !isNaN(+field) ? num++ : str++;
  });
  return num > str ? 'number' : 'string';
};

export const sort = (instrument, by, direction = 'asc', customOrder = []) => {
  if (instrument?.length <= 0) {
    return instrument;
  }
  if (by === 'instrumentKey' && customOrder === null) {
    return instrument;
  }

  switch (by) {
    case 'instrumentKey':
      return instrument.sort((a, b) => {
        const aIndex = customOrder.indexOf(a.instrumentKey);
        const bIndex = customOrder.indexOf(b.instrumentKey);
        if (aIndex === -1 && bIndex === -1) {
          return 0;
        }
        if (aIndex === -1) {
          return 1;
        }
        if (bIndex === -1) {
          return -1;
        }
        return aIndex - bIndex;
      });
    case 'name':
      return instrument?.sort((a, b) => {
        if (a?.name < b?.name) {
          return direction === 'desc' ? 1 : -1;
        }
        if (a?.name > b?.name) {
          return direction === 'desc' ? -1 : 1;
        }
        return 0;
      });
    case 'mName':
      return instrument?.sort((a, b) => {
        if (a?.mName < b?.mName) {
          return direction === 'desc' ? 1 : -1;
        }
        if (a?.mName > b?.mName) {
          return direction === 'desc' ? -1 : 1;
        }
        return 0;
      });
    case 'date':
      return instrument.sort((a, b) => {
        if (a?.date < b?.date) {
          return direction === 'desc' ? 1 : -1;
        }
        if (a?.date > b?.date) {
          return direction === 'desc' ? -1 : 1;
        }
        return 0;
      });
  }
  return instrument;
};

// use generic type here to be able to use this sort function for Portfolio, Watchlist and Alerts
export const sortTableItems = <Type extends ExtendedInstrument>(
  items: Type[],
  column: string,
  direction: 'asc' | 'desc',
): Type[] => {
  if (!items || items.length <= 0) {
    return items;
  }
  const columnType = findColumnType(
    items?.map((instrument) => instrument[column]),
  );

  if (column === 'trendArrow') {
    column = 'iNetVperprVPr';
  }

  items.sort((a, b) => {
    let field1 = a[column];
    let field2 = b[column];
    if (columnType === 'number') {
      field1 = convertToNumber(field1);
      field2 = convertToNumber(field2);
      return sortNumber(field1, field2, direction);
    }
    return sortString(field1, field2, direction);
  });
  return items;
};

import React, { connect } from 'react-redux';
import compose from 'recompose/compose';
import shouldUpdate from 'recompose/shouldUpdate';
import classNames from 'classnames';
import teaserFactory, {
  TeaserFactoryProps,
  getFormattedDate,
} from '../../../../../../../common/components/Teaser/factory';
import { withTeaserTrackingHandler } from '../../../../../../../common/components/Teaser/helpers';
import {
  DEFAULT_AUTHOR_PREFIX_LANGUAGE,
  authorPrefixConfig,
} from '../../../../../../../shared/helpers/authors';
import { truncateByWord } from '../../../../../../../shared/helpers/utils';
import {
  getBadgeByProps,
  getFormattedPublicationDateByProps,
  getIsPublicationDateVisibleByProps,
  getTitleBadgeByProps,
} from '../../shared/helpers';
import locationStateSelector from '../../../../../../shared/selectors/locationStateSelector';
import Picture from '../../../../../../../common/components/Picture';
import Icon from '../../../Icon';
import {
  ADVERTISING_TYPE_ADVERTORIAL,
  ARTICLE_CONTENT_TYPE,
  IMAGE_GALLERY_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from '../../../../../../../shared/constants/content';
import {
  STYLE_16X9_440,
  STYLE_3X2_280,
} from '../../../../../../../shared/constants/images';
import { SCREEN_SEARCH_RESULTS } from '../../../SearchResults/constants';
import {
  TEASER_IMAGE_IDENTIFIER,
  TEASER_LEAD_LENGTH,
  TEASER_M_DEFAULT_IDENTIFIER,
} from '../../constants';
import {
  TEASER_ICON_TYPE_GALLERY_ICON,
  TEASER_ICON_TYPE_VIDEO_ICON,
} from '../TeaserIcon/constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import defaultStyles from './styles.legacy.css';
import { TeaserProps } from '../../typings';

type TeaserWidePropsInner = TeaserProps & TeaserInterface;

const FALLBACK_IMAGE_HIDDEN_FOR_PATHS = [
  '/hybrid-news/boersenticker',
  '/hybrid-news/alle',
  '/news/alle',
  '/news/boersenticker',
];

const checkFallbackImageHidden = (routePath, props) =>
  FALLBACK_IMAGE_HIDDEN_FOR_PATHS.includes(routePath) &&
  props?.teaserImage?.image?.file?.relativeOriginPath.indexOf('cash_fallback') >
    -1;

const getStylesByPropsConfig =
  ({ teaserIdentifier }) =>
  ({ subtypeValue, origin, routePath, hasVideo, __typename, ...props }) => {
    const isFallbackImageHidden = checkFallbackImageHidden(routePath, props);
    let hasIcon = false;
    if (
      __typename === IMAGE_GALLERY_CONTENT_TYPE ||
      (__typename === ARTICLE_CONTENT_TYPE && hasVideo) ||
      __typename === VIDEO_CONTENT_TYPE
    ) {
      hasIcon = true;
    }
    return {
      ContentWrapper: classNames(defaultStyles.ContentWrapper, {
        [defaultStyles.IsWithoutImage]: isFallbackImageHidden,
      }),
      OuterWrapper: defaultStyles.OuterWrapper,
      Wrapper: classNames(defaultStyles.Wrapper, teaserIdentifier),
      Title: classNames(defaultStyles.TeaserTitleWrapper, {
        [defaultStyles.IsWithoutImage]: isFallbackImageHidden,
      }),
      ImageWrapper: classNames(defaultStyles.TeaserImageWrapper, {
        [defaultStyles.Hidden]: isFallbackImageHidden,
        [defaultStyles.HasIcon]: hasIcon,
      }),
      Image: classNames(defaultStyles.Image, TEASER_IMAGE_IDENTIFIER),
      TitleInner: defaultStyles.TeaserTitle,
      BottomLine: classNames(
        {
          [defaultStyles.SearchPublicationDate]:
            origin === SCREEN_SEARCH_RESULTS,
        },
        defaultStyles.BottomLine,
      ),
      Lead: classNames(grid.HiddenSmDown, defaultStyles.TeaserLead, {
        [defaultStyles.SearchTeaserM]: origin === SCREEN_SEARCH_RESULTS,
      }),
      ShortTitle: classNames({
        [defaultStyles.ShortTitleAdvertorial]:
          subtypeValue === ADVERTISING_TYPE_ADVERTORIAL,
        [defaultStyles.SearchShortTitle]: origin === SCREEN_SEARCH_RESULTS,
        [defaultStyles.ShortTitle]: origin !== SCREEN_SEARCH_RESULTS,
        [defaultStyles.IsWithoutImage]: isFallbackImageHidden,
      }),
    };
  };

const getIconByProps = (iconStyle) => {
  const getIconByProps = ({ __typename, hasVideo }) => {
    if (__typename === IMAGE_GALLERY_CONTENT_TYPE) {
      return (
        <div className={iconStyle}>
          <Icon
            type={TEASER_ICON_TYPE_GALLERY_ICON}
            addClass={defaultStyles.Icon}
          />
        </div>
      );
    } else if (
      (__typename === ARTICLE_CONTENT_TYPE && hasVideo) ||
      __typename === VIDEO_CONTENT_TYPE
    ) {
      return (
        <div className={iconStyle}>
          <Icon
            type={TEASER_ICON_TYPE_VIDEO_ICON}
            addClass={defaultStyles.Icon}
          />
        </div>
      );
    } else {
      return null;
    }
  };
  return getIconByProps;
};

const getInnerContentByProps = ({ ...props }) => {
  const {
    title,
    lead,
    teaserImage,
    image,
    downloadPriority,
    publicationDate,
    authors,
    authorPrefix,
    routePath,
  } = props;
  const leadOptions = {
    truncateCount: 150,
    append: '...',
  };
  const teaserImageRelativePath = teaserImage?.image?.file?.relativeOriginPath;
  const focalPointX =
    teaserImage?.image?.file?.focalPointX || image?.file?.focalPointX;
  const focalPointY =
    teaserImage?.image?.file?.focalPointY || image?.file?.focalPointX;
  const teaserImageStyles = {
    style_320: STYLE_3X2_280,
    style_1680: STYLE_16X9_440,
  };
  const teaserImageAlt = teaserImage?.image?.file?.alt || '';
  const icon = getIconByProps(defaultStyles.IconWrapper)({
    __typename: props?.__typename,
    hasVideo: props?.hasVideo,
  });
  const badge = getBadgeByProps(defaultStyles.IconWrapper)(
    props as TeaserFactoryProps,
  );
  const formattedPublicationDate = getFormattedPublicationDateByProps(props);
  const isPublicationDateVisible = getIsPublicationDateVisibleByProps(props);
  const formattedPublicationDatePlain = getFormattedDate(publicationDate);
  const isFallbackImageHidden = checkFallbackImageHidden(routePath, props);
  return (
    <div className={defaultStyles.OuterContentWrapper}>
      {lead && leadOptions && Object.keys(leadOptions).length > 0 && (
        <div className={defaultStyles.TeaserLead}>
          {(teaserImageRelativePath && teaserImageStyles && (
            <div
              className={classNames(defaultStyles.TeaserImageWrapper, {
                [defaultStyles.Hidden]: isFallbackImageHidden,
                [defaultStyles.HasIcon]: !!icon,
              })}
              data-testid="teaser-factory-image-wrapper"
            >
              <>
                <Picture
                  downloadPriority={downloadPriority}
                  relativeOrigin={teaserImageRelativePath}
                  focalPointX={focalPointX}
                  focalPointY={focalPointY}
                  alt={teaserImageAlt}
                  className={defaultStyles.Image}
                  disableWrapperClassName={true}
                  disableLineHeightResetClassName={false}
                  {...teaserImageStyles}
                  title={title}
                />
                {icon || null}
                {badge || null}
              </>
            </div>
          )) ||
            null}
          {truncateByWord(
            lead,
            leadOptions.truncateCount,
            leadOptions.append || '',
          )}
          <div>
            {isPublicationDateVisible && formattedPublicationDatePlain && (
              <div className={defaultStyles.BottomLine}>
                {(formattedPublicationDate && (
                  <span title={formattedPublicationDatePlain}>
                    {formattedPublicationDate}
                  </span>
                )) || (
                  <span title={formattedPublicationDatePlain}>{`am ${
                    formattedPublicationDatePlain || ''
                  }`}</span>
                )}
              </div>
            )}
            {authors?.edges?.length > 0 && (
              <div className={defaultStyles.BottomLine}>
                {authorPrefix && (
                  <span>
                    {
                      authorPrefixConfig[authorPrefix][
                        DEFAULT_AUTHOR_PREFIX_LANGUAGE
                      ]
                    }
                  </span>
                )}
                {authors.edges.map(({ node }, index) => (
                  <span key={`author-${node.id}`}>
                    {index !== 0 ? ', ' : ''}
                    {node.name}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const TeaserWide = teaserFactory({
  trackingTeaserHandler: withTeaserTrackingHandler,
  icon: getIconByProps(defaultStyles.IconWrapper),
  badge: getBadgeByProps(defaultStyles.Badge),
  titleBadge: getTitleBadgeByProps(),
  disableWrapperClassName: true,
  isIconPositionOnImage: true,
  isAuthorVisible: true,
  isAuthorPrefixVisible: true,
  formattedPublicationDate: getFormattedPublicationDateByProps,
  isPublicationDateVisible: getIsPublicationDateVisibleByProps,
  teaserImageStyles: {
    style_320: STYLE_3X2_280,
    style_1680: STYLE_16X9_440,
  },
  leadOptions: {
    truncateCount: TEASER_LEAD_LENGTH,
    append: '...',
  },
  styles: getStylesByPropsConfig({
    teaserIdentifier: TEASER_M_DEFAULT_IDENTIFIER,
  }),
  children: getInnerContentByProps,
});

const withUpdatePolicy = shouldUpdate(
  (props: TeaserWidePropsInner, nextProps: TeaserWidePropsInner) =>
    props.title !== nextProps.title,
);

const mapStateToProps = (state: Record<string, any>) => ({
  routePath: locationStateSelector(state).locationBeforeTransitions.pathname,
});

export default compose(connect(mapStateToProps), withUpdatePolicy)(TeaserWide);

import {
  TYPE_AD,
  TYPE_TEASER,
} from '../../../../../../../common/components/TeaserGrid/gridConfigs/constants';
import {
  INDEX_AD_1,
  TOP_AD_1,
} from '../../../../../../../shared/constants/adZone';
import { TEASER_LAYOUT_WIDE } from '../../../../../../../shared/constants/teaser';

export const landingPageDefault = {
  gridGroups: [
    {
      config: {
        hasContainer: false,
        autoFill: true,
      },
      items: [
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_WIDE,
          downloadPriority: 'high',
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_WIDE,
        },
        {
          type: TYPE_AD,
          adConfig: [
            { slot: TOP_AD_1, isMultiPlacement: false, deviceType: 'mobile' },
          ],
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_WIDE,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_WIDE,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_WIDE,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_WIDE,
        },
        {
          type: TYPE_AD,
          adConfig: [{ slot: INDEX_AD_1, deviceType: 'mobile' }],
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_WIDE,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_WIDE,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_WIDE,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_WIDE,
        },
        {
          type: TYPE_AD,
          adConfig: [{ slot: INDEX_AD_1, deviceType: 'mobile' }],
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_WIDE,
        },
      ],
    },
  ],
};

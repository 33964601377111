export const UBS_TRENDRADAR_PATH =
  'https://track.adform.net/C/?bn=52627733;cpdir=https://keyinvest-ch.ubs.com/trendradar/signal/[signalId]';

export const TENDENCY = {
  '-1': 'short',
  '1': 'long',
};

export const TIME_HORIZON = {
  '1': 'kurzfristig',
  '2': 'mittelfristig',
  '3': 'langfristig',
};

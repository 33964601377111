/* istanbul ignore file */

import clientFactory from '../../../common/entry/client/factory';
import App from '../../screens/App';
import AutoUpdateProvider from '../../screens/App/components/AutoUpdateProvider';
import RaschProviders from '../../screens/App/components/RaschProviders';
import { register } from '../../../shared/serviceWorker';
import { displayInfoToast } from '../../screens/App/components/Toast';
import generateApolloCachePolicy from '../../../shared/generateApolloCachePolicy';
import { configureClientStore } from '../../shared/configureStore';

const isPWApp = Boolean(
  (!__TESTING__ &&
    __CLIENT__ &&
    (global?.navigator?.['standalone'] ||
      window.matchMedia('(display-mode: standalone)').matches)) ||
    false,
);

// Find the DOM node generated by the server
const mountNode: HTMLElement | null = document.getElementById('app') || null;

const preRender = (): void => {
  register({
    onUpdate: (registration, callback) => {
      const registrationWaiting = registration.waiting;
      if (registrationWaiting && isPWApp) {
        displayInfoToast(
          'Eine neue Version ist verfügbar. ',
          'sw-update-toast',
          {
            text: 'Aktualisieren',
            onClick: () => {
              callback(registrationWaiting, {
                type: 'SKIP_WAITING',
              });
            },
          },
        );
      }
    },
  });
};

const client = clientFactory({
  RaschProviders,
  AutoUpdateProvider,
  AppRoutes: App,
  configureClientStore,
  mountNode,
  preRender,
  generateApolloCache: generateApolloCachePolicy,
});

// Run client application.
client();

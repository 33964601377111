import React from 'react';
import { tealiumTrackEvent } from '../../../../../../../../../shared/helpers/tealium';
import Icon from '../../../../../Icon';
import styles from './styles.legacy.css';
import { StepButtonProps } from './typings';

const StepButton = (props: StepButtonProps) => {
  const { iconType, onClick } = props;
  const eventActions = { IconPlusThin: 'plus', IconMinusThin: 'minus' };

  return (
    <>
      <button
        onClick={() => {
          onClick();
          tealiumTrackEvent({
            type: 'link',
            payload: {
              event_name: `stepbutton_click`,
              event_category: 'stepbutton',
              event_action: `click_${eventActions[iconType] || 'other_icon'}`,
            },
          });
        }}
        className={styles.Button}
      >
        <Icon type={iconType} />
      </button>
    </>
  );
};

export default StepButton;

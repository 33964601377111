import React from 'react';
import leftLineBoxParagraphFactory from '../../../../../../../../../common/components/Paragraphs/components/InfoBoxParagraph/components/LeftLineBox/factory';
import ParagraphsRenderer from '../../../ParagraphsRenderer';
import { INFO_BOX_TYPE } from '../../../../../../../../../common/components/Paragraphs/components/InfoBoxParagraph/constants';
import grid from '../../../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

const getAppParagraphsRenderer = ({ infoBoxParagraph }) => {
  if (!infoBoxParagraph?.infoBox?.body) {
    return null;
  }
  return (
    <ParagraphsRenderer
      pageBody={infoBoxParagraph.infoBox.body}
      hasContainer={false}
      addClass={styles.TypographyWrapper}
      addHeaderClass={styles.Header}
      addSectionClass={styles.SectionMargin}
      origin={INFO_BOX_TYPE}
    />
  );
};

const LeftLineBoxParagraph = leftLineBoxParagraphFactory({
  paragraphsRenderer: getAppParagraphsRenderer,
  styles: {
    Wrapper: '',
    InnerWrapper: grid.Row,
    Border: styles.Border,
    Devider: styles.Devider,
    ParagraphWrapper: styles.ParagraphWrapper,
  },
});

export default LeftLineBoxParagraph;

/**
 * Grid Layouts
 */

export const GRID_LAYOUT_LANDINGPAGE_DEFAULT = 'landingPageDefault';
export const GRID_LAYOUT_ENTITY_QUEUE_DEFAULT = 'entityQueueDefault';
export const GRID_LAYOUT_FULLQUOTE_DEFAULT = 'fullquoteDefault';
export const GRID_LAYOUT_FULLQUOTE_WIKIFOLIO = 'fullquoteWikifolio';
export const GRID_LAYOUT_FULLQUOTE_DERIVATIVE = 'fullquoteDerivative';
export const GRID_LAYOUT_FULLQUOTE_INDICES = 'fullquoteIndices';
export const GRID_LAYOUT_FULLQUOTE_FONDS = 'fullquoteFonds';
export const GRID_LAYOUT_FULLQUOTE_STOCK = 'fullquoteStock';
export const GRID_LAYOUT_FULLQUOTE_DIVERSE = 'fullquoteDiverse';
export const GRID_LAYOUT_FULLQUOTE_OBLIGATIONS = 'fullquoteObligations';
export const GRID_LAYOUT_FULLQUOTE_RAW_MATERIALS = 'fullquoteRawMaterials';
export const GRID_LAYOUT_FULLQUOTE_NEW_EMISSIONS = 'fullquoteNewEmissions';
export const GRID_LAYOUT_FULLQUOTE_INTEREST = 'fullquoteInterest';
export const GRID_LAYOUT_FULLQUOTE_CRYPTO_CURRENCIES =
  'fullquoteCryptoCurrencies';
export const GRID_LAYOUT_FULLQUOTE_INTERACTIVE_CHART =
  'fullquoteInteractiveChart';
export const GRID_LAYOUT_FULLQUOTE_INTERACTIVE_CHART_HYBRID =
  'fullquoteInteractiveChartHybrid';
export const GRID_LAYOUT_FULLQUOTE_DERIVATES_SIMULATOR =
  'fullquoteDerivateSimulator';
export const GRID_LAYOUT_TEASER_3X2 = 'teaser3x2';
export const GRID_LAYOUT_TEASER_3X4 = 'teaser3x4';
export const GRID_LAYOUT_PORTFOLIO_NEWS = 'portfolioNews';
export const GRID_LAYOUT_TEASER_OVERVIEWPAGE = 'teaserOverviewPage';
export const GRID_LAYOUT_TEASER_BOOKMARK_4X4 = 'teaserBookmark4x4';

import React from 'react';
import Table from '../../Table';
import { filterSoldItems } from '../../Portfolio';
import { TRADING_IDEAS_TABLE_HEADERS } from '../../Portfolio/constants';
import styles from './styles.legacy.css';

const MusterportfolioTable = ({ portfolio, origin, type }) => {
  const filteredPortfolio = filterSoldItems(portfolio);
  const instruments = portfolio?.calculatedFields?.instruments;

  switch (type) {
    case 'sell':
      const hasSellTransactions = instruments?.some(
        (instrument) =>
          instrument?.transactions &&
          instrument?.transactions.some(({ type }) => type === 'SELL'),
      );

      return (
        <>
          {(hasSellTransactions && (
            <div className={styles.TableHeading}>Verkäufe</div>
          )) ||
            null}
          <Table
            isDirtySortTableRef={null}
            component={type}
            data={portfolio}
            groupType={'no-grouping'}
            type="transactions-sell"
            tableHeaders={TRADING_IDEAS_TABLE_HEADERS}
            location={null}
            origin={origin}
            itemsPerPage={3}
          />
        </>
      );
    case 'buy':
      const hasBuyTransactions = instruments?.some(
        (instrument) =>
          instrument?.transactions &&
          instrument?.transactions.some(({ type }) => type === 'BUY'),
      );
      return (
        <>
          {(hasBuyTransactions && (
            <div className={styles.TableHeading}>Käufe</div>
          )) ||
            null}
          <Table
            isDirtySortTableRef={null}
            component={type}
            data={portfolio}
            groupType={'no-grouping'}
            type="transactions-buy"
            tableHeaders={TRADING_IDEAS_TABLE_HEADERS}
            location={null}
            origin={origin}
            itemsPerPage={3}
          />
        </>
      );
    default:
      return (
        <Table
          isDirtySortTableRef={null}
          component={type}
          data={filteredPortfolio}
          groupType={'no-grouping'}
          type="muster-portfolio"
          tableHeaders={TRADING_IDEAS_TABLE_HEADERS}
          location={null}
          origin={origin}
        />
      );
  }
};

export default MusterportfolioTable;

export const orderbookFieldMapping = {
  default: {
    bid: 'bid',
    bidDatetime: 'bidDatetime',
    bidVolume: 'bidVolume',
    ask: 'ask',
    askDatetime: 'askDatetime',
    askVolume: 'askVolume',
  },
  1: {
    bid: 'bidValue1st',
    bidDatetime: 'bidDatetime1st',
    bidVolume: 'bidVolume1st',
    ask: 'askValue1st',
    askDatetime: 'askDatetime1st',
    askVolume: 'askVolume1st',
  },
  2: {
    bid: 'bidValue2nd',
    bidDatetime: 'bidDatetime2nd',
    bidVolume: 'bidVolume2nd',
    ask: 'askValue2nd',
    askDatetime: 'askDatetime2nd',
    askVolume: 'askVolume2nd',
  },
  3: {
    bid: 'bidValue3rd',
    bidDatetime: 'bidDatetime3rd',
    bidVolume: 'bidVolume3rd',
    ask: 'askValue3rd',
    askDatetime: 'askDatetime3rd',
    askVolume: 'askVolume3rd',
  },
  4: {
    bid: 'bidValue4th',
    bidDatetime: 'bidDatetime4th',
    bidVolume: 'bidVolume4th',
    ask: 'askValue4th',
    askDatetime: 'askDatetime4th',
    askVolume: 'askVolume4th',
  },
  5: {
    bid: 'bidValue5th',
    bidDatetime: 'bidDatetime5th',
    bidVolume: 'bidVolume5th',
    ask: 'askValue5th',
    askDatetime: 'askDatetime5th',
    askVolume: 'askVolume5th',
  },
  6: {
    bid: 'bidValue6th',
    bidDatetime: 'bidDatetime6th',
    bidVolume: 'bidVolume6th',
    ask: 'askValue6th',
    askDatetime: 'askDatetime6th',
    askVolume: 'askVolume6th',
  },
  7: {
    bid: 'bidValue7th',
    bidDatetime: 'bidDatetime7th',
    bidVolume: 'bidVolume7th',
    ask: 'askValue7th',
    askDatetime: 'askDatetime7th',
    askVolume: 'askVolume7th',
  },
  8: {
    bid: 'bidValue8th',
    bidDatetime: 'bidDatetime8th',
    bidVolume: 'bidVolume8th',
    ask: 'askValue8th',
    askDatetime: 'askDatetime8th',
    askVolume: 'askVolume8th',
  },
  9: {
    bid: 'bidValue9th',
    bidDatetime: 'bidDatetime9th',
    bidVolume: 'bidVolume9th',
    ask: 'askValue9th',
    askDatetime: 'askDatetime9th',
    askVolume: 'askVolume9th',
  },
  10: {
    bid: 'bidValue10th',
    bidDatetime: 'bidDatetime10th',
    bidVolume: 'bidVolume10th',
    ask: 'askValue10th',
    askDatetime: 'askDatetime10th',
    askVolume: 'askVolume10th',
  },
};

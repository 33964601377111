// all these fields are available in case the editor forgot to add any config
export const defaultConfig = {
  'Anzahl ausgegebener Wertpapiere': 'tradedShares',
  'Aufgelaufene Zinsen': 'accInt',
  Börse: 'marketDescription',
  'Dividende Bruttobetrag': 'lastDividend',
  'Dividenden Rendite': 'yldeq',
  'Domizil Börse': 'exchangeDomicile',
  Domizil: 'domicile',
  Duration: 'duration',
  'Einheit Verzinsungsfrequenz': 'paymentFrequencyUnit',
  Einheit: 'unit',
  Emissionsdatum: 'issueDate',
  Emissionspreis: 'issuePrice',
  'Emissionsvolumen in Mia.': 'issueAmt',
  Emissionsvolumen: 'issueVolume',
  Emittent: 'companyFullName',
  Fälligkeit: 'maturity',
  Gewinnverwendung: 'dividendPolicy',
  Handelswährung: 'mCur',
  Instrumenteneinheit: 'instrumentUnit',
  Instrumententyp: 'instrumentType',
  ISIN: 'isin',
  Konvexität: 'convexity',
  'Kündigungsrecht Emittent': 'callbyissuer',
  'Kündigungsrecht Gläubiger': 'callbyholder',
  'Letzter Handelstag': 'lastTradingDate',
  Marktkapital: 'marketCap',
  'Mod. Duration': 'mdur',
  Name: 'mName',
  Nominalwährung: 'nominalCurrency',
  Nominalwert: 'nominal',
  'Rendite YTM': 'yield',
  Restlaufzeit: 'rlife',
  Rücknahmekurs: 'redemptionPayment',
  Sektor: 'sector',
  'Stückelung/Nennwert': 'denomination',
  Symbol: 'mSymb',
  Valor: 'mValor',
  'Vor-/Nachrangig': 'seniority',
  Währung: 'mCur',
  WKN: 'wkn',
  'Zahlungsdatum ex-Div': 'lastDividendDatetime',
  Zinsberechnungsmethode: 'dayCountConvention',
  Zinshäufigkeit: 'paymentFrequency',
  'Zinssatz p.a.': 'interest',
  Zinssatz: 'interest',
  Zinstermin: 'interestDate',
};

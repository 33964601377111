import { CHART_COMPARISON_TABLE } from '../../../../screens/MyCash/components/Table/constants';

export const TABLE_HEADERS = {
  [CHART_COMPARISON_TABLE]: [
    'chartColors',
    'mName',
    'currentPrice',
    'lvalDatetime',
    'iNetVperprVPr',
    'perfPercentage1w',
    'perfPercentage4w',
    'perfPercentage52w',
    'perfPercentageYTD',
    'hi52w',
    'lo52w',
    'mCur',
  ],
};

export const CHART_COMPARISON_ORIGIN = 'CHART_COMPARISON_ORIGIN';

export enum NavigationMenuType {
  DEFAULT = 'navigation/default',
  USER = 'navigation/user',
  SEARCH = 'navigation/search',
  FLYOUT_NAVI_MENU = 'navigation/flyout-menu',
  LOGIN = 'navigation/login',
}

export enum ModalOverlayType {
  DEFAULT = 'overlay/default',
  NAVIGATION = 'overlay/navigation',
  SEARCH = 'overlay/search',
  FLYOUT_NAVI_MENU = 'overlay/flyout-menu',
}

import React from 'react';
import classnames from 'classnames';
import styles from './styles.legacy.css';
import { SkeletonProps } from './typings';

const Skeleton = ({ show, addClass }: SkeletonProps) => {
  return (
    (show && (
      <div
        className={classnames(styles.Skeleton, { [addClass]: !!addClass })}
      ></div>
    )) ||
    null
  );
};

export default Skeleton;

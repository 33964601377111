import React, { ReactElement, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { testLog } from '../../../../../../../shared/tests/helpers';
import navigationStateSelector from '../../../../../../../shared/selectors/navigationStateSelector';
import { setNavigationVisible } from '../../../../../../../shared/actions/navigation';
import Link from '../../../../../../../common/components/Link';
import Icon from '../../../Icon';
import NavigationAdIntegration from './components/NavigationAdIntegration';
import { NavigationMenuType } from '../../../../../../shared/constants/enums';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { NavigationMenuProps } from './typings';

// jest.mock('../../../../../../../common/components/Link');
// jest.mock('../../../Icon');
// jest.mock('./components/NavigationAdIntegration');

export type NavigationMenuPropsInner = NavigationMenuProps & {
  closeFlyoutMenuDebounced?: (() => void) & {
    cancel?: () => void;
  };
  cancelToggleFlyoutMenu?: () => void;
};

const NavigationFlyoutMenu = ({
  primaryMenuLinks,
  closeFlyoutMenuDebounced,
  cancelToggleFlyoutMenu,
}: NavigationMenuPropsInner): ReactElement => {
  const dispatch = useDispatch();
  const visibleNavigation = useSelector(
    (state) => navigationStateSelector(state).visibleNavigation,
  );
  const activeVerticalMenu = useSelector(
    (state) => navigationStateSelector(state).activeVerticalMenu,
  );

  const flyoutNavigationMenuRef = useRef(null);

  const closeMenu = useCallback(() => {
    if (!visibleNavigation) {
      return;
    }
    dispatch(setNavigationVisible(null));
    testLog('closeMenu handler handler has been called');
  }, [dispatch, visibleNavigation]);

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event?.keyCode === 27) {
        closeMenu();
      }
    },
    [closeMenu],
  );

  const handleMouseLeave = useCallback(() => {
    if (visibleNavigation) {
      closeFlyoutMenuDebounced();
    }
  }, [visibleNavigation, closeFlyoutMenuDebounced]);

  const handleMouseEnter = useCallback(() => {
    if (cancelToggleFlyoutMenu) {
      cancelToggleFlyoutMenu();
    }
    if (closeFlyoutMenuDebounced.cancel) {
      closeFlyoutMenuDebounced.cancel();
    }
  }, [closeFlyoutMenuDebounced, cancelToggleFlyoutMenu]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        flyoutNavigationMenuRef.current &&
        !flyoutNavigationMenuRef.current.contains(event.target) &&
        visibleNavigation
      ) {
        closeFlyoutMenuDebounced();
      }
    },
    [flyoutNavigationMenuRef, visibleNavigation, closeFlyoutMenuDebounced],
  );

  useEffect(() => {
    if (visibleNavigation === NavigationMenuType.FLYOUT_NAVI_MENU) {
      global.addEventListener('keydown', handleKeydown);
      global.addEventListener('click', handleClickOutside);
    }

    return () => {
      global.removeEventListener('keydown', handleKeydown);
      global.removeEventListener('click', handleClickOutside);
    };
  }, [
    handleKeydown,
    handleClickOutside,
    handleMouseLeave,
    visibleNavigation,
    handleMouseEnter,
  ]);

  if (!primaryMenuLinks?.length) {
    return null;
  }

  const activeMenuLabel = (): string => {
    const menu = primaryMenuLinks.filter(
      (menu) => menu.node.id === activeVerticalMenu,
    )[0].node.link.label;

    return menu;
  };

  return (
    <nav
      className={classNames(grid.ContainerPullOut, styles.MenuWrapper)}
      ref={flyoutNavigationMenuRef}
      data-testid="flyout-menu-items-wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        className={classNames('track-menu', styles.CloseButton)}
        aria-label="Menu schließen"
        data-track-action="close"
        data-track-element="flyout-menu"
        data-testid={`flyout-menu-close-button`}
      >
        <Icon type="IconXMark" onClick={closeMenu} />
      </button>
      {primaryMenuLinks.map(({ node }) => (
        <ul
          key={`${node.id}`}
          className={classNames(styles.ListItem, styles.SubMenu, {
            [styles.ActiveSubMenu]: node.id === activeVerticalMenu,
          })}
          id={`${node?.link?.label}`}
        >
          <li className={grid.Row}>
            {node?.subtree?.edges?.map((subItem, index) => {
              return (
                <ul
                  key={`${subItem.node.id}`}
                  className={classNames(grid.ColMd6, styles.ListItem)}
                  data-testid={`flyout-menu-title-link`}
                >
                  <Link
                    onClick={closeMenu}
                    className={classNames('track-menu', styles.TitleLink)}
                    path={subItem?.node?.link?.path}
                    data-track-action="click"
                    data-track-element={`flyout-menu-title-link-${index}`}
                  >
                    {subItem?.node?.link?.label}
                  </Link>

                  {subItem?.node?.subtree?.edges?.map((subItemThird, index) => (
                    <li
                      key={`${subItemThird.node.id}`}
                      data-testid={`flyout-menu-link`}
                    >
                      <Link
                        onClick={closeMenu}
                        className={classNames('track-menu', styles.Link)}
                        path={subItemThird?.node?.link?.path}
                        data-track-action="click"
                        data-track-element={`flyout-link-${index}`}
                      >
                        {subItemThird?.node?.link?.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              );
            })}
            <NavigationAdIntegration
              menu={node}
              activeLabel={activeMenuLabel()}
            />
          </li>
        </ul>
      ))}
    </nav>
  );
};

export default NavigationFlyoutMenu;

import { isListingKeyList } from '../MyCash/components/Portfolio/helpers';
import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import {
  DROPDOWN_CURRENCIES,
  DROPDOWN_QUOTES,
  DROPDOWN_RAW_MATERIAL,
} from '../../components/Widgets/components/QuoteList/constants';
import { GET_QUOTES_TABLE_DATA } from '../../components/Widgets/components/QuoteList/queries';
import { GET_QUOTELIST_PAGE } from './queries';

export const quoteListPageApolloConfig: RaschApolloConfig = {
  options: ({ location, params }) => {
    const page = (location.query?.page || 1) * 1;
    const sortBy = location.query?.sortBy || null;
    const direction = location.query?.direction || null;
    const quoteList = `quotelist-kurse?cache-buster=${location.pathname}`;
    const quoteListSubpage = `quotelist-kurse/${params['*']}`;

    const splitParams = params['*'].split('/');
    const quoteType = splitParams[splitParams.length - 1];
    let currentItem = null;

    Object.entries({
      ...DROPDOWN_QUOTES,
      ...DROPDOWN_CURRENCIES,
      ...DROPDOWN_RAW_MATERIAL,
    }).forEach(([key, value]) => {
      if (!key) {
        return;
      }
      value.forEach((item) => {
        if (item.key === quoteType) {
          currentItem = item;
        }
      });
    });
    const itemsPerPage = currentItem?.itemsPerPage || 0;
    const offset =
      currentItem?.itemsPerPage > 0 ? (page - 1) * itemsPerPage : 0;
    const isCustomListingKey = currentItem?.listingKeys?.startsWith('custom-');

    return {
      query: GET_QUOTELIST_PAGE,
      additionalQuery: GET_QUOTES_TABLE_DATA,
      additionalVariables: {
        listingKeys: currentItem?.listingKeys || '',
        constituents: currentItem?.constituents,
        limit: itemsPerPage,
        offset: offset,
        sortBy,
        direction,
      },
      variables: {
        quoteListSubtype: quoteList,
        quoteListSubPage: quoteListSubpage,
        publication: 'CASH',
      },
      skip:
        !currentItem?.listingKeys ||
        (!isCustomListingKey && !isListingKeyList(currentItem?.listingKeys)),
      fetchPolicy: 'network-only',
      ssr: false,
    };
  },
};

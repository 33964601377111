import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { compose } from 'recompose';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import autoUpdateStateSelector from '../../../../../../../shared/selectors/autoUpdateStateSelector';
import locationStateSelector from '../../../../../../shared/selectors/locationStateSelector';
import withScrollOnLoad from '../../../../../../../shared/decorators/withScrollOnLoad';
import { setInstrumentKeysAnonymous } from '../../../../../../shared/actions/autoUpdate';
import useRaschRouterLocation from '../../../../../../../shared/hooks/useRaschRouterLocation';
import Link from '../../../../../../../common/components/Link';
import Table from '../../../../screens/MyCash/components/Table';
import Breadcrumbs from '../../../Breadcrumbs';
import Dropdown from '../../../Dropdown';
import DropdownItem from '../../../Dropdown/components/DropdownItem';
import Icon from '../../../Icon';
import Pager, { PAGER_TYPE_PAGE_LOADER } from '../../../../components/Pager';
import {
  AD_PLACEMENT_SLOTS_QUOTELIST,
  QUOTELIST_CHARACTER_COUNTS,
  enrichItemsWithADs,
} from './ads';
import {
  DEFAULT_TABLE,
  HIGHT_LOW_TABLE,
  HIGHT_LOW_VIEW,
  MONITOR_TABLE,
  MONITOR_VIEW,
  PERFORMANCE_TABLE,
  PERFORMANCE_VIEW,
  TRADER_TABLE,
  TRADER_VIEW,
  VOLUME_TABLE,
  VOLUME_VIEW,
} from '../../../../screens/MyCash/components/Table/constants';
import {
  DROPDOWN_CURRENCIES,
  DROPDOWN_QUOTES,
  DROPDOWN_RAW_MATERIAL,
  DROPDOWN_VIEW,
  QUOTES_TABLE_HEADERS,
} from './constants';
import styles from './styles.legacy.css';
import { hashString } from '../../../../../../../shared/helpers/utils';
import { sortTableItems } from '../../../../screens/MyCash/components/Table/helpers';

const PAGER_ANCHOR_SCROLL_ID = 'page';

const tableByViewtype = (viewtype) => {
  switch (viewtype) {
    case VOLUME_VIEW:
      return VOLUME_TABLE;
    case HIGHT_LOW_VIEW:
      return HIGHT_LOW_TABLE;
    case TRADER_VIEW:
      return TRADER_TABLE;
    case PERFORMANCE_VIEW:
      return PERFORMANCE_TABLE;
    case MONITOR_VIEW:
      return MONITOR_TABLE;
    default:
      return DEFAULT_TABLE;
  }
};

const QuoteList = ({ widgetParagraph }) => {
  const isDirtySortTableRef = useRef(null);
  const [tableDataUpdated, setTableDataUpdated] = useState(null);
  const updateDataHashdRef = useRef(null);
  const dispatch = useDispatch();
  const location = useRaschRouterLocation();
  const params = useParams();
  const tableView = location?.query?.ansicht || DEFAULT_TABLE;
  const page = (location?.query?.page || 1) * 1;
  const isHybridApp = useSelector(
    (state) => locationStateSelector(state).isHybridApp,
  );
  const screenReady = useSelector(
    (state) => locationStateSelector(state).screenReady,
  );
  const autoUpdateData = useSelector(
    (state) => autoUpdateStateSelector(state).data,
  );
  const queryCopy = JSON.parse(JSON.stringify(location?.query));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams({});
  const splitParams = params['*']?.split?.('/');
  const quoteType = splitParams?.[splitParams?.length - 1];
  let currentValue = null;

  Object.entries({
    ...DROPDOWN_QUOTES,
    ...DROPDOWN_CURRENCIES,
    ...DROPDOWN_RAW_MATERIAL,
  }).forEach(([key, value]) => {
    if (!key) {
      return;
    }
    value.forEach((item) => {
      if (item.key === quoteType) {
        currentValue = item;
      }
    });
  });

  const tableType = tableByViewtype(tableView);
  const nodes = useMemo(
    () => widgetParagraph?.quoteList?.quoteList?.edges || [],
    [widgetParagraph],
  );

  useEffect(() => {
    let closedMarketInstruments: any = [];
    if (
      currentValue?.listingKeys &&
      screenReady &&
      !currentValue?.disableUpdate
    ) {
      nodes.map(({ node }) => {
        if (node.isMarketOpen === false && node.instrumentKey) {
          closedMarketInstruments.push({
            listingKey: node.instrumentKey,
            isMarketOpen: node.isMarketOpen,
          });
        }
      });

      const instrumentToUpdate = {
        ...((currentValue?.constituents && {
          listingKey: currentValue.listingKeys,
          constituents: currentValue.constituents,
          isMarketOpen: true,
        }) || {
          listingKey: currentValue.listingKeys,
          isMarketOpen: true,
        }),
      };
      dispatch(
        setInstrumentKeysAnonymous([
          instrumentToUpdate,
          ...closedMarketInstruments,
        ]),
      );
    }
    return () => {
      closedMarketInstruments = [];
    };
  }, [
    dispatch,
    currentValue?.listingKeys,
    currentValue?.constituents,
    currentValue?.disableUpdate,
    screenReady,
    nodes,
  ]);

  const tableData = useMemo(() => {
    return {
      ...widgetParagraph,
      items: nodes.map((node) => {
        return {
          ...node.node,
        };
      }),
    };
  }, [nodes, widgetParagraph]);

  if (tableData?.items && queryCopy?.sortBy) {
    // sort when user has clicked on sort
    if (
      // sort when we got params from url on load
      queryCopy?.sortBy
    ) {
      // because of the skeletons we now need to merge the autoupdate fields into the table data so we can sort it
      tableData.items = tableData.items.map((item) => {
        const updatedItem = autoUpdateData[item.listingId];
        if (updatedItem) {
          return {
            ...item,
            ...updatedItem,
          };
        }
        return item;
      });
    }
  }

  const tableDataCopy = JSON.parse(JSON.stringify(tableData));
  if (tableDataCopy?.items?.length > 0) {
    tableDataCopy.items = enrichItemsWithADs({
      items: tableDataCopy.items,
      adPlacementSlots: AD_PLACEMENT_SLOTS_QUOTELIST,
      characterCount: QUOTELIST_CHARACTER_COUNTS,
    });
  }

  const hashData = (data: any): string | null => {
    if (!data) {
      return null;
    }
    return hashString(JSON.stringify(data)).toString();
  };

  useEffect(() => {
    const autoUpdateDataHash = hashData(autoUpdateData);
    if (
      Object.keys(autoUpdateData).length > 0 &&
      queryCopy?.sortBy &&
      autoUpdateDataHash &&
      autoUpdateDataHash !== updateDataHashdRef?.current
    ) {
      updateDataHashdRef.current = autoUpdateDataHash;
      tableData.items = tableData.items.map((item) => {
        const updatedItem = autoUpdateData[item.listingId];
        if (updatedItem) {
          return {
            ...item,
            ...updatedItem,
          };
        }
        return item;
      });
      const tableDataCopy = JSON.parse(JSON.stringify(tableData));
      if (tableDataCopy?.items?.length > 0) {
        tableDataCopy.items = enrichItemsWithADs({
          items: tableDataCopy.items,
          adPlacementSlots: AD_PLACEMENT_SLOTS_QUOTELIST,
          characterCount: QUOTELIST_CHARACTER_COUNTS,
        });
      }
      tableDataCopy.items = sortTableItems(
        tableDataCopy.items,
        queryCopy.sortBy,
        queryCopy.direction,
      );
      setTableDataUpdated(tableDataCopy);
    }
  }, [autoUpdateData, tableData, queryCopy]);

  const breadcrumbItems: Omit<ActiveMenuTrailItemConnection, 'pageInfo'> = {
    count: 2,
    totalCount: 2,
    edges: (location.pathname === '/kurse' && [
      {
        node: {
          id: '',
          link: null,
          label: 'Kurse',
        },
      },
    ]) || [
      {
        node: {
          id: '',
          link: '/kurse/aktien/schweiz/smi-index',
          label: 'Kurse',
        },
      },
      {
        node: {
          id: '',
          link: null,
          label: currentValue?.label,
        },
      },
    ],
  };

  const debouncedSetSearchParams = debounce((params) => {
    setSearchParams(params);
  }, 100);

  return (
    <div>
      {isHybridApp ? null : (
        <Breadcrumbs pageUrl={location.pathname} items={breadcrumbItems} />
      )}
      <h1 className={styles.Heading}>{currentValue?.label || ''}</h1>
      <div className={styles.ActionsWrapper}>
        <div className={styles.ActionsWrapper}>
          <Dropdown
            align="left"
            iconTypeRight="IconChevronDown"
            iconTypeRightActive="IconChevronUp"
            label="Aktien"
            variant="secondary"
            updateButtonLabelOnChange={false}
          >
            <div className={styles.DropdownWrapper}>
              {Object.entries(DROPDOWN_QUOTES).map(([key, value]) => {
                return (
                  <>
                    <DropdownItem label={key}>
                      {({ label }) => (
                        <div
                          key={`dropdown-option-${key}`}
                          className={classNames(
                            styles.DropdownItem,
                            styles.Title,
                          )}
                        >
                          {label}
                        </div>
                      )}
                    </DropdownItem>
                    {value.map((item, index) => {
                      return (
                        <DropdownItem
                          key={`quote-option-${index}`}
                          label={item.label}
                        >
                          {({ itemId, label, handleOptionClick }) => (
                            <Link
                              className={classNames(
                                styles.DropdownItem,
                                styles.Link,
                              )}
                              key={`dropdown-option-${itemId}`}
                              path={item.path}
                              onClick={() => {
                                handleOptionClick(itemId);
                              }}
                            >
                              <>
                                {label}
                                {quoteType === item.key && (
                                  <Icon
                                    addClass={styles.Icon}
                                    type="IconCheck"
                                  />
                                )}
                              </>
                            </Link>
                          )}
                        </DropdownItem>
                      );
                    })}
                  </>
                );
              })}
            </div>
          </Dropdown>
          <Dropdown
            align="left"
            iconTypeRight="IconChevronDown"
            iconTypeRightActive="IconChevronUp"
            label="Währungen"
            variant="secondary"
            updateButtonLabelOnChange={false}
          >
            <div className={styles.DropdownWrapper}>
              {Object.entries(DROPDOWN_CURRENCIES).map(([key, value]) => {
                return (
                  <>
                    <DropdownItem label={key}>
                      {({ label }) => (
                        <div
                          key={`dropdown-option-${key}`}
                          className={classNames(
                            styles.DropdownItem,
                            styles.Title,
                          )}
                        >
                          {label}
                        </div>
                      )}
                    </DropdownItem>
                    {value.map((item, index) => {
                      return (
                        <DropdownItem
                          key={`qurrencies-option-${index}`}
                          label={item.label}
                        >
                          {({ itemId, label, handleOptionClick }) => (
                            <Link
                              className={classNames(
                                styles.DropdownItem,
                                styles.Link,
                              )}
                              key={`dropdown-option-${itemId}`}
                              path={item.path}
                              onClick={() => {
                                handleOptionClick(itemId);
                              }}
                            >
                              <>
                                {label}
                                {quoteType === item.key && (
                                  <Icon
                                    addClass={styles.Icon}
                                    type="IconCheck"
                                  />
                                )}
                              </>
                            </Link>
                          )}
                        </DropdownItem>
                      );
                    })}
                  </>
                );
              })}
            </div>
          </Dropdown>
          <Dropdown
            align="left"
            iconTypeRight="IconChevronDown"
            iconTypeRightActive="IconChevronUp"
            label="Rohstoffe und Edelmetalle"
            variant="secondary"
            updateButtonLabelOnChange={false}
          >
            <div className={styles.DropdownWrapper}>
              {Object.entries(DROPDOWN_RAW_MATERIAL).map(([key, value]) => {
                return (
                  <>
                    <DropdownItem label={key}>
                      {({ label }) => (
                        <div
                          key={`dropdown-option-${key}`}
                          className={classNames(
                            styles.DropdownItem,
                            styles.Title,
                          )}
                        >
                          {label}
                        </div>
                      )}
                    </DropdownItem>
                    {value.map((item) => {
                      return (
                        <DropdownItem
                          key={`raw-option-${item.key}`}
                          label={item.label}
                        >
                          {({ itemId, label, handleOptionClick }) => (
                            <Link
                              className={classNames(
                                styles.DropdownItem,
                                styles.Link,
                              )}
                              key={`dropdown-option-${itemId}`}
                              path={item.path}
                              onClick={() => {
                                handleOptionClick(itemId);
                              }}
                            >
                              <>
                                {label}
                                {quoteType === item.key && (
                                  <Icon
                                    addClass={styles.Icon}
                                    type="IconCheck"
                                  />
                                )}
                              </>
                            </Link>
                          )}
                        </DropdownItem>
                      );
                    })}
                  </>
                );
              })}
              {/* somehow the factory expect to have more the one item as children */}
              <></>
            </div>
          </Dropdown>
        </div>
        <div>
          <Dropdown
            align="right"
            iconTypeRight="IconChevronDown"
            iconTypeRightActive="IconChevronUp"
            label="Ansicht"
            variant="secondary"
            updateButtonLabelOnChange={false}
          >
            <>
              {Object.entries(DROPDOWN_VIEW).map(([key, value]) => {
                return (
                  <>
                    {value.map((item) => {
                      return (
                        <DropdownItem
                          key={`view-option-${key}-${item.key}`}
                          label={item.label}
                        >
                          {({ itemId, label, handleOptionClick }) => (
                            <Link
                              className={classNames(
                                styles.DropdownItem,
                                styles.Link,
                              )}
                              key={`dropdown-option-${itemId}`}
                              onClick={() => {
                                if (item.key === DEFAULT_TABLE) {
                                  delete location.query.ansicht;
                                  const newParams = {
                                    ...location.query,
                                  };
                                  debouncedSetSearchParams(newParams);
                                  handleOptionClick(itemId);
                                  return;
                                }
                                const newParams = {
                                  ...location.query,
                                  ansicht: item.action,
                                };
                                debouncedSetSearchParams(newParams);
                                handleOptionClick(itemId);
                              }}
                            >
                              <>
                                {label}
                                {tableView === item.action && (
                                  <Icon
                                    addClass={styles.Icon}
                                    type="IconCheck"
                                  />
                                )}
                              </>
                            </Link>
                          )}
                        </DropdownItem>
                      );
                    })}
                  </>
                );
              })}
              {/* somehow the factory expect to have more the one item as children */}
              <></>
            </>
          </Dropdown>
        </div>
      </div>

      <div id={PAGER_ANCHOR_SCROLL_ID}></div>

      <Table
        isDirtySortTableRef={isDirtySortTableRef}
        component={tableType}
        data={tableDataUpdated || tableDataCopy}
        groupType={'no-grouping'}
        type="quote-list"
        tableHeaders={QUOTES_TABLE_HEADERS}
        location={location}
      />

      {currentValue?.itemsPerPage &&
        widgetParagraph?.quoteList?.quoteList?.count && (
          <Pager
            itemsCount={widgetParagraph?.quoteList?.quoteList?.count || 0}
            itemsPerPage={currentValue.itemsPerPage}
            currentPage={page}
            component={PAGER_TYPE_PAGE_LOADER}
            anchorScrollId={PAGER_ANCHOR_SCROLL_ID}
          />
        )}
    </div>
  );
};

export default compose(withScrollOnLoad({ offset: 170 }))(QuoteList);

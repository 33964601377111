import React, { ReactElement, memo } from 'react';
import createComponentSwitch from '../../../../../../../../shared/decorators/componentSwitch';
import ChunkProgressBar from './components/ChunkProgressBar';
import ProgressBar from './components/ProgressBar';
import RatingRange from './components/Range';
import {
  RATING_CHUNK_PROGRESS_BAR,
  RATING_PROGRESS_BAR,
  RATING_RANGE,
} from './constants';
import { RatingProps } from './typings';

const Switch = createComponentSwitch({
  [RATING_RANGE]: RatingRange,
  [RATING_PROGRESS_BAR]: ProgressBar,
  [RATING_CHUNK_PROGRESS_BAR]: ChunkProgressBar,
});

const Widgets = (props): ReactElement => {
  return <Switch component={props.component} {...props} />;
};

export default memo<RatingProps>(Widgets);

import { BOTTOM_AD_1, INDEX_AD_1 } from '../../../AppNexus/constants';
import { AdZoneProps } from '../AdZone/typings';

const DEVICE_TYPES = ['mobile', 'tabletDesktop'];

const getSlotConfigBySlotNameAndDeviceType = (
  slotName: string,
  deviceType: string,
) => {
  return {
    slotName,
    deviceType,
  };
};

export const AD_PLACEMENT_SLOTS_QUOTELIST = {
  mobile: {
    sequence: [INDEX_AD_1],
    repeater: INDEX_AD_1,
    last: BOTTOM_AD_1,
  },
  tabletDesktop: {
    sequence: [INDEX_AD_1],
    repeater: INDEX_AD_1,
    last: BOTTOM_AD_1,
  },
};

export const QUOTELIST_CHARACTER_COUNTS = {
  mobile: [32, 50],
  tabletDesktop: [32, 50],
};

function* adPlacementSlotGenerator(adPlacementSlotsByDeviceType) {
  let i = 0;

  while (true) {
    if (adPlacementSlotsByDeviceType.sequence[i]) {
      // use defined sequence of given device type
      yield {
        type: 'sequence',
        slotName: adPlacementSlotsByDeviceType.sequence[i],
      };
    } else {
      // use repeater
      yield {
        type: 'repeater',
        slotName: adPlacementSlotsByDeviceType.repeater,
      };
    }

    i++;
  }
}

export const enrichItemsWithADs = ({
  items,
  adPlacementSlots,
  characterCount,
}) => {
  if (!items || !adPlacementSlots.tabletDesktop || !adPlacementSlots.mobile) {
    return null;
  }
  // set initial character counts
  const characterCounts = {
    mobile: 0,
    tabletDesktop: 0,
  };

  const usedRepeaterCounts = {
    mobile: 0,
    tabletDesktop: 0,
  };

  const defaultCharacterCounts = JSON.parse(JSON.stringify(characterCount));

  // init slot generators by device type
  const slotGenerators = {
    mobile: adPlacementSlotGenerator(adPlacementSlots.mobile),
    tabletDesktop: adPlacementSlotGenerator(adPlacementSlots.tabletDesktop),
  };

  const pageBodyWithAdSlots = items?.map((entry) => {
    characterCounts.mobile++;
    characterCounts.tabletDesktop++;

    // // check if ad is needed for any device type after this entry
    const adSlots: AdZoneProps[] = DEVICE_TYPES.reduce((acc, deviceType) => {
      if (characterCounts[deviceType] < defaultCharacterCounts[deviceType][0]) {
        // no ad needed
        return acc;
      }

      // define which ad we have to render
      const generatorResponse = slotGenerators[deviceType].next().value;
      const slotName = generatorResponse.slotName;
      if (generatorResponse.type === 'repeater') {
        usedRepeaterCounts[deviceType]++;
      }
      acc.push(getSlotConfigBySlotNameAndDeviceType(slotName, deviceType));

      // // cleanup
      characterCounts[deviceType] = 0;
      if (defaultCharacterCounts[deviceType].length > 1) {
        // remove item from render sequence if we have more then one left
        defaultCharacterCounts[deviceType].splice(0, 1);
      }

      return acc;
    }, []);

    if (adSlots.length > 0) {
      entry.adSlots = adSlots;
    }

    return { ...entry };
  });

  return pageBodyWithAdSlots;
};

import React, { ReactElement, memo } from 'react';
import classNames from 'classnames';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import AppNexus from '../../../AppNexus';
import { isContentAd } from '../../../AppNexus/constants';
import styles from './styles.legacy.css';
import { AdZoneProps } from './typings';

const AdZone = ({ adSlots }: AdZoneProps): ReactElement => {
  if (adSlots.length === 0) {
    return null;
  }

  return (
    <div className={styles.AdZone}>
      {adSlots.map((adSlot, index) => {
        return (
          <span
            className={styles.AdWrapper}
            key={`${adSlot.slotName}-${index}`}
          >
            <div
              className={classNames(
                'ad-wrapper',
                styles.AdPadding,
                (adSlot.deviceType && `ad-wrapper-${adSlot.deviceType}`) || '',
              )}
            >
              <div>
                <TestFragment
                  data-testid="paragraphsrenderer-ad-wrapper"
                  data-slot={adSlot.slotName}
                >
                  <AppNexus
                    slot={adSlot.slotName}
                    isMultiPlacement={isContentAd(adSlot.slotName)}
                    deviceType={adSlot.deviceType}
                  />
                </TestFragment>
              </div>
            </div>
          </span>
        );
      })}
    </div>
  );
};

export default memo(AdZone);

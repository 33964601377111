import React, { useRef } from 'react';
import AutoSuggestSearch from '../../../../../../../components/AutoSuggestSearch';
import { alertsFormOverlay } from '../../../../../../../components/AlertsForm';
import { SEARCH_RESULT_CONFIG } from '../../../constants';
import styles from './styles.legacy.css';
import { AddInstrumentsProps } from './typings';
import { useStableNavigate } from '../../../../../../../../../../shared/hooks/useStableNavigateContext';

const AddInstrumentToAlerts = ({
  closeDrawer,
  searchResultWithBorder = false,
  searchResultHeight,
  isInsideDrawer = false,
}: AddInstrumentsProps) => {
  const navigate = useStableNavigate();
  const searchRef = useRef(null);

  const addToAlerts = (event, listingId, title, item) => {
    event.preventDefault();
    searchRef.current.clearInputfield();
    searchRef.current.closeSearchResult();
    closeDrawer?.();

    alertsFormOverlay({
      alertKey: '',
      fullquoteUri: item?.link,
      navigate,
      location,
    });
  };

  return (
    <AutoSuggestSearch
      ref={searchRef}
      searchResultConfig={SEARCH_RESULT_CONFIG}
      placeholderStyle={styles.InputIcon}
      resultWithBorder={searchResultWithBorder}
      placeholder="Instrument hinzufügen"
      onClickResult={addToAlerts}
      appInputAriaLabel="Add Instruments"
      searchResultHeight={searchResultHeight}
      isInsideDrawer={isInsideDrawer}
    />
  );
};

export default AddInstrumentToAlerts;

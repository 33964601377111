export const OTHER_ASSETS_CURRENCY = [
  {
    value: 'AUD',
    label: 'AUD',
  },
  {
    value: 'CAD',
    label: 'CAD',
  },
  {
    value: 'CHF',
    label: 'CHF',
  },
  {
    value: 'DKK',
    label: 'DKK',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'HKD',
    label: 'HKD',
  },
  {
    value: 'JPY',
    label: 'JPY',
  },
  {
    value: 'NOK',
    label: 'NOK',
  },
  {
    value: 'SEK',
    label: 'SEK',
  },
  {
    value: 'SGD',
    label: 'SGD',
  },
  {
    value: 'TRY',
    label: 'TRY',
  },
  {
    value: 'USD',
    label: 'USD',
  },
];

export const OTHER_ASSETS_MARKET_TYPE = [
  {
    value: 'BON',
    label: 'Obligationen',
  },
  {
    value: 'COM',
    label: 'Commodities',
  },
  {
    value: 'CUR',
    label: 'Währungen',
  },
  {
    value: 'DER',
    label: 'Derivate',
  },
  {
    value: 'ETF',
    label: 'ETF',
  },
  {
    value: 'DIV',
    label: 'Diverse',
  },
  {
    value: 'EQU',
    label: 'Aktien',
  },
  {
    value: 'FON',
    label: 'Fonds',
  },
  {
    value: 'FOP',
    label: 'FOP',
  },
  {
    value: 'IND',
    label: 'Indizes',
  },
  {
    value: 'LIQ',
    label: 'LIQ',
  },
  {
    value: 'FUT',
    label: 'Futures',
  },
  {
    value: 'OPT',
    label: 'Optionen',
  },
  {
    value: 'TFO',
    label: 'Börsengehandelter Fonds',
  },
  {
    value: 'INT',
    label: 'Zinsen',
  },
];

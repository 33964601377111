import raf from 'raf';
import {
  BOND,
  CRYPTO_CURRENCY,
  DERIVATE,
  DIVERSE,
  EQUITY,
  FUND,
  INDEX,
} from './constants';

export const getSearchResults = (textSearch) => [
  {
    type: EQUITY,
    title: 'Aktien',
    items: textSearch?.equity?.items,
  },
  {
    type: INDEX,
    title: 'Index',
    items: textSearch?.index?.items,
  },
  {
    type: CRYPTO_CURRENCY,
    title: 'ETFs',
    items: textSearch?.cryptoCurrency?.items,
  },
  {
    type: FUND,
    title: 'Fund',
    items: textSearch?.fund?.items,
  },
  {
    type: BOND,
    title: 'Bond',
    items: textSearch?.bond?.items,
  },
  {
    type: DERIVATE,
    title: 'Derivate',
    items: textSearch?.derivative?.items,
  },
  {
    type: DIVERSE,
    title: 'Diverse',
    items: textSearch?.diverse?.items,
  },
];

export const enableAutoFocus = ({ target }) => {
  const autosuggestInput = target.parentElement.parentElement.querySelector(
    '.autosuggest-input',
  ) as HTMLInputElement;

  raf(() => {
    autosuggestInput?.focus();
  });
};

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { GET_MINI_PORTFOLIO } from './queries';

export const miniPortfolioApolloConfig: RaschApolloConfig = {
  options: ({ params }) => {
    const portfolioKey = params?.portfolioKey;

    return {
      query: GET_MINI_PORTFOLIO,
      variables: {
        portfolioKey,
        publication: 'CASH',
      },
      ssr: false,
      skip: !portfolioKey,
    };
  },
};

import React from 'react';
import { useQuery } from '@apollo/client';
import {
  DATE_FORMAT_TIME,
  formatDate,
} from '../../../../../../../shared/helpers/dateTimeElapsed';
import Link from '../../../../../../../common/components/Link';
import Icon from '../../../Icon';
import { TENDENCY, UBS_TRENDRADAR_PATH } from './constants';
import { GET_TREND_RADAR_OVERVIEW } from './queries';
import styles from './styles.legacy.css';
import { QueryResult, TrendRadarOverviewProps } from './typings';

const sortData = (list) => {
  const sortedList =
    list?.length > 0 &&
    list?.sort((a, b) => {
      if (!b?.timeSort || a?.timeSort < b?.timeSort) {
        return 1;
      }

      if (!a?.timeSort || a?.timeSort > b?.timeSort) {
        return -1;
      }
      return 0;
    });
  return sortedList;
};

const TrendRadarOverview = ({ widgetParagraph }: TrendRadarOverviewProps) => {
  const path = widgetParagraph?.link?.path;
  const url =
    (path && path.startsWith('/') && new URL(path, global.locationOrigin)) ||
    (path && new URL(path)) ||
    null;
  const mIsin = url?.searchParams?.get('mIsin')?.split(',') || [];

  const { data, error, loading } = useQuery<QueryResult>(
    GET_TREND_RADAR_OVERVIEW,
    {
      variables: {
        mIsin: mIsin,
        limit: 10,
      },
      skip: mIsin.length === 0,
    },
  );

  const dataCopy = JSON.parse(JSON.stringify(data || null));

  if (error || loading || !dataCopy) {
    return <div className={styles.Skeleton}></div>;
  }

  const sortedData = sortData(
    dataCopy?.integration?.solvians?.trendRadar || [],
  );

  return (
    <div>
      <p className={styles.HeaderTitle}>UBS TrendRadar Signals</p>
      <table className={styles.Table}>
        <thead>
          <tr className={styles.Heading}>
            <th>Basiswert</th>
            <th>Signal</th>
            <th>Tendenz</th>
            <th>Datum</th>
            <th className={styles.HideOnMobile}></th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((trendRadar) => {
            const date = formatDate(trendRadar?.timeSort * 1000) || '';
            const time =
              formatDate(trendRadar.timeSort * 1000, DATE_FORMAT_TIME) || '';
            return (
              <tr key={trendRadar.id}>
                <td>
                  <Link className={styles.Link} path={trendRadar?.fullquoteUri}>
                    {trendRadar?.instrument?.name}
                  </Link>
                </td>
                <td>{trendRadar?.patternType?.name}</td>
                <td>{TENDENCY[trendRadar?.breakout[0]?.direction]}</td>
                <td>
                  {date} {time}
                </td>
                <td className={styles.HideOnMobile}>
                  <Link
                    className={styles.Link}
                    path={UBS_TRENDRADAR_PATH.replace(
                      '[signalId]',
                      `${trendRadar.id}`,
                    )}
                  >
                    Signal Details
                    <span className={styles.Icon}>
                      <Icon type="IconArrowRightUpFromSquare" />
                    </span>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TrendRadarOverview;

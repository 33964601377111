import { ROUTE_PORTFOLIOS } from '../../../../constants';

export const getDataByGroup = (data, group) => {
  const newData = Object.keys(data)
    .filter(
      (key) =>
        data[key].group === group ||
        (Array.isArray(data[key].group) && data[key].group.includes(group)),
    )
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});

  return newData;
};

export const getOptionsData = ({ customList, data, isWatchlist = false }) => {
  if (!customList) {
    return [];
  }

  return Object.keys(data).map((key) => {
    return {
      label:
        (isWatchlist &&
          data[key].description.replace('Portfolio', 'Watchlist')) ||
        data[key].description,
      value: key,
      initiallyChecked: customList.includes(key),
      disabled: false,
    };
  });
};

export const getBreadcrumbsByType = (type) => {
  const breadcrumbItems: Omit<ActiveMenuTrailItemConnection, 'pageInfo'> = {
    count: 2,
    totalCount: 2,
    edges: [
      (type === 'watchlist' && {
        node: {
          id: 'watchlist',
          label: 'Watchlist',
          link: '/watchlist',
          __typename: 'ActiveMenuTrailItem',
        },
        __typename: 'ActiveMenuTrailItemEdge',
      }) || {
        node: {
          id: 'portfolios',
          label: 'Portfolios',
          link: `/${ROUTE_PORTFOLIOS}`,
          __typename: 'ActiveMenuTrailItem',
        },
        __typename: 'ActiveMenuTrailItemEdge',
      },
      {
        node: {
          id: 'portfolio',
          label: 'Portfolio',
          link: '/portfolio',
          __typename: 'ActiveMenuTrailItem',
        },
        __typename: 'ActiveMenuTrailItemEdge',
      },
      {
        node: {
          id: 'custom-view',
          label: 'Eigene Ansicht anpassen',
          link: '',
          __typename: 'ActiveMenuTrailItem',
        },
        __typename: 'ActiveMenuTrailItemEdge',
      },
    ],
    __typename: 'ActiveMenuTrailItemConnection',
  };

  return breadcrumbItems;
};

import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Link from '../../../../../../../common/components/Link';
import Picture from '../../../../../../../common/components/Picture';
import ImageCaption from '../ImageCaption';
import { STYLE_INLINE_IMAGE_1200 } from '../../../../../../../shared/constants/images';
import { MULTI_COLUMNS_PARAGRAPH } from '../../../../../../../shared/constants/paragraphs';
import {
  TRACKING_CLASS_IMAGE_PARAGRAPH,
  TRACKING_CLASS_PARAGRAPH,
} from '../../../../../../../shared/constants/tracking';
import { PAGESCREEN_MARKETING_TYPE } from '../../../../screens/PageScreen/constants';
import { CANNOT_SHOW_IMAGE_MESSAGE, FALLBACK_IMAGE_URL } from './constants';
import styles from './styles.legacy.css';
import { ImageParagraphProps } from './typings';

export type ImageParagraphPropsInner = ImageParagraphProps & {
  heroMediaParagraph?: boolean;
};

const ImageParagraph = ({
  imageParagraph,
  plainImage = false,
  origin = '',
  heroMediaParagraph,
  ...props
}: ImageParagraphPropsInner): ReactElement => {
  if (!imageParagraph || !imageParagraph.image) {
    return null;
  }
  const originalUrl =
    (imageParagraph.image?.showOriginal &&
      imageParagraph.image?.file?.origin) ||
    null;

  let imgComp = imageParagraph.image.credit ? (
    <Picture
      url={originalUrl}
      showOriginal={imageParagraph.image?.showOriginal}
      relativeOrigin={imageParagraph.image?.file?.relativeOriginPath}
      alt={imageParagraph.image?.file?.alt}
      style_320={STYLE_INLINE_IMAGE_1200}
      width={imageParagraph?.image?.file?.width}
      height={imageParagraph?.image?.file?.height}
      focalPointX={imageParagraph.image?.file?.focalPointX}
      focalPointY={imageParagraph.image?.file?.focalPointY}
      downloadPriority="high"
    />
  ) : (
    <Picture
      url={FALLBACK_IMAGE_URL}
      alt={CANNOT_SHOW_IMAGE_MESSAGE}
      style_320={STYLE_INLINE_IMAGE_1200}
      width={imageParagraph?.image?.file?.width}
      height={imageParagraph?.image?.file?.height}
      downloadPriority="high"
    />
  );

  if (imageParagraph.link && imageParagraph.link.path) {
    imgComp = <Link {...imageParagraph.link}>{imgComp}</Link>;
  }

  imgComp =
    (imageParagraph?.image?.file?.width < imageParagraph?.image?.file?.height &&
      origin !== MULTI_COLUMNS_PARAGRAPH &&
      !imageParagraph.fullWidth && (
        <div
          className={classNames(styles.PortraitWrapper, {
            [styles.PortraitWrapperHeroMedia]: heroMediaParagraph,
          })}
        >
          {imgComp}
        </div>
      )) ||
    imgComp;

  if (!plainImage) {
    return (
      <div
        className={classNames(
          TRACKING_CLASS_PARAGRAPH,
          TRACKING_CLASS_IMAGE_PARAGRAPH,
          styles.ImageWrapper,
          { [styles.MarketingPage]: origin === PAGESCREEN_MARKETING_TYPE },
        )}
        data-testid="image-paragraph-wrapper"
      >
        {imageParagraph.title && (
          <h2 className={styles.Title}>{imageParagraph.title}</h2>
        )}
        {imgComp}
        {!props.hideCaption && imageParagraph.image?.credit && (
          <ImageCaption
            caption={imageParagraph.caption}
            credit={imageParagraph.image?.credit || ''}
            origin={origin}
            suppressSource={imageParagraph.suppressSource}
          />
        )}
      </div>
    );
  }

  return imgComp;
};

export default ImageParagraph;

import React from 'react';
import { useQuery } from '@apollo/client';
import SectorTable from './components/SectorTable';
import TableRowSkeleton from './components/TableRowSkeleton';
import { GET_SECTOR_QUOTES } from './queries';
import styles from './styles.legacy.css';
import { QueryResult, SectorProps } from './typings';

const Sector = ({ widgetParagraph }: SectorProps) => {
  const path = widgetParagraph?.link?.path;
  const url =
    (path && path.startsWith('/') && new URL(path, global.locationOrigin)) ||
    (path && new URL(path)) ||
    null;

  const listingId = url?.searchParams?.get('listingId');
  const secId = url?.searchParams?.get('secId');
  const encodedSec = encodeURI(path).split('=')[2];
  const sec = decodeURI(encodedSec.replace('&listingId', ''));

  const { data, loading, error } = useQuery<QueryResult>(GET_SECTOR_QUOTES, {
    variables: {
      publication: 'CASH',
      secId,
    },
    skip: !secId,
    ssr: false,
  });

  const instruments =
    data?.getSectorQuotes?.sectorQuotes?.edges.map((edge) => edge.node) || null;

  return (
    <div className={styles.Wrapper} id={'sector-widget-top'}>
      <div className={styles.Title}>{`Branche${
        (sec && `: ${sec}`) || ''
      } `}</div>
      {(loading || error) && <TableRowSkeleton count={10} />}
      {!loading && !error && data && (
        <SectorTable instruments={instruments} activeQuoteKey={listingId} />
      )}
    </div>
  );
};

export default Sector;

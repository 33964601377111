import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { tealiumTrackEvent } from '../../../../../../../shared/helpers/tealium';
import locationStateSelector from '../../../../../../shared/selectors/locationStateSelector';
import Link from '../../../../../../../common/components/Link';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import ButtonWithLoading from '../../../../components/ButtonWithLoading';
import Dropdown from '../../../../components/Dropdown';
import DropdownItem from '../../../../components/Dropdown/components/DropdownItem';
import Icon from '../../../../components/Icon';
import CategoryButtons from '../CategoryButtons';
import EmptyScreen from '../EmptyScreen';
import Card from './components/Card';
import { portfolioCreate } from '../../../../components/PortfolioManagementForm';
import { displayErrorToast } from '../../../../components/Toast';
import modal from '../../../../components/Modal';
import { ROBOTS_META_NOINDEX_FOLLOW_NOODP_NOARCHIVE } from '../../../../../../../shared/constants/structuredData';
import { DEFAULT_ERROR_MESSAGE } from '../../../../components/Toast/constants';
import { EDIT_USER_CUSTOM_PORTFOLIOS_ORDER_SETTINGS } from '../CustomView/queries';
import { GET_PORTFOLIOS } from '../Portfolio/queries';
import styles from './styles.legacy.css';
import { portfolioScreenApolloConfig } from '../Portfolio/apolloConfig';
import { portfoliosApolloConfig } from './apolloConfig';
import { PortfoliosProps } from './typings';
import { useStableNavigate } from '../../../../../../../shared/hooks/useStableNavigateContext';

const breadcrumbItems: Omit<ActiveMenuTrailItemConnection, 'pageInfo'> = {
  count: 2,
  totalCount: 2,
  edges: [
    {
      node: {
        id: '',
        label: 'Portfolios',
        link: null,
        __typename: 'ActiveMenuTrailItem',
      },
      __typename: 'ActiveMenuTrailItemEdge',
    },
  ],
  __typename: 'ActiveMenuTrailItemConnection',
};

const Portfolios = ({ location }: PortfoliosProps) => {
  const navigate = useStableNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [editUserSettingsCustomPortfoliosOrderMutation] = useMutation(
    EDIT_USER_CUSTOM_PORTFOLIOS_ORDER_SETTINGS,
  );
  const isHybridApp = useSelector(
    (state) => locationStateSelector(state).isHybridApp,
  );
  const { query: porfoliosQuery, ...portfoliosOptions } =
    portfoliosApolloConfig.options({
      location,
      params: {
        isAuthenticated: 'true',
      },
    });

  const { loading, data, error, refetch } = useQuery(
    porfoliosQuery,
    portfoliosOptions,
  );

  const allPortfolios: Portfolio[] = (!error && data?.portfolios?.items) || [];
  const hasPortfolios = allPortfolios.length > 0;

  const { ...options } = portfolioScreenApolloConfig.options({
    location,
    params: {
      isAuthenticated: 'true',
    },
  });

  return (
    <>
      <div className={styles.Wrapper}>
        <Helmet
          title="Portfolios | cash"
          meta={[
            {
              name: 'robots',
              content: ROBOTS_META_NOINDEX_FOLLOW_NOODP_NOARCHIVE,
            },
          ]}
        ></Helmet>
        {(!isHybridApp && (
          <Breadcrumbs
            pageUrl={location.pathname}
            items={breadcrumbItems}
            addClass="hide-on-print"
          />
        )) ||
          null}
        <h1 className={classNames(styles.Heading, 'hide-on-print')}>
          Portfolios
        </h1>
        <CategoryButtons pathname={location.pathname} />
        {!hasPortfolios && !loading && !isLoading && (
          <EmptyScreen entity="portfolio" />
        )}
      </div>

      <div className={styles.InnerWrapper}>
        <div className={styles.ActionsWrapper}>
          <div>
            <ButtonWithLoading
              onClick={(event) => {
                event.preventDefault();
                portfolioCreate({ navigate, origin: 'portfolio' });
              }}
              size="small"
              variant="secondary"
              ariaLabel="Neues Portfolio anlegen"
            >
              <Icon type="IconPlus" addClass={styles.Icons}></Icon>
            </ButtonWithLoading>
          </div>
          <Dropdown
            key={`dropdown-${loading || isLoading ? 'loading' : 'loaded'}`}
            align="right"
            variant="secondary"
            iconTypeLeft="IconPenToSquare"
            updateButtonLabelOnChange={false}
            loading={loading || isLoading}
          >
            <>
              <DropdownItem label="Portfolio-Reihenfolge bearbeiten">
                {({ label }) => {
                  return (
                    <Link
                      className={styles.DropdownViewLink}
                      path={`/portfolios/eigene-reihenfolge`}
                    >
                      <div className={styles.DropdownListItem}>
                        <Icon
                          type="IconArrowUpArrowDown"
                          addClass={styles.Icons}
                        ></Icon>
                        <p>{label}</p>
                      </div>
                    </Link>
                  );
                }}
              </DropdownItem>
              <DropdownItem label="Portfolio-Reihenfolge zurücksetzen">
                {({ label, setIsOpen }) => {
                  return (
                    <Link
                      className={styles.DropdownViewLink}
                      onClick={(event) => {
                        event.preventDefault();
                        setIsOpen(false);
                        modal({
                          title: 'Zeilen-Reihenfolge zurücksetzen',
                          hasStickyHeader: true,
                          hasStickyFooter: true,
                          content:
                            'Sind Sie sicher, dass Sie die Reihenfolge zurücksetzen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
                          buttons: [
                            {
                              children: 'Abbrechen',
                            },
                            {
                              variant: 'secondary',
                              children: 'Zurücksetzen',
                              onClick: () => {
                                setIsLoading(true);
                                editUserSettingsCustomPortfoliosOrderMutation({
                                  variables: {
                                    value: 'null',
                                  },
                                  refetchQueries: [
                                    {
                                      query: GET_PORTFOLIOS,
                                      variables: options.variables,
                                    },
                                  ],
                                }).then(async ({ data }): Promise<void> => {
                                  if (
                                    data?.editCustomPortfoliosOrder &&
                                    data?.editCustomPortfoliosOrder?.error &&
                                    data?.editCustomPortfoliosOrder?.error !==
                                      null
                                  ) {
                                    if (
                                      !toast.isActive('user-settings-error')
                                    ) {
                                      displayErrorToast(
                                        DEFAULT_ERROR_MESSAGE,
                                        'user-settings-error',
                                      );
                                    }
                                    setIsLoading(false);
                                    return;
                                  }
                                  const scope = 'portfolios';
                                  const event = 'row_order';
                                  // track tealium event
                                  tealiumTrackEvent({
                                    type: 'link',
                                    payload: {
                                      event_name: `${scope}_edit_custom_portfolios_order_${event}`,
                                      event_category: scope,
                                      event_action: `${scope}_edit_custom_portfolios_order_${event}`,
                                      [`${scope}_key`]: null,
                                    },
                                  });
                                  refetch().finally(() => {
                                    setIsLoading(false);
                                  });
                                });
                              },
                            },
                          ],
                        });
                      }}
                    >
                      <div className={styles.DropdownListItem}>
                        <Icon
                          type="IconArrowRotateLeft"
                          addClass={styles.Icons}
                        ></Icon>
                        <p>{label}</p>
                      </div>
                    </Link>
                  );
                }}
              </DropdownItem>
            </>
          </Dropdown>
        </div>
        <div className={styles.CardWrapper}>
          {allPortfolios.map((portfolio) => (
            <Card
              key={`card-${portfolio?.portfolioKey}`}
              portfolio={portfolio}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Portfolios;

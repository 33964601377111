export const ESI_WIDGETS_FULLQUOTE_HEADER_PATH =
  '/esi-widgets/fullquote-header';
export const ESI_WIDGETS_ACTION_BUTTONS_A = '/esi-widgets/action-buttons';
export const ESI_WIDGETS_LATEST_DATA_PATH = '/esi-widgets/latest-data';
export const ESI_WIDGETS_SUMMARY_PATH = '/esi-widgets/summary';
export const ESI_WIDGETS_ORDERBOOK_PATH = '/esi-widgets/orderbook';
export const ESI_WIDGETS_MASTER_DATA_PATH = '/esi-widgets/master-data';
export const ESI_WIDGETS_CORPORATE_ACTION_PATH =
  'esi-widgets/corporate-actions';
export const ESI_WIDGETS_FUNDAMENTAL_DATA_PATH =
  '/esi-widgets/fundamental-data';
export const ESI_WIDGETS_MARKET_PLACES_PATH = '/esi-widgets/market-places';

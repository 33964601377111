import styles from './styles.legacy.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [
  styles.Container,
  styles.Children,
  styles.Ad,
  styles.Sticky,
  styles.HasChildren,
];

export const recalculateMainContainerMinHeight = () => {
  const mainContainer = document.getElementById('main');
  const monsterskyWrapper = Array.from(
    document.getElementsByClassName(styles.AdWrapper),
  ) as HTMLElement[];

  if (!mainContainer || !monsterskyWrapper || monsterskyWrapper.length === 0) {
    return;
  }

  const footerHeight =
    document.getElementById('footer')?.getBoundingClientRect().height ?? 0;
  const mainContainerHeight =
    mainContainer.getBoundingClientRect().height + footerHeight;
  // use an mutation observer to detect changes in the DOM of the main container if it changes the height then mainContainerHeight
  // needs to be recalculated this can happen if we render some embedded paragraphs or components used with InViewObserver
  const observer = new MutationObserver(() => {
    const newMainContainerHeight =
      mainContainer.getBoundingClientRect().height + footerHeight;
    if (mainContainerHeight !== newMainContainerHeight) {
      recalculateMainContainerMinHeight();
      observer.disconnect();
    }
  });

  // unobserver before observing again
  observer.disconnect();

  observer.observe(mainContainer, {
    attributes: true,
    childList: true,
    subtree: true,
  });

  monsterskyWrapper[0].style.height = `${mainContainerHeight}px`;
};

import React, { memo } from 'react';
import {
  mapTooltip,
  mapFields,
} from '../../../../screens/MyCash/components/Table/components/headerMapping';
import { getSearchParams, useWidgetParagraphQuery } from '../../helpers';
import DataField from './components/DataField';
import styles from './styles.legacy.css';
import { InstrumentGenericDataProps } from './typings';
import { defaultConfig } from './defaultConfig';

const InstrumentGenericData = ({
  widgetParagraph,
}: InstrumentGenericDataProps) => {
  const searchParams = getSearchParams(widgetParagraph);
  const widgetTitle = searchParams['widgetTitle'] || 'Generic Data';
  const config = searchParams['config'];
  const { instrument, loading, error } =
    useWidgetParagraphQuery(widgetParagraph);
  const isLoading = loading || !!error;
  const parsedConfig = JSON.parse(config || null) || defaultConfig;

  // if not parseable config, return null
  // If no config present, show default config to know how one can integrate it
  if (!parsedConfig || !instrument) {
    return null;
  }

  const mappedData = [];

  Object.entries(parsedConfig)?.map(
    ([label, field]: [string, keyof Instrument]) => {
      if (!instrument?.[field]) return null;

      mappedData.push({
        label,
        field: mapFields(instrument, [field])[field],
        tooltip: mapTooltip(field),
      });
    },
  );

  return (
    <>
      <div className={styles.Wrapper}>
        <p className={styles.Title}>{widgetTitle}</p>
        {mappedData.map(({ label, field, tooltip }) => {
          return (
            <div key={field} className={styles.Row}>
              <span className={styles.Label}>
                {label}
                {tooltip}
              </span>

              <DataField isLoading={isLoading} field={field} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default memo<InstrumentGenericDataProps>(InstrumentGenericData);

/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { ROUTE_TRADING_IDEAS } from '../../constants';
import { GET_TRADING_IDEAS_PAGE, GET_TRADING_IDEAS_PORTFOLIO } from './queries';

export const tradingIdeasApolloConfig: RaschApolloConfig = {
  options: () => {
    return {
      query: GET_TRADING_IDEAS_PORTFOLIO, // gql-service
      additionalQuery: GET_TRADING_IDEAS_PAGE, // cms
      additionalVariables: {
        path: ROUTE_TRADING_IDEAS,
        publication: 'CASH',
      },
      variables: {
        pathname: `/${ROUTE_TRADING_IDEAS}`,
        publication: 'CASH',
      },
    };
  },
};

import React from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import {
  DATE_FORMAT_TIME,
  formatDate,
} from '../../../../../../../shared/helpers/dateTimeElapsed';
import { tealiumTrackEvent } from '../../../../../../../shared/helpers/tealium';
import useInView from '../../../../../../../shared/hooks/useInView';
import Link from '../../../../../../../common/components/Link';
import Icon from '../../../Icon';
import { UBS_TRENDRADAR_PATH } from './constants';
import { GET_TREND_RADAR } from './queries';
import styles from './styles.legacy.css';
import {
  QueryResult,
  QueryWrapperProps,
  TrendProps,
  TrendRadarProps,
} from './typings';

const TENDENCY = {
  '-1': 'short',
  '1': 'long',
};

const TIME_HORIZON = {
  '1': 'kurzfristig',
  '2': 'mittelfristig',
  '3': 'langfristig',
};

const NoTrendRadar = () => (
  <div className={styles.InnerTitle}>Keine Signale zum Titel</div>
);

const Trend = ({ object, mIsin, entry }: TrendProps) => {
  const date = formatDate(object.timeSort * 1000) || '';
  const time = formatDate(object.timeSort * 1000, DATE_FORMAT_TIME) || '';

  const quotePotential = object.breakout?.[0]?.quotePotential;

  const distPercentBreakoutFrom: any =
    (quotePotential &&
      quotePotential?.[quotePotential.length - 1]?.distPercentBreakoutFrom) ||
    null;

  let isPositive = true;

  if (
    distPercentBreakoutFrom &&
    parseFloat((100 * distPercentBreakoutFrom) as any) < 0
  ) {
    isPositive = false;
  }

  return (
    <>
      <div className={styles.InnerTitle}>{object.patternType.name}</div>

      {object?.image && (
        <Link
          target="_blank"
          path={UBS_TRENDRADAR_PATH.replace('[signalId]', `${object.id}`)}
          onClick={(event) => {
            event.preventDefault();
            tealiumTrackEvent({
              type: 'link',
              payload: {
                event_category: 'UBS Trendradar',
                event_action: 'Click signal details',
                event_label: mIsin,
                integration_sponsor: 'UBS',
              },
            });
            window.open(
              entry.target.getElementsByTagName('a')[0].href,
              '_blank',
            );
          }}
        >
          <img
            className={styles.Image}
            src={`${object.image}?width=610&height=317`}
            alt="UBS TrendRadar Bild"
          />
        </Link>
      )}

      <div className={styles.List}>
        <span className={styles.Title}>Tendenz</span>
        <span className={styles.Value}>
          {TENDENCY[object.breakout?.[0]?.direction] || ''}
        </span>
      </div>

      <div className={styles.List}>
        <span className={styles.Title}>Zeithorizont</span>
        <span className={styles.Value}>
          {TIME_HORIZON[object.timeHorizon] || ''}
        </span>
      </div>

      <div className={styles.List}>
        <span className={styles.Title}>Renditeerwartung</span>
        {(distPercentBreakoutFrom && (
          <span
            className={classNames(styles.Value, {
              [styles.Positive]: isPositive,
              [styles.Negative]: !isPositive,
            })}
          >
            {parseFloat((100 * distPercentBreakoutFrom) as any).toFixed(2)}%
          </span>
        )) ||
          null}
      </div>

      <div className={styles.List}>
        <span className={styles.Title}>Zeit</span>
        <span className={styles.Value}>
          {date} {time}
        </span>
      </div>

      <Link
        target="_blank"
        path={UBS_TRENDRADAR_PATH.replace('[signalId]', `${object.id}`)}
        onClick={(event) => {
          event.preventDefault();
          tealiumTrackEvent({
            type: 'link',
            payload: {
              event_category: 'UBS Trendradar',
              event_action: 'Click signal details',
              event_label: mIsin,
              integration_sponsor: 'UBS',
            },
          });
          window.open(entry.target.getElementsByTagName('a')[0].href, '_blank');
        }}
      >
        <p className={styles.DetailLink}>
          <span>Signal Details</span>
          <Icon type="IconArrowRightUpFromSquare" />
        </p>
      </Link>
    </>
  );
};

const QueryWrapper = ({ mIsin, entry }: QueryWrapperProps) => {
  const { data, loading } = useQuery<QueryResult>(GET_TREND_RADAR, {
    variables: {
      mIsin: [mIsin],
      limit: 1,
    },
  });

  if (loading) {
    return null;
  }

  const object = data?.integration?.solvians?.trendRadar?.[0];

  if (!object) {
    return <NoTrendRadar />;
  }

  return <Trend object={object} mIsin={mIsin} entry={entry} />;
};

const TrendRadar = ({ widgetParagraph }: TrendRadarProps) => {
  const path = widgetParagraph?.link?.path;
  const url =
    (path && path.startsWith('/') && new URL(path, global.locationOrigin)) ||
    (path && new URL(path)) ||
    null;
  const mIsin = url?.searchParams?.get('mIsin');
  const { setRef, isInView, entry } = useInView({
    rootMargin: '200px',
    triggerOnce: true,
  });

  if (isInView) {
    tealiumTrackEvent({
      type: 'link',
      payload: {
        event_category: 'UBS Trendradar',
        event_action: 'Impression widget',
        event_label: mIsin,
        integration_sponsor: 'UBS',
        event_non_interaction: 1,
      },
    });
  }

  return (
    <div ref={setRef}>
      <p className={styles.HeaderTitle}>UBS TrendRadar</p>

      {(mIsin && !__TESTING__ && (
        <QueryWrapper mIsin={mIsin} entry={entry} />
      )) || <NoTrendRadar />}
    </div>
  );
};

export default TrendRadar;

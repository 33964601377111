import { RaschApolloConfig } from '../../../../../../../../../shared/decorators/withRaschRouterFactory';
import { GET_PORTFOLIO_BY_KEY } from './queries';

export const portfolioByKeyApolloConfig: RaschApolloConfig = {
  options: ({ params }) => {
    const portfolioKey = params?.portfolioKey;

    return {
      query: GET_PORTFOLIO_BY_KEY,
      variables: {
        portfolioKey,
        publication: 'CASH',
      },
      ssr: false,
      skip: !portfolioKey,
    };
  },
};

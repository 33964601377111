import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CORPORATE_ACTIONS } from './queries';
import {
  CorporateActionsProps,
  QueryResult,
  QueryResultInstrumentData,
} from './typings';
import Tabs from './components/Tabs';
import { ANCHOR_ID, EVENTS, INITIAL_ITEMS_SHOWN } from './constants';
import styles from './styles.legacy.css';
import Icon from '../../../Icon';
import classNames from 'classnames';
import Event from './components/Event';
import { tealiumTrackEvent } from '../../../../../../../shared/helpers/tealium';
import {
  defaultOptions,
  scrollToAnchorElement,
} from '../../../../../../../common/components/SmoothScroll/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS } from '../../../AutoUpdateProvider/queries';
import { setInstrumentKeysAnonymous } from '../../../../../../shared/actions/autoUpdate';
import { cleanEvents } from './helpers';
import { PIANO_CORPORATE_ACTIONS_WIDGET } from '../../../../../../../shared/constants/piano';
import {
  SUBSCRIPTION_TYPE_ANLEGER,
  SUBSCRIPTION_TYPE_BANKING,
  SUBSCRIPTION_TYPE_PROFI,
} from '../../../../constants';
import { ButtonProps } from './components/Tabs/typings';
import { isListingKey } from '../../../../screens/MyCash/components/Portfolio/helpers';

const CorporateActions = ({ widgetParagraph }: CorporateActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState('ALLE');
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const eventCD = Object.keys(EVENTS);
  const today = new Date();
  const toDate = today.toISOString().split('T')[0];
  today.setFullYear(today.getFullYear() - 4);
  const dispatch = useDispatch();
  const fromDate = today.toISOString().split('T')[0];
  const path = widgetParagraph?.link?.path;
  const url =
    (path && path.startsWith('/') && new URL(path, global.locationOrigin)) ||
    (path && new URL(path)) ||
    null;
  const listingKey = url?.searchParams?.get('listingKey').trim();
  const mIsin = url?.searchParams?.get('mIsin');

  const {
    data: instrumentData,
    loading: instrumentDataLoading,
    error: instrumentDataError,
  } = useQuery<QueryResultInstrumentData>(
    GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS,
    {
      variables: { listingKeys: listingKey },
      skip: !isListingKey(listingKey) || !mIsin,
    },
  );

  const instrument = instrumentData?.quoteList?.quoteList?.edges[0]?.node;

  const { data, error, loading } = useQuery<QueryResult>(
    GET_CORPORATE_ACTIONS,
    {
      variables: {
        operationalMic: instrument?.mic,
        localCode: instrument?.mSymb,
        isin: mIsin,
        eventCD,
        toDate,
        fromDate,
      },
      skip: !!(
        !instrumentData ||
        !instrument ||
        !instrument?.mic ||
        instrumentDataLoading ||
        instrumentDataError
      ),
    },
  );

  useEffect(() => {
    dispatch(
      setInstrumentKeysAnonymous([
        {
          isMarketOpen: true,
          listingKey: listingKey,
        },
      ]),
    );
  }, [dispatch, listingKey]);

  const events = structuredClone(
    data?.integration?.edi?.corporateAction?.jsondata,
  );

  const toggleOpen = () => {
    if (isOpen) {
      scrollToAnchorElement(ANCHOR_ID, defaultOptions);
    }
    tealiumTrackEvent({
      type: 'link',
      payload: {
        event_name: 'fullquote_corporate_actions',
        event_category: 'fullquote_page',
        event_action: `corporate_actions_show_${isOpen ? 'less' : 'more'}`,
      },
    });
    setIsOpen(!isOpen);
  };

  const subscriptions = useSelector<ReduxState, string[]>(
    ({ auth }) => auth.subscriptions,
  );
  const hasSubscriptions = useSelector<ReduxState, boolean>(
    ({ auth }) => auth.hasSubscriptions || false,
  );
  const isCrawler = useSelector<ReduxState, boolean>(
    ({ route }) => route.isCrawler || false,
  );
  const hasValidSubscriptions = (): boolean => {
    if (isCrawler) {
      return true;
    }

    if (!hasSubscriptions) {
      return false;
    }

    return [
      SUBSCRIPTION_TYPE_ANLEGER,
      SUBSCRIPTION_TYPE_PROFI,
      SUBSCRIPTION_TYPE_BANKING,
    ].some((abo) => subscriptions.includes(abo));
  };

  const uniqueTabs = () => {
    const unique: ButtonProps | {} = {};
    const tabs = [
      { key: 'ALLE', label: 'ALLE' },
      ...Object.keys(EVENTS).map((key) => ({
        key: key,
        label: EVENTS[key].label,
      })),
    ];
    tabs.forEach((tab) => {
      unique[tab.label] = tab;
    });
    return Object.values(unique) as ButtonProps[];
  };

  if (loading || error || !events) {
    return null;
  }

  return (
    <div className={styles.Wrapper} id={ANCHOR_ID}>
      <p className={styles.Title}>
        CORPORATE ACTIONS
        <Icon
          type={'IconCircleInfo'}
          addClass={styles.InfoIcon}
          onClick={() => setIsInfoOpen(!isInfoOpen)}
        />
      </p>
      <p className={classNames(styles.Info, { [styles.Hide]: !isInfoOpen })}>
        {`Corporate Actions sind Massnahmen von Unternehmen,
        die sich direkt auf die Aktien eines Anlegers auswirken.
        Dazu gehören eine Vielzahl von Aktivitäten wie Kapitalerhöhungen,
        Aktiensplits, Dividendenzahlungen oder Fusionen.
        Diese Massnahmen können den Wert und die Struktur Ihrer Anlagen verändern.
        Es ist wichtig, solche Änderungen zu kennen,
        da sie sich auf Ihre Anlagestrategie und Ihr Portfolio auswirken können.
        Verschaffen Sie sich mit einem Anleger- oder Profi-Abonnement einen
        informativen Überblick über die Corporate Actions der wichtigsten
        Schweizer und internationalen Aktien.`}
      </p>
      <Tabs
        activeTab={activeEvent}
        setActiveTab={setActiveEvent}
        buttons={uniqueTabs()}
      />
      {cleanEvents(events, EVENTS[activeEvent]?.eventCollection)
        .slice(0, isOpen ? 100 : INITIAL_ITEMS_SHOWN)
        .map((event) => (
          <Event
            key={event.eventid}
            event={event}
            hasSubscription={hasValidSubscriptions()}
          />
        ))}
      {events?.length > INITIAL_ITEMS_SHOWN ? (
        <button
          className={classNames(styles.CollapseButton)}
          onClick={toggleOpen}
        >
          <span>{!isOpen ? 'Mehr anzeigen' : 'Weniger anzeigen'}</span>
          <Icon type={!isOpen ? 'IconChevronDown' : 'IconChevronUp'} />
        </button>
      ) : null}
      <div
        className={classNames(
          PIANO_CORPORATE_ACTIONS_WIDGET,
          styles.PianoWidget,
          {
            [styles.Hidden]: hasValidSubscriptions(),
          },
        )}
      ></div>
    </div>
  );
};

export default CorporateActions;

import { useSelector } from 'react-redux';
import autoUpdateStateSelector from '../../../shared/selectors/autoUpdateStateSelector';

export const useAutoUpdateFields: any = (instrument, fields) => {
  const result = {};
  const state = useSelector(
    (state) => autoUpdateStateSelector(state).data[instrument?.instrumentKey],
  );

  for (const field in fields) {
    const curr = fields[field];
    result[curr] = state?.[curr] || instrument?.[curr];
  }

  return result;
};

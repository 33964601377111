import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import {
  DATE_FORMAT_WITH_TIME_FULL,
  formatDate,
} from '../../../../../../../shared/helpers/dateTimeElapsed';
import { tealiumTrackEvent } from '../../../../../../../shared/helpers/tealium';
import { formatPrice } from '../../../Highcharts/helpers';
import Link from '../../../../../../../common/components/Link';
import InputField from '../../../Paragraphs/components/WebformParagraph/components/InputField';
import SVGIcon from '../../../SVGIcon';
import SelectField from './components/SelectField';
import { SVG_ICONS_TYPE_ARROW_UP_AND_DOWN } from '../../../../../../../shared/constants/svgIcons';
import { currencySelectOptions, listingKeysMapping } from './constants';
import { GET_CURRENCY_CALCULATOR_DATA } from './queries';
import styles from './styles.legacy.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [styles.SwissFlag, styles.Flag];

const CurrencyCalculator = () => {
  const [instruments, setInstruments] = useState(null);
  const [currentInstrument, setCurrentInstrument] = useState(null);
  const [useSmallLayout, setUseSmallLayout] = useState(null);
  const [inputInfo, setInputInfo] = useState({
    value: '100',
    type: 'fromInput',
  });
  const [fromCurrency, setFromCurrency] = useState('CHF');
  const [toCurrency, setToCurrency] = useState('EUR');

  const wrapperRef = useRef(null);

  // Get all listingKeys to fetch from the config listingKeysMapping
  let listingKeys = '';
  Object.entries(listingKeysMapping).forEach((item) => {
    Object.values(item[1]).forEach((listingKeyList) => {
      listingKeys += `${listingKeyList},`;
    });
  });
  listingKeys.substring(1);

  const { data, loading, error } = useQuery(GET_CURRENCY_CALCULATOR_DATA, {
    variables: { listingKeys },
    skip: !listingKeys,
  });

  const handleFromSelectFieldChange = ({ currency }) => {
    setFromCurrency(currency);
    tealiumTrackEvent({
      type: 'link',
      payload: {
        event_name: `currency_change_from`,
        event_category: 'currency_calculator',
        event_action: `currency_change_from`,
        from: `${currency}`,
        to: `${toCurrency}`,
      },
    });
  };

  const handleToSelectFieldChange = ({ currency }) => {
    setToCurrency(currency);
    tealiumTrackEvent({
      type: 'link',
      payload: {
        event_name: `currency_change_to`,
        event_category: 'currency_calculator',
        event_action: `currency_change_to`,
        from: `${fromCurrency}`,
        to: `${currency}`,
      },
    });
  };

  const handleInputValue = (type, value) => {
    setInputInfo({ value, type });
  };

  const getCalculatedValue = useCallback(() => {
    if (currentInstrument) {
      const calculatedVal =
        (Number(inputInfo.value) * Number(currentInstrument.lval)) /
        currentInstrument.pricingamt;

      return calculatedVal;
    }
  }, [currentInstrument, inputInfo]);

  const handleFormUpdate = useCallback(() => {
    let currentListingKey = '';

    if (inputInfo.type === 'fromInput') {
      currentListingKey =
        listingKeysMapping[fromCurrency][`${fromCurrency}/${toCurrency}`];
    } else if (inputInfo.type === 'toInput') {
      currentListingKey =
        listingKeysMapping[toCurrency][`${toCurrency}/${fromCurrency}`];
    }

    const currentInstrument = instruments?.find(
      (instrument) => instrument?.node?.instrumentKey === currentListingKey,
    );

    setCurrentInstrument(currentInstrument?.node);

    const isSameCurrency = fromCurrency === toCurrency;

    const inputField = formFields?.current?.find(
      (field: FieldComponentProps): boolean => {
        const identifier =
          (inputInfo.type === 'fromInput' && 'toInputField') ||
          'fromInputField';
        return field.getId() === identifier;
      },
    );

    if (isSameCurrency) {
      inputField.setValue(inputInfo.value);
    } else {
      const calculatedValue = getCalculatedValue();
      inputField.setValue(formatPrice(calculatedValue));
    }
  }, [getCalculatedValue, instruments, fromCurrency, toCurrency, inputInfo]);

  const elWidth = wrapperRef?.current?.getBoundingClientRect?.()?.width || null;
  useEffect(() => {
    //@TODO: try to implement autoupdate on calculator
    // dispatch(
    //   setInstrumentKeysAnonymous([
    //     {
    //       isMarketOpen: true,
    //       listingKey: listingKeys,
    //     },
    //   ]),
    // );

    if (elWidth) {
      setUseSmallLayout(elWidth < 600);
    }

    if (data && fromCurrency && toCurrency) {
      setInstruments(data?.quoteList?.quoteList?.edges);
      handleFormUpdate();
    }
  }, [fromCurrency, toCurrency, handleFormUpdate, data, listingKeys, elWidth]);

  const formFields = useRef<FieldComponentProps[]>([]);
  const registerField = (formField: FieldComponentProps): void => {
    formFields.current.push(formField);
  };

  const handleSwitchIconClick = () => {
    const values = {} as any;

    formFields.current.forEach((formField: FieldComponentProps): void => {
      values[formField.getId()] = formField.getValue();
    });

    const fromSelectField = formFields?.current?.find(
      (field: FieldComponentProps): boolean => {
        return field.getId() === 'fromSelectField';
      },
    );

    const toSelectField = formFields?.current?.find(
      (field: FieldComponentProps): boolean => {
        return field.getId() === 'toSelectField';
      },
    );

    setToCurrency(values.fromSelectField.currency);
    toSelectField.setValue(values.fromSelectField);

    setFromCurrency(values.toSelectField.currency);
    fromSelectField.setValue(values.toSelectField);

    tealiumTrackEvent({
      type: 'link',
      payload: {
        event_name: `switch_currencies`,
        event_category: 'currency_calculator',
        event_action: `switch_currencies`,
        from: `${values.toSelectField.currency}`,
        to: `${values.fromSelectField.currency}`,
      },
    });

    handleFormUpdate();
  };

  const SwitchIcon = () => {
    return (
      <div
        onClick={handleSwitchIconClick}
        onKeyDown={handleSwitchIconClick}
        role="button"
        tabIndex={0}
      >
        <SVGIcon
          className={styles.Icon}
          type={SVG_ICONS_TYPE_ARROW_UP_AND_DOWN}
        />
      </div>
    );
  };

  if (!data || loading || error) {
    return <div className={styles.SkeletonWrapper}></div>;
  }

  return (
    <div ref={wrapperRef} className={styles.Wrapper}>
      <div className={styles.Title}>Währungsrechner</div>
      <div className={styles.ContentWrapper}>
        <form className={styles.FormWrapper} noValidate lang="de-CH">
          <div
            className={classNames(styles.InputRow, {
              [styles.SmallLayout]: !useSmallLayout,
            })}
          >
            <div
              className={classNames(styles.InputFieldWrapper, {
                [styles.SmallLayout]: useSmallLayout,
              })}
            >
              <InputField
                animatedLabel
                fieldName="amount"
                id="fromInputField"
                disabled={false}
                label="Betrag"
                title="fromInputField"
                handleChange={handleInputValue.bind(this, 'fromInput')}
                maxlength={50}
                value={'100'}
                register={registerField.bind(this)}
                withErrorIcon={false}
                type="string"
                initialValue={'100'}
                errorMessage={'Bitte geben Sie einen gültigen Betrag ein'}
                required
                getId={() => 'fromInputField'}
                inputmode="decimal"
                validate={() => null}
                getValue={() => null}
              />
            </div>

            <div className={styles.SelectFieldWrapper}>
              <SelectField
                id="fromSelectField"
                name="fromSelectField"
                register={registerField.bind(this)}
                label="Von"
                initialSelect={currencySelectOptions[0]}
                onChange={handleFromSelectFieldChange.bind(this)}
                hasError={false}
                options={currencySelectOptions}
              />
            </div>
          </div>
          <div
            className={classNames(styles.SwitchButtons, {
              [styles.SmallLayout]: useSmallLayout,
              [styles.Hidden]: !useSmallLayout,
            })}
          >
            <SwitchIcon />
          </div>
          <div
            className={classNames(styles.InputRow, {
              [styles.SmallLayout]: !useSmallLayout,
            })}
          >
            <div
              className={classNames(styles.InputFieldWrapper, {
                [styles.SmallLayout]: useSmallLayout,
              })}
            >
              <InputField
                animatedLabel
                id="toInputField"
                fieldName="toInputField"
                handleChange={handleInputValue.bind(this, 'toInput')}
                disabled={false}
                label="Betrag"
                title="toInputField"
                maxlength={50}
                value={''}
                register={registerField.bind(this)}
                withErrorIcon={false}
                type="string"
                initialValue={'0'}
                errorMessage={'Bitte geben Sie einen gültigen Betrag ein'}
                getId={() => 'toInputField'}
                inputmode="decimal"
                validate={() => null}
                getValue={() => null}
              />
            </div>
            <div className={styles.SelectFieldWrapper}>
              <SelectField
                id="toSelectField"
                name="toSelectField"
                register={registerField.bind(this)}
                label="Nach"
                initialSelect={currencySelectOptions[1]}
                onChange={handleToSelectFieldChange.bind(this)}
                hasError={false}
                options={currencySelectOptions}
              />
            </div>
          </div>
        </form>
        <div
          className={classNames(styles.SwitchButtons, {
            [styles.SmallLayout]: useSmallLayout,
            [styles.Hidden]: useSmallLayout,
          })}
        >
          <SwitchIcon />
        </div>
      </div>
      {currentInstrument && (
        <div
          className={classNames(styles.CurrencyInfoWrapper, {
            [styles.SmallLayout]: useSmallLayout,
          })}
        >
          <Link path={currentInstrument?.fullquoteUri} className={styles.Link}>
            {fromCurrency}/{toCurrency}
          </Link>
          <span>{`Kurs: ${parseFloat(currentInstrument.lval).toFixed(
            4,
          )} vom ${formatDate(
            currentInstrument.lvalDatetime,
            DATE_FORMAT_WITH_TIME_FULL,
          )}`}</span>
        </div>
      )}
    </div>
  );
};

export default CurrencyCalculator;

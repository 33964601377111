import React from 'react';
import classNames from 'classnames';
import {
  formatPercentage,
  formatPrice,
} from '../../../../../../components/Highcharts/helpers';
import styles from './styles.legacy.css';
import { PortfolioSummaryProps } from './typings';
import TradingTeaser from '../../../../../../components/Widgets/components/TradingTeaser';

const PortfolioSummary = ({ portfolio, depotPrice }: PortfolioSummaryProps) => {
  if (!portfolio) {
    return null;
  }
  const calculatedFields = portfolio.calculatedFields;
  const isPerformancePositive =
    Math.sign(parseFloat(calculatedFields?.totalAccountPercent)) > 0;

  return (
    <div className={styles.SummaryGridWrapper}>
      <div className={styles.TradingTeaserWrapper}>
        <TradingTeaser depotPrice={depotPrice} />
      </div>
      <div className={styles.SummaryWrapper}>
        <p className={styles.Title}>Zusammenfassung</p>
        <div className={styles.ContentRow}>
          <p>
            Einstand{' '}
            {portfolio.currency && <span>{`(${portfolio.currency})`}</span>}
          </p>
          <p>{formatPrice(calculatedFields.totalPaidPrice)}</p>
        </div>
        <div className={styles.ContentRow}>
          <p>Aktuell</p>
          <p>{formatPrice(calculatedFields.totalActualPrice, 'Value')}</p>
        </div>
        <div className={styles.ContentRow}>
          <p>+/-</p>
          <p
            className={classNames({
              [styles.Positive]: isPerformancePositive,
              [styles.Negative]: !isPerformancePositive,
            })}
          >
            {!isPerformancePositive ?? '-'}
            {formatPrice(calculatedFields.totalAccountPlusMinus, 'Value')}
          </p>
        </div>
        <div className={classNames(styles.ContentRow)}>
          <p>+/-%</p>
          <p
            className={classNames({
              [styles.Positive]: isPerformancePositive,
              [styles.Negative]: !isPerformancePositive,
            })}
          >
            {formatPercentage(calculatedFields?.totalAccountPercent)}%
          </p>
        </div>
        <div className={styles.ContentRow}>
          <p>Flüssige Mittel</p>
          <p>{formatPrice(portfolio?.calculatedFields?.cash, 'Value')}</p>
        </div>
        <div className={styles.ContentRow}>
          <p>Gesamtwert</p>
          <p>
            {formatPrice(
              portfolio?.calculatedFields?.cashAccountTotal,
              'Value',
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PortfolioSummary;

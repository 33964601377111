export const ALERTS_OVERVIEW_TABLE = 'alerts-overview-table';
export const ITEMS_PER_PAGE = 15;

export const TABLE_HEADERS = {
  [ALERTS_OVERVIEW_TABLE]: [
    'alert-checkbox-delete',
    'mName',
    'mValor',
    'value',
    'type',
    'expiration',
    'brokenTime',
    'receiveType',
  ],
};

export const EQUITY = 'Equity';
export const INDEX = 'Index';
export const CRYPTO_CURRENCY = 'CryptoCurrency';
export const BOND = 'Bond';
export const DERIVATE = 'Derivative';
export const DIVERSE = 'Diverse';
export const FUND = 'Fund';
export const WIKIFOLIO = 'Wikifolio';
export const NEW_EMISSION = 'NewEmission';
export const NEWS = 'News';

export const SEARCH_RESULT_CONFIG = [
  EQUITY,
  INDEX,
  CRYPTO_CURRENCY,
  FUND,
  BOND,
  DERIVATE,
  DIVERSE,
];

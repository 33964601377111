import { RaschApolloConfig } from '../../../../../../../shared/decorators/withRaschRouterFactory';
import { GET_CASH_ITEMS } from './queries';

export const cashItemsApolloConfig: RaschApolloConfig = {
  options: ({ params }) => {
    const { portfolioKey } = params;
    return {
      query: GET_CASH_ITEMS,
      variables: {
        portfolioKey,
      },
    };
  },
};

// CONFIG
export const TEASER_LEAD_LENGTH = 200;
export const TEASER_LEAD_SUFFIX_TEXT = '...';
export const TEASER_TITLE_LENGTH = 120;
export const TEASER_SMALL_TEXT_TITLE_LENGTH = 5;

// CTA
export const TEASER_CTA_CHANNEL = 'zum Channel';

// IMAGE
export const TEASER_IMAGE_IDENTIFIER = 'teaser-image';

// TEASER HERO
export const TEASER_HERO_DEFAULT_IDENTIFIER = 'teaser-hero-default';

// TEASER L
export const TEASER_L_DEFAULT_IDENTIFIER = 'teaser-l-default';

// TEASER M
export const TEASER_M_DEFAULT_IDENTIFIER = 'teaser-m-default';

// OTHER TEASERS
export const TEASER_BOOKMARK_IDENTIFIER = 'teaser-bookmark';
export const TEASER_UBS_TRENDRADAR = 'teaser-ubs-trendradar';

export const TEASER_LAYOUT_PORTFOLIO_NEWS = 'teaser-portfolio-news';
export const TEASER_LAYOUT_EDITORIAL_PICKS = 'teaser-editorial-picks';

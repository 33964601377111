import React from 'react';
import compose from 'recompose/compose';
import shouldUpdate from 'recompose/shouldUpdate';
import classNames from 'classnames';
import teaserFactory from '../../../../../../../../../common/components/Teaser/factory';
import { withTeaserTrackingHandler } from '../../../../../../../../../common/components/Teaser/helpers';
import { getAllAuthors } from '../../../../../../../../../shared/helpers/authors';
import {
  TIME_ELAPSED_FORMAT_LONG,
  getFormattedElapsedDate,
} from '../../../../../../../../../shared/helpers/dateTimeElapsed';
import {
  getBadgeByProps,
  getShortTitleElementByProps,
  getSponsorImageByProps,
} from '../../../../shared/helpers';
import Icon from '../../../../../Icon';
import sponsorImageFactory, {
  SPONSOR_IMAGE_POSITION_AUTO,
} from '../../../../../SponsorImage';
import {
  STYLE_1X1_640,
  STYLE_8X3_1130,
} from '../../../../../../../../../shared/constants/images';
import { TEASER_IMAGE_IDENTIFIER } from '../../../../../Teaser/constants';
import styles from './styles.legacy.css';

const SponsorImage = sponsorImageFactory({
  position: SPONSOR_IMAGE_POSITION_AUTO,
});

const getInnerContentByProps = ({
  authors,
  cta,
  createDate,
  changeDate,
  publicationDate,
}) => {
  const hasAuthors =
    authors?.edges && Array.isArray(authors.edges) && authors.edges.length > 0;

  if (cta) {
    return (
      <div className={styles.DedicatedPageLink}>
        {cta}
        <span className={styles.ArrowWrap}>
          <Icon type="IconArrowRight" />
        </span>
      </div>
    );
  }

  if (!hasAuthors) {
    return null;
  }

  return (
    <div className={styles.AuthorWrapper}>
      {getAllAuthors({ authors: authors.edges })}{' '}
      {getFormattedElapsedDate({
        createDate: publicationDate || createDate,
        changeDate: changeDate,
        format: TIME_ELAPSED_FORMAT_LONG,
        maxHours: 11,
        prefix: 'am',
      })}
    </div>
  );
};

const getStylesByProps = ({ sponsor }) => ({
  Wrapper: classNames(styles.Wrapper, styles.Link, {
    [styles.WrapperWithSponsor]: sponsor,
  }),
  ContentWrapper: classNames(styles.TeaserText, styles.InnerWrapper),
  Title: styles.TeaserTitleWrapper,
  TitleInner: styles.TeaserTitle,
  BottomLine: styles.AuthorWrapper,
  ImageWrapper: styles.ImageWrapper,
  Image: classNames(TEASER_IMAGE_IDENTIFIER, styles.Image),
});

const TeaserHeroImageTile = teaserFactory({
  badge: getBadgeByProps(styles.Badge),
  trackingTeaserHandler: withTeaserTrackingHandler,
  teaserImageStyles: {
    style_320: STYLE_1X1_640,
    style_540: STYLE_1X1_640,
    style_760: STYLE_8X3_1130,
    style_960: STYLE_8X3_1130,
  },
  shortTitleElement: getShortTitleElementByProps(styles.ShortTitleWrapper),
  sponsorImage: getSponsorImageByProps(
    styles.SponsorImageWrapper,
    SponsorImage,
  ),
  innerContent: getInnerContentByProps,
  isShortTitleHidden: false,
  styles: getStylesByProps,
});

const withUpdatePolicy = shouldUpdate(
  (props, nextProps) => props.title !== nextProps.title,
);

export default compose(withUpdatePolicy)(TeaserHeroImageTile);

import { RaschApolloConfig } from '../../../../../../../shared/decorators/withRaschRouterFactory';
import { GET_PORTFOLIOS, GET_PORTFOLIOS_CALCULATED } from './queries';

export const portfoliosApolloConfig: RaschApolloConfig = {
  options: ({ params }) => {
    const isAuthenticated =
      (params?.isAuthenticated === 'true' && true) || false;

    return {
      query: GET_PORTFOLIOS,
      variables: {
        publication: 'CASH',
      },
      ssr: false,
      skip: !isAuthenticated,
    };
  },
};

export const portfoliosCalculatedScreenApolloConfig: RaschApolloConfig = {
  options: ({ params }) => {
    const isAuthenticated =
      (params?.isAuthenticated === 'true' && true) || false;

    return {
      query: GET_PORTFOLIOS_CALCULATED,
      variables: {
        publication: 'CASH',
      },
      ssr: false,
      skip: !isAuthenticated,
    };
  },
};

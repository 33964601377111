/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { GET_DICTIONARY_LISTING } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ params: { char = 'A', category = 'Lexikon' } }) => {
    return {
      query: GET_DICTIONARY_LISTING,
      variables: {
        char: char.toUpperCase(),
        publication: 'CASH',
        category,
      },
    };
  },
};

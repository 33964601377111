export const WIDGET_QUOTELIST = 'type/quote-list';
export const WIDGET_ORDERBOOK = 'type/quote-orderbook';
export const WIDGET_AWP_ANALYSER = 'type/awp-analyser';
export const WIDGET_INSTRUMENT_ACTIONS = 'type/quote-actions';
export const WIDGET_SAVINGS_PLAN_CALCULATOR = 'type/savings-plan-calculator';
export const WIDGET_CURRENCY_CALCULATOR = 'type/currency-calculator';
export const WIDGET_CHART_TIMESERIES = 'type/quote-timeseries';
export const WIDGET_FULLQUOTE_HEADER = 'type/quote-fullquote-header';
export const WIDGET_INSTRUMENT_LATEST_DATA = 'type/quote-latest-data';
export const WIDGET_INSTRUMENT_COMPARISON_LAST_YEAR =
  'type/quote-comparison-last-year';
export const WIDGET_INSTRUMENT_LATEST_DATA_IN_ARTICLE =
  'type/quote-latest-data-in-article';
export const WIDGET_INSTRUMENT_SUMMARY = 'type/quote-summary';
export const WIDGET_INSTRUMENT_MARKET_PLACES = 'type/quote-market-places';
export const WIDGET_INSTRUMENT_GENERIC_DATA = 'type/quote-generic-data';
export const WIDGET_MULTIPLE_INSTRUMENTS_GENERIC_DATA =
  'type/multiple-generic-data';
export const WIDGET_TOP_FLOP = 'type/top-flop';
export const WIDGET_VOLUME_TURNOVER = 'type/volume-turnover';
export const WIDGET_SECTOR = 'type/sector';
export const WIDGET_TREND_RADAR = 'type/trend-radar';
export const WIDGET_CORPORATE_ACTIONS = 'type/corporate-actions';
export const WIDGET_TREND_RADAR_OVERVIEW = 'type/trend-radar-overview';
export const WIDGET_WIKIFOLIO = 'type/wikifolio';

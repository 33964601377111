export const AUTOPLAY_START = true;
export const AUTOPLAY_STOP = false;
export const DIRECTION_FORWARDS = 'direction/forwards';
export const DIRECTION_BACKWARDS = 'direction/backwards';
export const DIRECTION_ON_INDICATOR_CLICK = 'direction/on-indicator-click';
export const DEFAULT_PRELOAD_COUNT = 2;
export const SLIDE_ALIGNMENT_CENTER = 'slider/slide-alignment-center';
export const SLIDE_ALIGNMENT_LEFT = 'slider/slide-alignment-left';
export const DEFAULT_SLIDE_INTERVAL = 50;
export const SLIDER_NAVIGATION_POSITION_BOTTOM_IMAGE =
  'slider-navigation-position/bottom-image';
export const SWIPE_INDICATOR_POSITION_BOTTOM_IMAGE_CENTER =
  'swipe-indicator-position/bottom-image-center';
export const SWIPE_INDICATOR_POSITION_BOTTOM =
  'swipe-indicator-position/bottom';

/* istanbul ignore file */

import classNames from 'classnames';
import ministageNewsletter from '../../../../../../../../../../../common/components/Paragraphs/components/MinistageParagraph/components/MinistageNewsletter/factory';
import MailchimpSubscribeForm from '../MailChimpSubscribeForm';
import { STYLE_TEASER_1_1 } from '../../../../../../../../../../../shared/constants/images';
import grid from '../../../../../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import {
  MinistageNewsletterFactoryOptionsStyles,
  MinistageNewsletterProps,
} from '../../../../../../../../../../../common/components/Paragraphs/components/MinistageParagraph/components/MinistageNewsletter/typings';

const getStyleByProps = ({
  addClass,
  ministageNewsletter,
  useFullwidthBackground = false,
}: MinistageNewsletterProps): MinistageNewsletterFactoryOptionsStyles => {
  const relativeOriginPath =
    ministageNewsletter?.image?.relativeOriginPath || '';

  return {
    Background: styles.Background,
    Wrapper: classNames({
      [styles.Wrapper]: !addClass,
      [styles.FullWidthBackgroundWrapper]: useFullwidthBackground,
      [addClass]: !!addClass,
    }),
    Container: styles.Container,
    InnerWrapper: styles.InnerWrapper,
    Row: grid.Row,
    ContentWrapper: relativeOriginPath
      ? classNames(
          grid.ColMd24,
          useFullwidthBackground ? grid.ColXl18 : grid.ColXl15,
        )
      : grid.ColXs24,
    HeaderText: styles.HeaderText,
    HeaderWrapper: styles.HeaderWrapper,
    LeadText: styles.LeadText,
    HiddenTeaserImage: styles.HiddenTeaserImage,
    PictureWrapper: styles.TeaserImageWrapper,
    Picture: styles.TeaserImage,
  };
};

const MinistageNewsletter = ministageNewsletter({
  MailchimpSubscribeForm,
  imageStyles: {
    style_320: STYLE_TEASER_1_1,
  },
  styles: getStyleByProps,
});

export default MinistageNewsletter;

import { memo } from 'react';
import dropdownFactory from '../../../../../common/components/Dropdown/factory';
import ButtonWithLoading from '../ButtonWithLoading';
import Icon from '../Icon';
import styles from './styles.legacy.css';

const Dropdown = dropdownFactory({
  Icon,
  ButtonWithLoading,
  styles: {
    BodyClass: styles.BodyClass,
    OverlayWrapper: styles.OverlayWrapper,
    Open: styles.Open,
    Wrapper: styles.Wrapper,
    CloseWrapper: styles.CloseWrapper,
    OptionsWrapper: styles.OptionsWrapper,
    Right: styles.Right,
    Disabled: styles.Disabled,
  },
});

export default memo(Dropdown);

import React, { ReactElement, memo } from 'react';
import { compose } from 'redux';
import lodashSlice from 'lodash/slice';
import { WithHeaderProps } from '../../../../../shared/decorators/@types/withHeader';
import createComponentSwitch from '../../../../shared/decorators/componentSwitch';
import withHelmet from '../../../../shared/decorators/withHelmet';
import ArticlePageDefault from './components/Default';
import ArticlePageSwipeable from './components/Swipeable';
import { COMMENT_STATUS_HIDDEN } from '../../../../../shared/constants/comments';
import { RESTRICTION_STATUS_PAID } from '../../../../../shared/constants/content';
import { ROOT_SCHEMA_TYPE_NEWS_ARTICLE } from '../../../../../shared/constants/structuredData';
import { PARAGRAPHS_FOR_FREE } from '../Article/constants';
import { ARTICLE_PAGE_DEFAULT, ARTICLE_PAGE_SWIPEABLE } from './constants';
import { ArticleProps } from './components/Default/typings';

export type ArticlePropsInner = ArticleProps &
  WithHeaderProps & {
    setHeaderData: (props: HeaderState) => void;
    resetHeaderData: () => void;
    locationPathname: string;
    screenReady: boolean;
    hasSubscriptions: boolean;
    isCrawler: boolean;
    viewportLabel?: string;
  };

const Switch = createComponentSwitch({
  [ARTICLE_PAGE_DEFAULT]: ArticlePageDefault,
  [ARTICLE_PAGE_SWIPEABLE]: ArticlePageSwipeable,
});

const ArticlePage = (props): ReactElement => {
  return <Switch component={props.component} {...props} />;
};

const getRootSchemaRestricted = ({ article, hasSubscriptions, isCrawler }) => {
  let shouldHideContent =
    !hasSubscriptions &&
    [RESTRICTION_STATUS_PAID].includes(article?.restrictionStatus);

  if (isCrawler) {
    shouldHideContent = false;
  }

  const body =
    (shouldHideContent && lodashSlice(article.body, 0, PARAGRAPHS_FOR_FREE)) ||
    article.body;

  const jsonLd: {
    isAccessibleForFree?: boolean;
    hasPart?: Object[];
    isPartOf?: Object;
  } = {
    isAccessibleForFree: true,
    isPartOf: {
      '@type': ['CreativeWork', 'Product'],
      name: 'Cash',
      productID: 'cash.ch:showcase',
    },
  };

  if ([RESTRICTION_STATUS_PAID].includes(article?.restrictionStatus)) {
    jsonLd.isAccessibleForFree = false;
    jsonLd.hasPart = [];
    for (let index = PARAGRAPHS_FOR_FREE + 1; index < body.length; index++) {
      jsonLd.hasPart.push({
        '@type': 'WebPageElement',
        isAccessibleForFree: 'False',
        cssSelector: `.restricted-section-${index}`,
      });
    }

    if (!shouldHideContent) {
      if (article.issue?.nid) {
        jsonLd.hasPart.push({
          '@type': 'WebPageElement',
          isAccessibleForFree: 'False',
          cssSelector: '.restricted-article-magazin-issue',
        });
      }

      if (
        Array.isArray(article?.topics?.edges) &&
        article.topics.edges.length > 0
      ) {
        jsonLd.hasPart.push({
          '@type': 'WebPageElement',
          isAccessibleForFree: 'False',
          cssSelector: '.restricted-article-alerts',
        });
      }
      if (
        article?.commentStatus &&
        article.commentStatus !== COMMENT_STATUS_HIDDEN
      ) {
        jsonLd.hasPart.push({
          '@type': 'WebPageElement',
          isAccessibleForFree: 'False',
          cssSelector: '.restricted-article-comments',
        });
      }
    }
  }

  return jsonLd;
};

export default compose(
  withHelmet({
    getNode: (mapProps: ArticlePropsInner) => mapProps.article,
    rootSchemaType: ROOT_SCHEMA_TYPE_NEWS_ARTICLE,
    getRootSchemaRestricted,
  }),
)(memo(ArticlePage));

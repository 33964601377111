/* istanbul ignore file */
import { GET_PREV_NEXT_CHANNEL_ENTITIES } from './queries';

export const apolloConfig = {
  options: ({ location }) => {
    const pathname = location.pathname.slice(1, location.pathname.length);
    return {
      query: GET_PREV_NEXT_CHANNEL_ENTITIES,
      variables: {
        path: pathname,
        publication: 'CASH',
        limit: 1,
      },
      ssr: false,
    };
  },
};

export const MY_CASH_ALERTS = 'type/my-cash-alerts';
export const MY_CASH_PORTFOLIOS = 'type/my-cash-portfolios';
export const MY_CASH_PORTFOLIOS_ORDER = 'type/my-cash-portfolios/custom-order';
export const MY_CASH_PORTFOLIO = 'type/my-cash-portfolio';
export const MY_CASH_PORTFOLIO_TRANSACTIONS =
  'type/my-cash-portfolio/transactions';
export const MY_CASH_PORTFOLIO_INSTRUMENT = 'type/my-cash-portfolio/instrument';
export const MY_CASH_PORTFOLIO_CUSTOM_VIEW =
  'type/my-cash-portfolio/custom-view';
export const MY_CASH_PORTFOLIO_CASH_ITEMS = 'type/my-cash-portfolio/cash-items';
export const MY_CASH_WATCHLIST = 'type/my-cash-watchlist';
export const MY_CASH_WATCHLIST_CUSTOM_VIEW =
  'type/my-cash-watchlist/custom-view';

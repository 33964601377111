import { useEffect, useState } from 'react';
import locationStateSelector from '../selectors/locationStateSelector';
import { useSelector } from 'react-redux';

/**
 * Only changes loading once. Stays true after pages was loaded once.
 * Otherwise pages rerender after navigating away.
 */

export const useInitialLoading = () => {
  const [initialLoading, setInitialLoading] = useState(undefined);
  const loading = useSelector((state) => locationStateSelector(state).loading);

  useEffect(() => {
    if (initialLoading === undefined) {
      setInitialLoading(loading);
      return;
    }
    if (initialLoading === true) {
      setInitialLoading(loading);
    }
  }, [loading, initialLoading]);

  return initialLoading;
};

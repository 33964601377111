import React, { ComponentType } from 'react';
import classNames from 'classnames';
import useRaschRouterLocation from '../../../../../../../shared/hooks/useRaschRouterLocation';
import Button from '../../../ButtonWithLoading';
import Dropdown from '../../../Dropdown';
import AddToPortfolioButton from './components/AddToPortfolioButton';
import { Auth0 } from '../../../../../../../common/components/Auth0Provider';
import { WatchlistSelection } from '../../../../screens/MyCash/components/Watchlist/components/AddInstrumentToWatchlist';
import { ExtendedItem } from '../Table';
import { alertsFormOverlay } from '../../../AlertsForm';
import { displayInfoToast } from '../../../Toast';
import { PORTFOLIO_TRADE_FORM_TYPE_BUY } from '../../../PortfolioTradeForm/constants';
import {
  AUTHORIZATION_DEFAULT_ERROR_MESSAGE,
  AUTHORIZATION_ERROR_ID,
} from '../../../Toast/constants';
import styles from './styles.legacy.css';
import { useStableNavigate } from '../../../../../../../shared/hooks/useStableNavigateContext';

type IntegrationButtonsWrapperProps = {
  fullquote: ExtendedItem;
  type: string;
};

const IntegrationButtonsWrapper: ComponentType<
  IntegrationButtonsWrapperProps
> = ({ fullquote, type }) => {
  const navigate = useStableNavigate();
  const location = useRaschRouterLocation();
  const isCryptoCurrency = type === 'cryptoCurrency';
  const token = Auth0.getAccessToken();
  const handleButtonClick = (actionType) => {
    if (actionType === 'alerts') {
      alertsFormOverlay({
        fullquoteUri: fullquote?.link,
        navigate,
        location,
      });
    }
  };

  return (
    <div
      className={classNames(styles.Wrapper, {
        [styles.IsCryptoCurrency]: isCryptoCurrency,
      })}
    >
      <AddToPortfolioButton
        listingId={fullquote?.listingId}
        instrumentType={fullquote?.type}
        type={PORTFOLIO_TRADE_FORM_TYPE_BUY}
        origin="search"
      >
        Portfolio
      </AddToPortfolioButton>
      {(!token && (
        <Button
          size="small"
          aria-label="Watchlist"
          variant="secondary"
          iconTypeLeft="IconEye"
          onClick={() => {
            displayInfoToast(
              AUTHORIZATION_DEFAULT_ERROR_MESSAGE,
              AUTHORIZATION_ERROR_ID,
              {
                text: 'Hier einloggen oder registrieren.',
                onClick: () => Auth0.login(),
              },
            );
          }}
        >
          Watchlist
        </Button>
      )) || (
        <Dropdown
          iconTypeLeft="IconEye"
          size="small"
          label="Watchlist"
          variant="secondary"
          align="right"
        >
          {({ setIsOpen }) => {
            return (
              <>
                <WatchlistSelection
                  instrumentKey={fullquote?.listingId}
                  instrumentName={fullquote?.name}
                  closeOverlay={() => setIsOpen(false)}
                  withInstrument={true}
                  origin="search"
                ></WatchlistSelection>
              </>
            );
          }}
        </Dropdown>
      )}
      <Button
        size="small"
        aria-label="upload"
        variant="secondary"
        iconTypeLeft="IconBell"
        onClick={() => handleButtonClick('alerts')}
      >
        Alert
      </Button>
    </div>
  );
};

export default IntegrationButtonsWrapper;

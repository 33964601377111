export const tradeableMarketAlternative = {
  4: 'SWX',
  6: 'EBR',
  12: 'CPH',
  13: 'FRA',
  14: 'DUS',
  15: 'MUC',
  16: 'STU',
  17: 'HAM',
  18: 'BEB',
  19: 'HAJ',
  25: 'EPA',
  36: 'LON',
  38: 'EAM',
  40: 'HEL',
  44: 'ETR',
  46: 'MCI',
  47: 'LUX',
  48: 'OSL',
  50: 'VIE',
  51: 'ELI',
  53: 'STO',
  54: 'MCE',
  61: 'TOR',
  63: 'CVE',
  65: 'NYX',
  66: 'NMK',
  67: 'NMS',
  69: 'NAR',
  76: 'MFD',
  84: 'SCL',
  100: 'JNB',
  106: 'TYO',
  111: 'ASX',
  116: 'NZE',
  145: 'ISE',
  260: 'OPC',
  272: 'SEA',
  337: 'OBI',
  341: 'MAS',
  349: 'SWU',
  351: 'SWE',
  361: 'LSS',
  365: 'OTN',
  380: 'SWL',
  393: 'SWF',
  399: 'ENX',
  473: 'OPP',
  477: 'PLU',
  490: 'DWS',
  512: 'SXU',
  513: 'SXG',
  521: 'EUW',
  613: 'GAM',
  685: 'AIB',
  822: 'VEU',
  830: 'ITF',
  832: 'FTC',
  874: 'OME',
  880: 'QMH',
  881: 'BFZ',
  1058: 'STN',
  1310: 'SNO',
  2609: 'INV',
  2976: 'CAY',
};

import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import termsOverviewFactory from '../../../../../common/components/TermsOverview/factory';
import locationStateSelector from '../../../../shared/selectors/locationStateSelector';
import AlphabeticNavigation from '../AlphabeticNavigation';
import Breadcrumbs from '../Breadcrumbs';
import styles from './styles.legacy.css';
import { TermsOverviewProps as TermsOverviewCommonProps } from '../../../../../common/components/TermsOverview/typings';

type TermsOverviewProps = TermsOverviewCommonProps & {
  breadcrumbItems: ActiveMenuTrailItemConnection;
  theme?: string;
};

const BreadcrumbsByProps = ({
  activeLetter,
  breadcrumbItems,
  title,
}: TermsOverviewProps): ReactElement<typeof Breadcrumbs> => {
  const isHybridApp = useSelector(
    (state) => locationStateSelector(state).isHybridApp,
  );
  if (isHybridApp) {
    return null;
  }
  return (
    <Breadcrumbs
      items={breadcrumbItems}
      title={`${title}: ${activeLetter}`}
      staticData
      pageUrl={'/'}
    />
  );
};

const getAlphabeticNavigationByProps = (
  props: TermsOverviewProps,
): ReactElement<typeof AlphabeticNavigation> => (
  <AlphabeticNavigation enableOverlay={true} {...props} />
);

const TermsOverview = termsOverviewFactory({
  styles: {
    Container: null,
    Title: styles.Title,
    TitleWrapper: styles.TitleWrapper,
    Wrapper: null,
  },
  Breadcrumbs: BreadcrumbsByProps,
  AlphabeticNavigation: getAlphabeticNavigationByProps,
});

export default TermsOverview;

import React, { memo } from 'react';
import Skeleton from '../../../../../../components/Skeleton';
import styles from './styles.legacy.css';
import { DataFieldProps } from './typings';

const DataField = ({ isLoading, fields, onlyOne = false }: DataFieldProps) => {
  if (fields?.length === 0) return null;

  if (isLoading) {
    return (
      <span className={styles.ValueWrapper}>
        <Skeleton show={isLoading} addClass={styles.Skeleton} />
        {!onlyOne && (
          <Skeleton show={isLoading} addClass={styles.SkeletonSmall} />
        )}
      </span>
    );
  }

  const isEmpty = (obj) => (obj ? Object.keys(obj)?.length === 0 : true);

  return (
    <span className={styles.ValueWrapper}>
      <span className={styles.Value}>{fields[0]}</span>
      {!isEmpty(fields[1]) && (
        <span className={styles.DateOrTime}>{fields[1]}</span>
      )}
    </span>
  );
};

export default memo<DataFieldProps>(DataField);

import { useEffect, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import {
  MapFieldsResult,
  mapFields,
} from '../../screens/MyCash/components/Table/components/headerMapping';
import { GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS } from '../AutoUpdateProvider/queries';
import { isListingKey } from '../../screens/MyCash/components/Portfolio/helpers';

export const getListingKey = (widgetParagraph: WidgetParagraph) => {
  const searchParams = getSearchParams(widgetParagraph);

  // captures the listing key no matter where
  const keyByUrl =
    getFullPath(widgetParagraph?.link?.path).match(
      /(\d{1,}-\d{1,}-\d{1,})/,
    )?.[0] || '';

  return searchParams['listingId'] || searchParams['listingKey'] || keyByUrl;
};

export const getSearchParams = (widgetParagraph: WidgetParagraph) => {
  const urlString = getFullPath(widgetParagraph?.link?.path);
  const isUrl = !!urlString && new RegExp('https?://.*').test(urlString);
  if (!isUrl || !widgetParagraph) return {};

  const config = urlString.match(/config={.*?}/)?.[0] || '';
  const url = getSanitizedURL(urlString);
  const baseUrl = `${global?.origin}${global?.location?.pathname}`;

  if (!url?.search) return {};
  const search = sanitizeSearchString(url.search);
  const quotesCount = search.split('"').length - 1;

  // we must ensure to always have an even number of quotes, as `handleLinkParams` only then works properly. Also, since URLSearchParams
  // doesn't support full URLs as a param like with `hrefBuy`or `hrefSell` we first extract these with `handleLinkParams`
  // => as e.g. in the case of `&hrefBuy="https://cash.ch/buy?isin=CH4"` with URLSearchParams that would give us `https://cash.ch/buy` as key
  if (quotesCount % 2 !== 0) return {};
  const { foundParams, remainingSearch } = handleLinkParams(search);

  const remainingUrl = getSanitizedURL(baseUrl + remainingSearch);
  const remainingSearchString = sanitizeSearchString(remainingUrl.search);
  const remainingParams = new URLSearchParams(remainingSearchString);

  const mappedParams = Object.fromEntries(remainingParams);
  const configParam = config
    ? { config: config.replace('config=', '') || '' }
    : {};

  return { ...mappedParams, ...foundParams, ...configParam };
};

const getFullPath = (path: string) =>
  path.startsWith('/')
    ? `${global?.origin}${global?.location?.pathname}${path}`
    : path;

const getSanitizedURL = (urlString = '') => {
  // decodeURIComponent doesn't like brackets/quotes in urls ¯\_(ツ)_/¯
  // => since these are present in our config param, we remove them here and bring them back later
  const url = urlString ? urlString.replace(/&config={.*?}/, '') : '';

  try {
    return new URL(decodeURIComponent(url));
  } catch (e) {
    return urlString;
  }
};

const sanitizeSearchString = (searchString: any = '') =>
  searchString
    ?.toString()
    .replace(/%5B/g, '[')
    .replace(/%5D/g, ']')
    .replace(/%7B/g, '{')
    .replace(/%7D/g, '}')
    .replace(/%22/g, '"')
    .replace(/%20/g, ' ');

const handleLinkParams = (
  searchString = '',
): { remainingSearch: string; foundParams: Record<string, string> } => {
  let remainingSearch = searchString;
  const foundParams = {};

  const linkParams = remainingSearch.matchAll(/(\w+)="([^"]+)"/g);
  for (const match of linkParams) {
    const [, key, value] = /(\w+)="(.+)"/.exec(match[0]);
    if (key && key !== 'config') {
      remainingSearch = remainingSearch.replace(match[0], '');
      foundParams[key] = value;
    }
  }

  return {
    foundParams,
    remainingSearch,
  };
};

type UseWidgetParagraphQueryResult = {
  fields: MapFieldsResult;
  instrument: Instrument;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useWidgetParagraphQuery = (
  widgetParagraph: WidgetParagraph,
  fields?: (keyof Instrument)[],
  fieldTypes?: Record<keyof Instrument | string, string>,
): UseWidgetParagraphQueryResult => {
  const [instrument, setInstrument] = useState<Instrument | null>(null);
  const listingKey = getListingKey(widgetParagraph);

  const { data, loading, error } = useQuery(
    GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS,
    {
      variables: { listingKeys: listingKey },
      skip: !isListingKey(listingKey),
    },
  );

  useEffect(() => {
    if (data) {
      setInstrument(data?.quoteList?.quoteList?.edges?.[0]?.node as Instrument);
    }
  }, [data]);

  return {
    fields: fields ? mapFields(instrument, fields, fieldTypes) : null,
    instrument,
    loading,
    error,
  };
};

export const scriptToAppend = (
  src: string,
  elementRef: React.MutableRefObject<any>,
) => {
  const script = document.createElement('script');
  script.src = src;
  if (elementRef.current) {
    elementRef.current.appendChild(script);
  } else {
    document.body.appendChild(script);
  }
  return script;
};

export const adjustBlickWidgetHight = () => {
  const id = '__BLICK_IFRAME_ID__';
  // this is needed to make sure correct size is set
  // for different loading times
  setInterval(() => {
    adjustHTMLHight(id);
  }, 500);
  addEventListener('resize', () => {
    adjustHTMLHight(id);
  });
};

export const adjustHTMLHight = (id: string) => {
  if (global.self === global.top) {
    // don't do this if not in iframe
    return;
  }
  const wrapper = document.getElementById(id);
  const height = wrapper?.clientHeight;
  const html = document.querySelector('html');
  if (height && html) {
    html.style.height = `${height}px`;
  }
};

import { toast } from 'react-toastify';
import { useLazyQuery } from '@apollo/client';
import { displayErrorToast } from '../../../../components/Toast';
import { alertsScreenApolloConfig } from './apolloConfig';
import { DEFAULT_ERROR_MESSAGE } from '../../../../components/Toast/constants';
import { CUSTOM_VIEW, LIMIT_VIEW } from '../Table/constants';
import { GET_ALERTS_LIST_EXTERNAL } from './queries';

export const handleDeleteAlert = ({
  alertKey,
  deleteAlertMutation,
  startLoadingCallback,
  stopLoadingCallback,
}) => {
  startLoadingCallback();

  const { variables: alertScreenVariables } = alertsScreenApolloConfig.options({
    location,
  });

  deleteAlertMutation({
    variables: {
      alertKey: `${alertKey}`,
    },
    refetchQueries: [
      {
        query: GET_ALERTS_LIST_EXTERNAL,
        variables: alertScreenVariables,
      },
    ],
  })
    .then(async ({ data }): Promise<void> => {
      if (
        data?.deleteAlert &&
        data?.deleteAlert?.error &&
        data?.deleteAlert?.error !== null
      ) {
        // eslint-disable-next-line no-console
        console.error(
          'there was an error sending the query',
          data?.deleteAlert.error,
          data?.deleteAlert.status,
        );
        if (!toast.isActive('alert-delete-error')) {
          displayErrorToast(
            DEFAULT_ERROR_MESSAGE,
            'portfolio-delete-transaction-error',
          );
        }
        stopLoadingCallback();
        return;
      }

      if (toast.isActive('alert-delete-error')) {
        toast.dismiss('alert-delete-error');
      }

      if (global.refetchAlertListGQL) {
        global.refetchAlertListGQL().then(() => {
          stopLoadingCallback();
        });
      }
    })
    .catch((): void => {
      displayErrorToast(DEFAULT_ERROR_MESSAGE, 'alert-delete-error');
      stopLoadingCallback();
    });
};

export const hasAlertsColumn = (tableView, currentCustomView) => {
  const alertColumns = ['alertsLowerLimit', 'alertsUpperLimit'];

  return (
    tableView === LIMIT_VIEW ||
    (tableView === CUSTOM_VIEW && currentCustomView?.length > 0
      ? alertColumns.some((column) => currentCustomView?.includes(column))
      : false)
  );
};

export const GetAlerts = ({ hasAlerts, currentKey, apolloConfig }) => {
  const { query: alertsQuery, ...alertsOptions } = apolloConfig.options({
    location,
    params: {
      ...currentKey,
    },
  });

  const [getAlerts, { data, loading, called, refetch }] = useLazyQuery(
    alertsQuery,
    alertsOptions,
  );

  const hasKey = !!Object.values(currentKey)[0];

  if (!called && !loading && hasAlerts && hasKey) {
    getAlerts();
  }

  return { data, loading, refetch };
};

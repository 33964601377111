import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStableNavigate } from '../../../../../shared/hooks/useStableNavigateContext';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { tealiumTrackEvent } from '../../../../../shared/helpers/tealium';
import { log } from '../../../../../shared/helpers/utils';
import authStateSelector from '../../../../../shared/selectors/authStateSelector';
import autoUpdateStateSelector from '../../../../../shared/selectors/autoUpdateStateSelector';
import locationStateSelector from '../../../../shared/selectors/locationStateSelector';
import { AutoUpdateState } from '../../../../shared/reducers/autoUpdate';
import {
  setInstrumentData,
  setInstrumentKeysAnonymous,
  toggleAutoUpdate,
} from '../../../../shared/actions/autoUpdate';
import useRefetchQueryInterval from '../../../../shared/hooks/useRefetchQueryInterval';
import Icon from '../Icon';
import modal from '../Modal';
import { ROUTE_BOERSEN_ABOS, SUBSCRIPTION_TYPE_BASIC } from '../../constants';
import { GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS } from './queries';
import styles from './styles.legacy.css';

export const ensureAutoUpdateData = (data: QuoteListEdge[]) => {
  if (!Array.isArray(data)) {
    return {};
  }

  const instrumentKeysWithData =
    data?.map(({ node }: { node: Instrument }) => {
      return { [node.instrumentKey]: { ...node } };
    }) || [];

  const instrumentData = instrumentKeysWithData.reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});

  return instrumentData || {};
};

export const getFilteredInstrumentKeys = (
  instrumentData: AutoUpdateState['instrumentKeysAnonymous'],
  isMarketOpen = true,
) => {
  const list = Object.values(instrumentData)
    .filter(
      (item) =>
        item.isMarketOpen === isMarketOpen && item.constituents !== true,
    )
    .map((item) => item.listingKey);
  // remove duplicates
  return list.filter((item, index) => list.indexOf(item) === index);
};

const AutoUpdateProvider = () => {
  const manuallyDisabledAutoupdate = useRef(false);
  const isShowNotification = useRef(true);
  const dispatch = useDispatch();
  const navigate = useStableNavigate();
  const instrumentDataAnonymous = useSelector(
    (state) => autoUpdateStateSelector(state).instrumentKeysAnonymous,
  );
  const isAutoUpdateEnabled = useSelector(
    (state) => autoUpdateStateSelector(state).isAutoUpdateEnabled,
  );
  const instrumentDataCustom = useSelector(
    (state) => autoUpdateStateSelector(state).instrumentKeysCustom,
  );
  const subscriptions = useSelector(
    (state) => authStateSelector(state).subscriptions,
  );
  const isRealTimeUser = useSelector(
    (state) => authStateSelector(state).realtime,
  );
  const routerLoading = useSelector(
    (state) => locationStateSelector(state).loading,
  );
  const inactiveTimeoutIdRef = useRef(null);

  const instrumentKeysAnonymous = getFilteredInstrumentKeys(
    instrumentDataAnonymous,
  );
  const instrumentKeysCustom = getFilteredInstrumentKeys(instrumentDataCustom);

  const instrumentKeysCustomWithoutDuplicates = useMemo(
    () =>
      instrumentKeysCustom.filter(
        (item) => !instrumentKeysAnonymous.includes(item),
      ),
    [instrumentKeysCustom, instrumentKeysAnonymous],
  );

  const { loading, data } = useQuery<{
    quoteList: QuoteList;
  }>(GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS, {
    variables: {
      listingKeys:
        Array.isArray(instrumentKeysCustomWithoutDuplicates) &&
        instrumentKeysCustomWithoutDuplicates?.length > 0
          ? instrumentKeysCustomWithoutDuplicates.join(',')
          : '',
    },
    fetchPolicy: 'no-cache',
    pollInterval: isRealTimeUser ? 10_000 : 30_000,
    notifyOnNetworkStatusChange: true,
    ssr: false,
    skip:
      !instrumentKeysCustomWithoutDuplicates?.length || !isAutoUpdateEnabled,
  });
  let instrumentKeysAnonymousConstituents = null;
  if (instrumentKeysAnonymous) {
    // filter out constituents objects
    instrumentKeysAnonymousConstituents = Object.values(instrumentDataAnonymous)
      .map((item: any) => {
        if (item?.constituents && item?.listingKey) {
          return item?.listingKey;
        }
        return null;
      })
      .filter((item: any) => item !== null);
  }

  const { loading: sharedLoading, data: sharedData } = useQuery<{
    quoteList: QuoteList;
  }>(GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS, {
    variables: {
      listingKeys:
        Array.isArray(instrumentKeysAnonymous) &&
        instrumentKeysAnonymous.length > 0
          ? instrumentKeysAnonymous.join(',')
          : '',
    },
    fetchPolicy: 'no-cache',
    pollInterval: isRealTimeUser ? 10_000 : 30_000,
    notifyOnNetworkStatusChange: true,
    ssr: false,
    skip: !instrumentKeysAnonymous?.length || !isAutoUpdateEnabled,
  });

  const { loading: constituentsLoading, data: constituentsData } = useQuery<{
    quoteList: QuoteList;
  }>(GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS, {
    variables: {
      listingKeys:
        Array.isArray(instrumentKeysAnonymousConstituents) &&
        instrumentKeysAnonymousConstituents?.length > 0
          ? instrumentKeysAnonymousConstituents.join(',')
          : '',
      constituents: true,
    },
    fetchPolicy: 'no-cache',
    pollInterval: isRealTimeUser ? 10_000 : 30_000,
    notifyOnNetworkStatusChange: true,
    ssr: false,
    skip: !instrumentKeysAnonymousConstituents?.length || !isAutoUpdateEnabled,
  });

  const instrumentKeysConstituentsData =
    constituentsData?.quoteList?.quoteList?.edges
      .filter((node: any) => node?.isMarketOpen === false)
      .map((node: any) => node?.instrumentKey) || [];

  const closedInstruments = {
    ...instrumentDataAnonymous,
    ...instrumentDataCustom,
    ...instrumentKeysConstituentsData,
  };
  const instrumentKeysClosedMarket = getFilteredInstrumentKeys(
    closedInstruments,
    false,
  );

  const {
    loading: closedMarketLoading,
    data: closedMarketData,
    refetch: refetchClosedMarketsQuery,
  } = useQuery<{
    quoteList: QuoteList;
  }>(GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS, {
    variables: {
      listingKeys:
        Array.isArray(instrumentKeysClosedMarket) &&
        instrumentKeysClosedMarket?.length > 0
          ? instrumentKeysClosedMarket.join(',')
          : '',
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    ssr: false,
    skip: !instrumentKeysClosedMarket?.length || !isAutoUpdateEnabled,
  });

  let refetchClosedMarkets = null;
  // we only want to refetch closed markets if auto update is enabled
  // and we wanna show the toast callback all the time if auto update is enabled even if the market is open
  if (isAutoUpdateEnabled) {
    refetchClosedMarkets = refetchClosedMarketsQuery;
  }

  if (
    subscriptions &&
    subscriptions?.some(
      (subscription) => subscription !== SUBSCRIPTION_TYPE_BASIC,
    )
  ) {
    isShowNotification.current = false;
  }

  if (global.self !== global.top) {
    // don't pop up notifications in iframe i.e. external widgets for blick
    dispatch(toggleAutoUpdate(false));
  }

  useRefetchQueryInterval({
    refetch: refetchClosedMarkets,
    callback: () => {
      const modalEl = document.querySelector('.' + styles.ModalWrapper);
      if (isShowNotification.current && !modalEl) {
        setTimeout(() => dispatch(toggleAutoUpdate(false)), 0);
        modal({
          type: 'drawer',
          hasStickyHeader: false,
          hasStickyFooter: false,
          hideDefaultButtons: false,
          isCloseVisible: false,
          customUi: ({ close }) => {
            return (
              <div className={styles.ModalWrapper}>
                <div
                  role={'button'}
                  tabIndex={0}
                  onKeyDown={close}
                  onClick={close}
                  className={classNames(styles.CloseIconWrapper, {
                    [styles.DrawerBottom]: global.innerWidth < 760,
                  })}
                >
                  <Icon type={'IconXMark'} />
                </div>
                <div className={styles.Title}>Kurs-Daten in Echtzeit</div>
                <div className={styles.Description}>
                  Verfolgen Sie Kurs-Daten live und bleiben Sie stets
                  informiert. Wünschen Sie eine 15-minütige Verlängerung der
                  Echtzeit-Aktualisierung? Als Abonnent erhalten Sie durchgehend
                  Live-Updates.
                </div>
              </div>
            );
          },
          buttons: [
            {
              variant: 'secondary',
              children: '15 min verlängern',
              onClick: () => {
                tealiumTrackEvent({
                  type: 'link',
                  payload: {
                    event_name: `auto_update_click`,
                    event_category: 'auto_update',
                    event_action: `stay_active`,
                  },
                });
                log('AutoUpdateProvider', `auto update enabled`, 'green');
                dispatch(toggleAutoUpdate(true));
              },
            },
            {
              variant: 'primary',
              children: 'Zu den Abos',
              onClick: () => {
                tealiumTrackEvent({
                  type: 'link',
                  payload: {
                    event_name: `auto_update_click`,
                    event_category: 'auto_update',
                    event_action: `stay_active`,
                  },
                });
                navigate('/' + ROUTE_BOERSEN_ABOS);
              },
            },
          ],
        });
      }
    },
  });
  const closedMarketIsOpenKeys =
    closedMarketData?.quoteList?.quoteList?.edges?.length > 0 &&
    closedMarketData.quoteList.quoteList.edges.filter(
      ({ node }: { node: Instrument }) => {
        return node.isMarketOpen;
      },
    );

  useEffect(() => {
    if (
      routerLoading &&
      !isAutoUpdateEnabled &&
      manuallyDisabledAutoupdate.current
    ) {
      log(
        'AutoUpdateProvider',
        `auto update re-enabled on route change`,
        'green',
      );
      dispatch(toggleAutoUpdate(true));
    }
  }, [routerLoading, dispatch, isAutoUpdateEnabled]);
  // set auto update data in redux store
  useEffect(() => {
    if (!loading || !sharedLoading || !constituentsLoading) {
      const quoteList = [] as QuoteListEdge[];

      if (data?.quoteList && data?.quoteList?.quoteList?.count > 0) {
        quoteList.push(...data.quoteList.quoteList.edges);
      }

      if (
        sharedData?.quoteList &&
        sharedData?.quoteList?.quoteList?.count > 0
      ) {
        quoteList.push(...sharedData.quoteList.quoteList.edges);
      }

      if (
        constituentsData?.quoteList &&
        constituentsData?.quoteList?.quoteList?.count > 0
      ) {
        quoteList.push(...constituentsData.quoteList.quoteList.edges);
      }

      if (closedMarketIsOpenKeys && closedMarketIsOpenKeys?.length > 0) {
        quoteList.push(...closedMarketIsOpenKeys);
      }

      dispatch(setInstrumentData(ensureAutoUpdateData(quoteList)));
    }
  }, [
    data?.quoteList,
    sharedData?.quoteList,
    constituentsData?.quoteList,
    loading,
    sharedLoading,
    constituentsLoading,
    closedMarketIsOpenKeys,
    dispatch,
  ]);

  useEffect(() => {
    if (
      !closedMarketLoading &&
      closedMarketIsOpenKeys &&
      closedMarketIsOpenKeys?.length > 0
    ) {
      dispatch(
        setInstrumentKeysAnonymous(
          closedMarketIsOpenKeys &&
            closedMarketIsOpenKeys.map(({ node }: Record<string, any>) => ({
              listingKey: node?.instrumentKey,
              isMarketOpen: node?.isMarketOpen,
            })),
        ),
      );
    }
  }, [closedMarketLoading, closedMarketIsOpenKeys, dispatch]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      log(
        'AutoUpdateProvider',
        `tab active/visible state: ${!document.hidden}`,
        'white',
      );

      if (document.hidden) {
        log(
          'AutoUpdateProvider',
          `auto update will be disabled in 10 seconds`,
          'orange',
        );
        inactiveTimeoutIdRef.current = setTimeout(() => {
          dispatch(toggleAutoUpdate(false));
          log('AutoUpdateProvider', `auto update disabled`, 'red');
        }, 10_000);
      } else {
        clearTimeout(inactiveTimeoutIdRef.current);
        dispatch(toggleAutoUpdate(true));
        log('AutoUpdateProvider', `auto update enabled`, 'green');
      }
    };

    if (!manuallyDisabledAutoupdate.current) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isAutoUpdateEnabled, dispatch]);

  return null;
};

export default AutoUpdateProvider;

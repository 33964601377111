/* istanbul ignore file */

import { compose } from 'redux';
import {
  STRUCTURED_META_DATA,
  softwareApplicationSchema,
} from '../helpers/metaData';
import { WithHelmet } from '../../../shared/decorators/@types/withHelmetFactory';
import withHelmetFactory from '../../../shared/decorators/withHelmetFactory';
import Helmet from '../../screens/App/components/Helmet';
import { PUBLICATION_CASH_SEO_TITLE as PUBLISHER } from '../../../shared/constants/publications';
import cashLogo from '../../screens/App/assets/graphics/logo-cash.png';

export type {
  WithHelmetProps,
  WithHelmet,
  WithHelmetNodeProps,
} from '../../../shared/decorators/@types/withHelmetFactory';

const withHelmet = withHelmetFactory({
  Helmet,
  getPublisher: () => PUBLISHER,
  getPublisherLogo: () => `${global.locationOrigin}${cashLogo}`,
});

const WithHelmetComponent = (props: WithHelmet) =>
  compose(
    withHelmet({
      ...props,
      structuredDefaultData: STRUCTURED_META_DATA,
      androidAppSchema: softwareApplicationSchema('ANDROID'),
      iOSAppSchema: softwareApplicationSchema('IOS'),
    }),
  );

export default WithHelmetComponent;

import React from 'react';
import DefaultBoxFactory from '../../../../../../../../../common/components/Paragraphs/components/InfoBoxParagraph/components/DefaultBox/factory';
import ParagraphsRenderer from '../../../ParagraphsRenderer';
import { INFO_BOX_TYPE } from '../../../../../../../../../common/components/Paragraphs/components/InfoBoxParagraph/constants';
import styles from './styles.legacy.css';

const getAppParagraphsRenderer = ({ infoBoxParagraph }) => {
  if (!infoBoxParagraph?.infoBox?.body) {
    return null;
  }
  return (
    <ParagraphsRenderer
      pageBody={infoBoxParagraph.infoBox.body}
      hasContainer={false}
      origin={INFO_BOX_TYPE}
    />
  );
};

const WarningBoxParagraph = DefaultBoxFactory({
  paragraphsRenderer: getAppParagraphsRenderer,
  styles: {
    Wrapper: styles.Wrapper,
    InnerWrapper: '',
    ParagraphWrapper: '',
  },
});

export default WarningBoxParagraph;

/* istanbul ignore file */
import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { GLOBAL_SEARCH_SORT_BY_RELEVANCE } from '../../../../../shared/constants/globalSearch';
import { PAGE_SIZE } from './constants';
import {
  GET_CMS_SEARCH_PAGE_CATEGORY,
  GET_SEARCH_PAGE_CATEGORY,
} from './queries';

const searchCategoryMapping = {
  news: 'News',
  aktien: 'Equity',
  derivate: 'Derivative',
  diverse: 'Diverse',
  currency: 'Currency',
  etf: 'ETF',
  fonds: 'Fund',
  indizes: 'Index',
  bonds: 'Bond',
  kryptowaehrungen: 'CryptoCurrency',
  wikifolio: 'WikiFolio',
  neuemissionen: 'NewEmission',
};

export const searchCategoryApolloConfig: RaschApolloConfig = {
  options: ({ location, params }) => {
    const { searchQuery = '', searchCategory = '' } = params;
    const page = location?.query?.page || 1;
    const category = searchCategory && searchCategoryMapping[searchCategory];
    const sortOrder = location?.query?.sort || GLOBAL_SEARCH_SORT_BY_RELEVANCE;

    return {
      query: GET_SEARCH_PAGE_CATEGORY,
      additionalQuery: GET_CMS_SEARCH_PAGE_CATEGORY,
      additionalVariables: {
        sort: sortOrder,
        query: searchQuery,
        limit: PAGE_SIZE,
        offset: Math.abs(page - 1) * PAGE_SIZE,
      },
      variables: {
        query: searchQuery,
        category: category,
        limit: PAGE_SIZE,
        offset: Math.abs(page - 1) * PAGE_SIZE,
      },
    };
  },
};

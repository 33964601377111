import React, { memo, useEffect } from 'react';
import { headerMapping } from '../../../../screens/MyCash/components/Table/components/headerMapping';
import { getSearchParams } from '../../helpers';
import DataField from './components/DataField';
import styles from './styles.legacy.css';
import { MultipleInstrumentsGenericDataProps } from './typings';
import { isListingKeyList } from '../../../../screens/MyCash/components/Portfolio/helpers';
import { GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS } from '../../../AutoUpdateProvider/queries';
import { useQuery } from '@apollo/client';
import { MULTIPLE_INSTRUMENTS_GENERIC_DATA } from './constants';
import ButtonWithLoading from '../../../ButtonWithLoading';
import Link from '../../../../../../../common/components/Link';
import { useDispatch } from 'react-redux';
import { setInstrumentKeysAnonymous } from '../../../../../../shared/actions/autoUpdate';

const MultipleInstrumentsGenericData = ({
  widgetParagraph,
}: MultipleInstrumentsGenericDataProps) => {
  const dispatch = useDispatch();
  const searchParams = getSearchParams(widgetParagraph);
  const widgetTitle = searchParams['widgetTitle'] || 'Generic Data';
  const config = searchParams['config'];
  const partnerLogo = searchParams['partnerLogo'];
  const listingKeys = searchParams['listingKeys'];
  const isTrendingCoins = widgetTitle === 'Trending Coins';

  const parsedConfig = JSON.parse(config || '{}');

  const configFields = Object.values(parsedConfig) as (keyof Instrument)[];

  const { data, loading, error } = useQuery<any>(
    GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS,
    {
      variables: { listingKeys },
      skip: !isListingKeyList(listingKeys),
    },
  );

  const instruments = data?.quoteList?.quoteList?.edges?.map(
    (edge) => edge.node,
  ) as Instrument[];

  useEffect(() => {
    if (data) {
      dispatch(
        setInstrumentKeysAnonymous(
          listingKeys.split(',').map((key) => ({
            listingKey: key,
            isMarketOpen: true,
          })),
        ),
      );
    }
  }, [dispatch, listingKeys, data]);

  if (!listingKeys || !config || Object.keys(parsedConfig).length === 0) {
    return null;
  }

  const isLoading = loading || !!error;

  return (
    <>
      <div className={styles.Wrapper}>
        <p className={styles.Title}>{widgetTitle}</p>
        <div className={styles.TableWrapper}>
          <table>
            <thead>
              <tr>
                {Object.keys(parsedConfig).map((field, colIdx) => (
                  <th key={colIdx}>{field}</th>
                ))}
                {isTrendingCoins && <th>&nbsp;</th>}
              </tr>
            </thead>
            <tbody>
              {isLoading &&
                listingKeys.split(',').map((_, rowIdx) => (
                  <tr key={rowIdx}>
                    {Object.keys(parsedConfig).map((_, colIdx) => (
                      <td className={styles.Skeleton} key={colIdx}>
                        <span></span>
                      </td>
                    ))}
                    {isTrendingCoins && <td>&nbsp;</td>}
                  </tr>
                ))}
              {instruments?.map((instrument, rowIdx) => {
                return (
                  <tr key={rowIdx}>
                    {configFields.map((field, colIdx) => (
                      <td key={colIdx}>
                        <DataField
                          isLoading={isLoading}
                          field={headerMapping[field].formatter({
                            instrument,
                            value: instrument[field],
                            origin: MULTIPLE_INSTRUMENTS_GENERIC_DATA,
                          })}
                        />
                      </td>
                    ))}
                    {isTrendingCoins && (
                      <td>
                        <Link path="https://bitpanda.pxf.io/c/4904486/1977336/15871">
                          <ButtonWithLoading
                            loading={isLoading}
                            role="link"
                            size="small"
                            addClass={styles.PartnerButton}
                          >
                            Trade
                          </ButtonWithLoading>
                        </Link>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {isTrendingCoins && (
          <div className={styles.Footer}>
            <p>presented by</p>
            <Link path="https://bitpanda.pxf.io/c/4904486/1977336/15871">
              <img src={partnerLogo} alt="logo" />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default memo<MultipleInstrumentsGenericDataProps>(
  MultipleInstrumentsGenericData,
);

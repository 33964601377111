import React from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { breadcrumbItems, enrichBody } from '../../../FullquotePage/helpers';
import locationStateSelector from '../../../../../../shared/selectors/locationStateSelector';
import useRaschRouterLocation from '../../../../../../../shared/hooks/useRaschRouterLocation';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import TeaserGrid from '../../../../components/TeaserGrid';
import { items } from '../../items';
import { fullquotePageApolloConfig } from '../../apolloConfig';
import {
  GRID_LAYOUT_FULLQUOTE_INTERACTIVE_CHART,
  GRID_LAYOUT_FULLQUOTE_INTERACTIVE_CHART_HYBRID,
} from '../../../../components/TeaserGrid/gridConfigs/constants';
import styles from './styles.legacy.css';
import { FullquotePageProps } from '../../typings';

const ChartIntegration = () => {
  const location = useRaschRouterLocation();
  const isHybridApp = useSelector(
    (state) => locationStateSelector(state).isHybridApp,
  );

  const match = matchPath(
    `/:pageType/:valorName/:market/:currency/chart`,
    location.pathname,
  );
  const params = match.params;
  const { valorName, currency, market } = params;

  const { query, ...options } = fullquotePageApolloConfig.options({
    location,
    params,
    props: { isHybridApp } as FullquotePageProps,
  });

  const { data } = useQuery(query, options);

  const { getFullquotePage } = data || {};
  const fullQuoteType = location.pathname.split(`/${valorName}`)?.[0];
  const itemCopy = JSON.parse(JSON.stringify(items));
  const gridLayout =
    (isHybridApp && GRID_LAYOUT_FULLQUOTE_INTERACTIVE_CHART_HYBRID) ||
    GRID_LAYOUT_FULLQUOTE_INTERACTIVE_CHART;
  const gridItems: any =
    Array.isArray(items[gridLayout]) &&
    itemCopy[gridLayout].map((item: any) => {
      item = enrichBody({
        body: item,
        data: {
          ...getFullquotePage,
          valorName,
          market,
          currency,
          pageType: fullQuoteType.slice(1),
        },
      });
      return item;
    });

  const interactivBreadCrumbs = (): Omit<
    ActiveMenuTrailItemConnection,
    'pageInfo'
  > => {
    const fullquoteItems = breadcrumbItems(getFullquotePage);
    fullquoteItems.count += 1;
    fullquoteItems.totalCount += 1;
    const len = fullquoteItems.edges.length;
    fullquoteItems.edges[len - 1].node.link =
      global?.location?.pathname?.replace(/\/chart.*?/, '');
    fullquoteItems.edges.push({
      node: { id: 'chart', link: null, label: 'Chart' },
    });
    return fullquoteItems;
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.PullOutWrapper}>
        <div className={styles.Breadcrumbs}>
          <Breadcrumbs
            pageUrl={location.pathname}
            items={interactivBreadCrumbs()}
          />
        </div>
        <TeaserGrid items={gridItems} layout={gridLayout} />
      </div>
    </div>
  );
};

export default ChartIntegration;

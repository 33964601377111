import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import autoUpdateStateSelector from '../../../../../../../shared/selectors/autoUpdateStateSelector';
import { setInstrumentKeysAnonymous } from '../../../../../../shared/actions/autoUpdate';
import useRaschRouterLocation from '../../../../../../../shared/hooks/useRaschRouterLocation';
import InstrumentActions from '../InstrumentActions';
import CopyToClipboard from './components/CopyToClipboard';
import Timer from './components/timer';
import { headerMapping } from '../../../../screens/MyCash/components/Table/components/headerMapping';
import { GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS } from '../../../AutoUpdateProvider/queries';
import styles from './styles.legacy.css';
import { FullquoteHeaderProps, QueryResult } from './typings';
import { isListingKey } from '../../../../screens/MyCash/components/Portfolio/helpers';

const FullquoteHeader = ({ widgetParagraph }: FullquoteHeaderProps) => {
  const path = widgetParagraph?.link?.path;
  const url =
    (path && path.startsWith('/') && new URL(path, global.locationOrigin)) ||
    (path && new URL(path)) ||
    null;
  const listingKey = url?.searchParams?.get('listingKey').trim();
  const mIsin = url?.searchParams?.get('mIsin');
  const mName = url?.searchParams?.get('mName');
  const subtitle = url?.searchParams?.get('subtitle');
  const dispatch = useDispatch();
  const instrument = useSelector((state) => autoUpdateStateSelector(state))
    ?.data?.[listingKey];
  const location = useRaschRouterLocation();
  const isNewEmission = location?.pathname?.includes('/neuemissionen');

  useEffect(() => {
    dispatch(
      setInstrumentKeysAnonymous([
        {
          isMarketOpen: true,
          listingKey: listingKey,
        },
      ]),
    );
  }, [dispatch, listingKey]);

  const { data, loading, error } = useQuery<QueryResult>(
    GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS,
    {
      variables: { listingKeys: listingKey },
      skip: !isListingKey(listingKey) || !mIsin,
    },
  );

  const node = instrument || data?.quoteList?.quoteList?.edges[0].node;

  if (error || loading || !node) {
    return <div className={styles.Skeleton}></div>;
  }
  const name = node.scGrouped === 'DER' ? mName : node.mName;
  const cur =
    node.scGrouped !== 'IND' && node.pricingQuotationId !== 4 ? node.mCur : '';

  return (
    <div className={styles.Wrapper}>
      <div>
        <div className={styles.TitleWrapper}>
          <h1 className={styles.Title}>{name}</h1>
          {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
        </div>
        {!isNewEmission && (
          <div className={styles.PriceWrapper}>
            <p className={styles.Price}>
              {headerMapping['lval'].formatter({
                value: node.lval,
                instrument: node,
              })}
              {node.pricingQuotationId === 4 ? <span>%</span> : null}
              <span className={styles.Currency}>{cur}</span>
            </p>

            <div className={styles.DeltaWrapper}>
              <p className={styles.DeltaPercentage}>
                {headerMapping['iNetVperprVPr'].formatter({
                  value: node.iNetVperprVPr,
                  instrument: node,
                })}
              </p>
              <p className={styles.Delta}>
                {headerMapping['iNetVperprV'].formatter({
                  value: node.iNetVperprV,
                  instrument: node,
                })}{' '}
                <span
                  className={
                    node.iNetVperprV?.includes('-')
                      ? styles.Negative
                      : styles.Positive
                  }
                ></span>
              </p>
            </div>
          </div>
        )}
        <div className={styles.InfoWrapper}>
          {(node.mSymb && (
            <p className={styles.Info}>
              Symbol <CopyToClipboard value={node.mSymb} />
            </p>
          )) ||
            null}
          <p className={styles.Info}>
            Valor <CopyToClipboard value={listingKey.split('-')[0]} />
          </p>
          {(mIsin && (
            <p className={styles.Info}>
              ISIN <CopyToClipboard value={mIsin} />
            </p>
          )) ||
            null}
        </div>
        <Timer bcOpen={node.bcOpen} bcClose={node.bcClose} />
      </div>
      {!isNewEmission && (
        <div className={styles.ButtonWrapper}>
          <InstrumentActions widgetParagraph={widgetParagraph} />
        </div>
      )}
    </div>
  );
};

export default FullquoteHeader;

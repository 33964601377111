import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { enrichOverviewBodyWithADs } from '../../../../../shared/helpers/ads';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import { isWarning } from '../LandingPage/helpers';
import { breadcrumbItems, enrichBody, replaceValues } from './helpers';
import locationStateSelector from '../../../../shared/selectors/locationStateSelector';
import withParams from '../../../../../shared/decorators/withParams';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import withHelmet from '../../../../shared/decorators/withHelmet';
import { setStatusCode } from '../../../../../shared/actions/ssr';
import { useInitialLoading } from '../../../../../shared/hooks/useInitialLoading';
import { useStableNavigate } from '../../../../../shared/hooks/useStableNavigateContext';
import EsiContext from '../../../../../common/components/EsiRenderer/context';
import Redirect from '../../../../../common/components/Redirect';
import Breadcrumbs from '../../../../screens/App/components/Breadcrumbs';
import AppNexus from '../../components/AppNexus';
import Paragraphs from '../../components/Paragraphs';
import TeaserGrid from '../../components/TeaserGrid';
import StatusPage from '../StatusPage';
import CreateValorButton from './components/CreateValorButton';
import { useSSRContext } from '../../../../../common/components/SSRContext';
import { items } from './items';
import { TOP_AD_1 } from '../../../../../shared/constants/adZone';
import { ROOT_SCHEMA_TYPE_WEBSITE } from '../../../../../shared/constants/structuredData';
import {
  getAdSlotNameByEntryIndex,
  getAdSlotNameByEntryIndexOnError,
} from '../../components/AppNexus/constants';
import { FULLQUOTE_PAGE_TYPE } from './constants';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { FullquotePageProps } from './typings';

const FullquotePage = ({
  data,
  market,
  currency,
  pageType,
  valorName,
  location,
  error,
}: FullquotePageProps) => {
  const { isSSR } = useSSRContext();
  const dispatch = useDispatch();
  const loading = useInitialLoading();
  const isHybridApp = useSelector(
    (state) => locationStateSelector(state).isHybridApp,
  );
  const [esiContextObject] = useState({ timeout: 32000 }); // https://reactjs.org/docs/context.html#caveats
  const navigate = useStableNavigate();

  useEffect(() => {
    if (location.hash === '#chart__comparision') {
      navigate(`${location.pathname}/chart`);
    }
  });

  const { getFullquotePage } = data;
  const routeByPathObject = (data?.environment?.routeByPathSubPage?.object ||
    data?.environment?.routeByPath?.object ||
    null) as LandingPage;

  if (getFullquotePage?.redirectUri) {
    if (isSSR) {
      dispatch(setStatusCode(301, `/${getFullquotePage.redirectUri}`));
      return null;
    } else {
      global.history.replaceState({}, '', `/${getFullquotePage.redirectUri}`);
      return <Redirect to={`/${getFullquotePage.redirectUri}`} />;
    }
  }

  const errorStatusCode =
    error?.networkError?.statusCode ||
    error?.graphQLErrors?.[0]?.extensions?.code;

  if (
    !getFullquotePage ||
    !routeByPathObject ||
    !valorName ||
    !market ||
    !currency ||
    !location.href
  ) {
    if (loading) {
      return null;
    }

    if (
      ((!errorStatusCode || errorStatusCode < 500) && !routeByPathObject) ||
      errorStatusCode === 404
    ) {
      return <StatusPage statusCode={404} />;
    }
  }

  if (__SERVER__ && errorStatusCode >= 500) {
    dispatch(setStatusCode(errorStatusCode));
  }

  const fullQuoteType = location.href.split(`/${valorName}`)?.[0];
  const widgetParams = `${fullQuoteType}/${valorName}/${market}/${currency}`;

  // All fullquote subtypes have their own entityqueue in the CMS. The `/derivate/simulator` page is a special case, because we only render a fullquote header widget and an 3rd party iframe. This pages does not have its own teasergrid layout, therefore we're only rendering the necessary paragraphs, the /simulator page is also fully hardcoded in the frontend, there is no landingpage for it in the CMS
  const useTeaserGrid = pageType === 'fullquoteDerivateSimulator';
  const itemCopy = JSON.parse(JSON.stringify(items));
  const gridItems: any =
    useTeaserGrid &&
    Array.isArray(items[pageType]) &&
    itemCopy[pageType].map((item: any) => {
      item = enrichBody({
        body: item,
        data: {
          ...getFullquotePage,
          valorName,
          market,
          currency,
          pageType: fullQuoteType.slice(1),
        },
      });
      return item;
    });

  const adEnrichedBody =
    (routeByPathObject &&
      routeByPathObject?.body &&
      enrichOverviewBodyWithADs({
        pageBody: enrichBody({
          body: routeByPathObject?.body,
          data: {
            ...getFullquotePage,
            valorName,
            market,
            currency,
            pageType: fullQuoteType.slice(1),
          },
        }),
        ignoreFirstIndexLogic: true,
        enhanceAdslotByEntryIndex: isWarning(routeByPathObject?.body)
          ? getAdSlotNameByEntryIndexOnError
          : getAdSlotNameByEntryIndex,
        noLastSlotOverride: true,
      })) ||
    null;

  return (
    <>
      {(!routeByPathObject?.channel?.suppressAds && (
        <div className="ad-wrapper ad-wrapper-mobile">
          <div className={grid.Container}>
            <AppNexus slot={TOP_AD_1} deviceType="mobile" />
          </div>
        </div>
      )) ||
        null}

      <div className={styles.Wrapper} key={`fullquote-${widgetParams}`}>
        {isWarning(routeByPathObject.body) || isHybridApp ? null : (
          <div className={styles.Breadcrumbs}>
            <Breadcrumbs
              pageUrl={location}
              items={breadcrumbItems(getFullquotePage)}
            />
          </div>
        )}
        {(routeByPathObject?.editContentUri && (
          <CreateValorButton fullquoteParam={widgetParams} />
        )) ||
          null}
        {/* on fullquote pages, we want to show the fullquote teaser grid layout by given pageType
        pls ensure you choose a page type where a teaserLayout exists */}
        {!useTeaserGrid && adEnrichedBody && (
          <EsiContext.Provider value={esiContextObject}>
            <Paragraphs
              pageBody={adEnrichedBody}
              origin={`${FULLQUOTE_PAGE_TYPE}-${pageType}`}
              colStyle={grid.ColXs24}
              landingPagePullOut
              isAdSuppressed={routeByPathObject?.channel?.suppressAds}
            />
          </EsiContext.Provider>
        )}
        {useTeaserGrid && (
          <div className={styles.InnerWrapper}>
            <TeaserGrid items={gridItems} layout={pageType} />
          </div>
        )}
      </div>
    </>
  );
};

export default compose(
  withParams,
  withHelmet({
    getNode: (mapProps) => {
      const routeByPathObject = (mapProps.data?.environment?.routeByPathSubPage
        ?.object ||
        mapProps.data?.environment?.routeByPath?.object ||
        null) as LandingPage;
      let replacedRouteByPathObject = replaceValues(
        routeByPathObject,
        mapProps.data?.getFullquotePage?.title || '',
        mapProps.valorName || '',
      );
      replacedRouteByPathObject = enrichBody({
        body: routeByPathObject,
        data: {
          ...mapProps.data?.getFullquotePage,
          valorName: mapProps?.valorName,
          currency: mapProps?.currency,
          market: mapProps?.market,
          pageType: mapProps?.location?.href
            .split(`/${mapProps?.valorName}`)?.[0]
            .slice(1),
        },
        replacements: '{}',
      });
      return {
        ...replacedRouteByPathObject,
        metaCanonicalUrl: `${global.locationOrigin}/${
          mapProps.data?.getFullquotePage?.canonicalUrl || ''
        }`,
      };
    },
    rootSchemaType: ROOT_SCHEMA_TYPE_WEBSITE,
  }),
  withAppNexus({ parseTrackingData }),
)(FullquotePage);

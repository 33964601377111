import React, { useRef } from 'react';
import AutoSuggestSearch from '../../../../../../components/AutoSuggestSearch';
import { portfolioTrade } from '../../../../../../components/PortfolioTradeForm';
import { PORTFOLIO_TRADE_FORM_TYPE_BUY } from '../../../../../../components/PortfolioTradeForm/constants';
import { SEARCH_RESULT_CONFIG } from '../../constants';
import styles from './styles.legacy.css';
import { AddInstrumentsProps } from './typings';

const AddInstrumentToPortfolio = ({
  closeDrawer,
  portfolioKey,
  searchResultWithBorder = false,
  instrumentCount = null,
  searchResultHeight,
  isInsideDrawer = false,
  origin,
}: AddInstrumentsProps) => {
  const searchRef = useRef(null);
  const addToPortfolio = (event, listingId) => {
    event.preventDefault();
    searchRef.current.clearInputfield();
    searchRef.current.closeSearchResult();
    closeDrawer?.();

    portfolioTrade({
      portfolioKey: portfolioKey,
      instrumentCount,
      instrumentKey: listingId,
      type: PORTFOLIO_TRADE_FORM_TYPE_BUY,
      origin,
    });
  };

  return (
    <AutoSuggestSearch
      ref={searchRef}
      searchResultConfig={SEARCH_RESULT_CONFIG}
      placeholderStyle={styles.InputIcon}
      resultWithBorder={searchResultWithBorder}
      placeholder="Instrument hinzufügen"
      onClickResult={addToPortfolio}
      appInputAriaLabel="Add Instruments"
      searchResultHeight={searchResultHeight}
      isInsideDrawer={isInsideDrawer}
    />
  );
};

export default AddInstrumentToPortfolio;

export const listingKeysMarket = [
  '998089-4-1',
  '998750-4-1',
  '998032-830-814',
  '274207-2913-333',
  '274702-176-333',
];

export const listingKeysMarketExtended = [
  ...listingKeysMarket,
  '897789-148-1',
  '275000-148-1',
  '275001-148-1',
  '999999915312-9910014-333',
];

export const listingKeysDevisen = [
  '897789-148-1',
  '275000-148-1',
  '275001-148-1',
  '499048-148-1',
  '999999915312-9910014-333',
];

export const listingKeysTop = ['998089-4-1'];

import { gql } from '@apollo/client';

export const MAILCHIMP_LIST_REQUEST = gql`
  mutation MailchimpListRequest(
    $action: MailchimpListAction!
    $email: String!
    $listId: String!
    $groupId: String
    $mailchimpAccountId: String!
  ) @api(name: cms) {
    mailchimpListRequest(
      input: {
        action: $action
        email: $email
        listId: $listId
        groupId: $groupId
        mailchimpAccountId: $mailchimpAccountId
      }
    )
  }
`;

import { useEffect, useState } from 'react';

const DEFAULT_OPTIONS = {
  attributes: true,
  childList: true,
  subtree: true,
};

export const useMutationObserver = (
  targetEl: Node,
  cb: MutationCallback,
  config = DEFAULT_OPTIONS,
) => {
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [cb, setObserver]);

  useEffect(() => {
    if (!observer || !targetEl) return;

    observer.observe(targetEl, config);
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, targetEl, config]);
};

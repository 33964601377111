import { useEffect } from 'react';
import { log } from '../../../shared/helpers/utils';

let refetchIntervalRef: any = null;
let refetchTimeoutRef: any = null;

const useRefetchQueryInterval = ({
  refetch,
  minutes = 15,
  callback = null,
  minTimeForCallback = 10,
}): boolean => {
  const now = new Date();
  const min = now.getMinutes();
  const milliseconds = 60_000;
  const pollInterval = milliseconds * minutes; // 15 minutes

  // get the minutes to the next quarter hour
  const minutesToNextQuarterHour = minutes - (min % minutes);
  const minutesToNextQuarterInterval = minutesToNextQuarterHour * milliseconds;
  useEffect(() => {
    if (!refetch) {
      log('HOOKS', ['useRefetchQueryInterval', 'no refetch'], 'red');
      refetchTimeoutRef && clearTimeout(refetchTimeoutRef);
      refetchIntervalRef && clearInterval(refetchIntervalRef);
      refetchTimeoutRef = null;
      refetchIntervalRef = null;
      return;
    }
    if (refetchTimeoutRef || refetchIntervalRef) {
      return;
    }
    log(
      'HOOKS',
      ['useRefetchQueryInterval', { minutesToNextQuarterHour, pollInterval }],
      'green',
    );
    refetchTimeoutRef = setTimeout(() => {
      refetch().then(() => {
        if (callback && minutesToNextQuarterHour >= minTimeForCallback) {
          callback();
        }
        refetchIntervalRef = setInterval(() => {
          refetch().then(() => {
            if (callback) {
              callback();
            }
          });
        }, pollInterval);
      });
    }, minutesToNextQuarterInterval);
  }, [
    refetch,
    pollInterval,
    minutesToNextQuarterInterval,
    callback,
    minutesToNextQuarterHour,
    minTimeForCallback,
  ]);

  return null;
};

export default (!__TESTING__ && useRefetchQueryInterval) || (() => false);

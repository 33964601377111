import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';
import lodashSlice from 'lodash/slice';
import { enrichArticleBodyWithADs } from '../../../../../../../shared/helpers/ads';
import { isNativeAdvertising as isNativeAdvertisingFunction } from '../../../../../../../shared/helpers/sharePanel';
import { getScrollOffset } from '../../../../../../shared/helpers/getScrollOffset';
import { enrichBody } from '../../../FullquotePage/helpers';
import authStateSelector from '../../../../../../../shared/selectors/authStateSelector';
import locationStateSelector from '../../../../../../../shared/selectors/locationStateSelector';
import pianoStateSelector from '../../../../../../../shared/selectors/pianoStateSelector';
import windowStateSelector from '../../../../../../../shared/selectors/windowStateSelector';
import {
  resetHeaderData,
  setHeaderData,
} from '../../../../../../../shared/actions/header';
import useImpressionTracking from '../../../../../../../shared/hooks/useImpressionTracking';
import SmoothScroll from '../../../../../../../common/components/SmoothScroll';
import AppNexus from '../../../../components/AppNexus';
import Comments from '../../../../components/Comments';
import EditButtons from '../../../../components/EditButtons';
import Paragraphs from '../../../../components/Paragraphs';
import ArticleAlerts from '../../../Article/components/ArticleAlerts';
import ArticleHeader from '../../../Article/components/ArticleHeader';
import AuthorsTeaser from '../AuthorsTeaser';
import ArticlePageAside from './../../components/ArticlePageAside';
import ArticleRecommendations from './../../components/ArticleRecommendations';
import { ArticlePropsInner } from '../..';
import { TOP_AD_1 } from '../../../../../../../shared/constants/adZone';
import {
  COMMENTS_ANCHOR_ID,
  COMMENT_STATUS_HIDDEN,
} from '../../../../../../../shared/constants/comments';
import {
  RESTRICTION_STATUS_PAID,
  RESTRICTION_STATUS_REGISTERED,
} from '../../../../../../../shared/constants/content';
import {
  PIANO_CONTAINER_INLINED,
  PIANO_PLACEHOLDER_INLINED,
} from '../../../../../../../shared/constants/piano';
import { TRACKING_CLASS_ARTICLE_BODY } from '../../../../../../../shared/constants/tracking';
import {
  AD_PLACEMENT_SLOTS_ARTICLE,
  ARTICLE_CHARACTER_COUNTS,
} from '../../../../components/AppNexus/constants';
import { PARAGRAPHS_FOR_FREE } from '../../../Article/constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import sections from '../../../../../../../common/assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';

const enrichWidgets = (body: any, article: Article | NativeAdvertising) => {
  if (!body) {
    return body;
  }

  const firstValor = article?.valors?.edges[0]?.node || null;
  if (
    firstValor &&
    firstValor.valorName &&
    firstValor.valorStockExchange?.label &&
    firstValor.valorCurrency?.label
  ) {
    return enrichBody({
      body: body,
      data: {
        listingId: `${firstValor.valorNumber}-${firstValor.valorStockExchange.originalId}-${firstValor.valorCurrency.originalId}`,
        valorName: firstValor.fullquoteUrl?.split('/')[1],
        market: firstValor.valorStockExchange.label,
        currency: firstValor.valorCurrency.label,
        mMarketId: firstValor.valorStockExchange.originalId,
        mCurrencyId: firstValor.valorCurrency.originalId,
      },
    });
  }
  return body;
};

const ArticlePageDefault = ({
  article,
  locationPathname,
  screenReady,
  setHeaderData,
  resetHeaderData,
  hasSubscriptions,
  isCrawler,
  viewportLabel,
  isInView = true,
}: ArticlePropsInner) => {
  const {
    gcid,
    subtypeValue = '',
    channel,
    commentStatus,
    preferredUri,
    socialMediaTitle,
    title,
    shortTitle,
    lead,
    __typename,
    restrictionStatus,
    keywords,
  } = article;

  const isNativeAdvertising: boolean = isNativeAdvertisingFunction(article);

  const scrollOffset = getScrollOffset(false, viewportLabel);

  useImpressionTracking({
    trackingDetailImpression: article?.trackingDetailImpression,
    pathname: locationPathname,
    screenReady,
    isNativeAdvertising,
  });

  useEffect(() => {
    if (isInView) {
      setHeaderData({
        articleData: {
          gcid,
          title,
          shortTitle,
          lead,
          subtypeValue,
          channel,
          commentStatus,
          preferredUri,
          socialMediaTitle,
          restrictionStatus,
        },
        contentType: __typename,
      });
    }
    return () => {
      resetHeaderData();
    };
  }, [
    __typename,
    gcid,
    channel,
    commentStatus,
    preferredUri,
    resetHeaderData,
    setHeaderData,
    socialMediaTitle,
    subtypeValue,
    title,
    shortTitle,
    lead,
    restrictionStatus,
    isInView,
  ]);

  const getIsReferrerWhitelisted = () => {
    let result: RegExpMatchArray;

    try {
      result = document.referrer.match(
        /(smd.ch|pro.swissdox.ch|essentials.swissdox.ch)/,
      );
    } catch (e) {
      // catch on ssr only
    }
    return result;
  };
  const isRestrictedArticle = [
    RESTRICTION_STATUS_PAID,
    RESTRICTION_STATUS_REGISTERED,
  ].includes(article?.restrictionStatus);
  const shouldHideContent =
    !hasSubscriptions && !getIsReferrerWhitelisted() && isRestrictedArticle;

  let body =
    (shouldHideContent &&
      !isCrawler &&
      lodashSlice(article.body, 0, PARAGRAPHS_FOR_FREE)) ||
    article.body;

  body = enrichWidgets(body, article);

  const enrichedArticleBody =
    isNativeAdvertising || !isInView
      ? body
      : enrichArticleBodyWithADs({
          pageBody: body,
          adPlacementSlots: AD_PLACEMENT_SLOTS_ARTICLE,
          characterCount: ARTICLE_CHARACTER_COUNTS,
        });

  return (
    <div className={styles.PageWrapper}>
      <div className={grid.Row}>
        <div className={grid.ColXs24}>
          <EditButtons
            editContentUri={article.editContentUri}
            editRelationUri={article.editRelationUri}
            cloneContentUri={article.cloneContentUri}
          />
        </div>
        <div
          className={classNames(
            grid.ColXs24,
            grid.ColMd15,
            grid.ColXl16,
            styles.Print,
          )}
        >
          <div className={classNames('article-detail', styles.Wrapper)}>
            <div className={styles.ArticleInner}>
              <ArticleHeader article={article} isInView={isInView} />

              {!(article?.channel?.suppressAds || isNativeAdvertising) && (
                <div
                  className={classNames(
                    styles.MMR1Wrapper,
                    'ad-wrapper ad-wrapper-mobile header-apn-zone',
                  )}
                >
                  <div className={grid.Container}>
                    <AppNexus
                      slot={(isInView && TOP_AD_1) || 'MMR1Placeholder'}
                      deviceType="mobile"
                    />
                  </div>
                </div>
              )}

              <div
                className={classNames(
                  styles.BodyWrapper,
                  TRACKING_CLASS_ARTICLE_BODY,
                )}
              >
                <Paragraphs
                  contentGcid={article.gcid}
                  articleKeywords={article.keywords}
                  pageBody={enrichedArticleBody}
                  colStyle={classNames(grid.ColXs24)}
                  origin={article?.subtypeValue || article.__typename}
                  activeChannel={article?.channel?.title || ''}
                  paragraphsForFree={
                    (shouldHideContent && PARAGRAPHS_FOR_FREE) || null
                  }
                  isAdSuppressed={
                    article?.channel?.suppressAds || isNativeAdvertising
                  }
                  hasContainer={false}
                />

                <AuthorsTeaser authors={article.authors?.edges || []} />

                {article.issue?.nid &&
                  (!shouldHideContent || (isCrawler && shouldHideContent)) && (
                    <div
                      className={`${
                        (isRestrictedArticle && 'restricted-') || ''
                      }article-magazin-issue`}
                    >
                      {/* <MagazineIssueSection issueId={article.issue.nid} /> */}
                    </div>
                  )}
                {!isCrawler && (
                  <div
                    className={classNames(styles.Paywall, {
                      [styles.HiddenPaywall]: !shouldHideContent,
                    })}
                  >
                    <div className={grid.Container}>
                      <div className={grid.Row}>
                        <div className={grid.ColXl17}>
                          <div
                            id={PIANO_CONTAINER_INLINED}
                            className={PIANO_PLACEHOLDER_INLINED}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className={sections.Section}>
                {article?.topics?.edges &&
                  (!shouldHideContent || (isCrawler && shouldHideContent)) &&
                  Array.isArray(article.topics.edges) &&
                  article.topics.edges.length > 0 && (
                    <div
                      className={classNames(
                        `${
                          (isRestrictedArticle && 'restricted-') || ''
                        }article-alerts`,
                      )}
                    >
                      <ArticleAlerts items={article.topics.edges} />
                    </div>
                  )}

                <SmoothScroll
                  anchorId={(isInView && COMMENTS_ANCHOR_ID) || ''}
                  offset={scrollOffset}
                />
                {!isNativeAdvertising &&
                  (!shouldHideContent || (isCrawler && shouldHideContent)) &&
                  article.commentStatus &&
                  article.commentStatus !== COMMENT_STATUS_HIDDEN && (
                    <div
                      className={`${
                        (isRestrictedArticle && 'restricted-') || ''
                      }article-comments`}
                    >
                      <Comments
                        articleId={article.id || ''}
                        gcid={gcid || ''}
                        commentStatus={article.commentStatus || ''}
                        isInView={isInView}
                      />
                    </div>
                  )}

                <ArticleRecommendations
                  gcid={gcid}
                  keywords={keywords}
                  isNativeAdvertising={isNativeAdvertising}
                />
              </div>
            </div>
          </div>
        </div>
        <ArticlePageAside article={article} />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setHeaderData,
  resetHeaderData,
};

const mapStateToProps = (state: ReduxState) => ({
  locationPathname:
    locationStateSelector(state).locationBeforeTransitions.pathname,
  screenReady: locationStateSelector(state).screenReady,
  hasSubscriptions:
    authStateSelector(state).hasSubscriptions ||
    pianoStateSelector(state).isAccessGranted,
  isCrawler: locationStateSelector(state)?.isCrawler || false,
  viewportLabel: windowStateSelector(state).viewport.label,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ArticlePageDefault,
);

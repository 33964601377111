import classNames from 'classnames';
import teaserStageParagraphFactory from '../../../../../../../common/components/Paragraphs/components/TeaserStageParagraph/factory';
import TeaserGrid from '../../../TeaserGrid';
import { TeaserLayout } from '../../../TeaserGrid/gridConfigs';
import { TEASER_STAGE_PARAGRAPH_STYLE_RANDOMIZED_LIST } from '../../../../../../../shared/constants/paragraphs';
import { PAGESCREEN_MARKETING_TYPE } from '../../../../screens/PageScreen/constants';
import styles from './styles.legacy.css';
import { TeaserStageParagraphProps } from '../../../../../../../common/components/Paragraphs/components/TeaserStageParagraph/typings';

const getStyleByProps = ({ origin, teaserStage }) => ({
  Wrapper: classNames({
    [styles.Wrapper]:
      teaserStage.style === TEASER_STAGE_PARAGRAPH_STYLE_RANDOMIZED_LIST,
  }),
  SectionTitle: classNames(styles.SectionTitle, {
    [styles.RandomizedList]:
      teaserStage.style === TEASER_STAGE_PARAGRAPH_STYLE_RANDOMIZED_LIST,
  }),
  TitleLink: classNames(styles.TitleLink, {
    [styles.MarketingPage]: origin === PAGESCREEN_MARKETING_TYPE,
    [styles.RandomizedList]:
      teaserStage.style === TEASER_STAGE_PARAGRAPH_STYLE_RANDOMIZED_LIST,
  }),
});

export const getGridLayoutByProps = ({
  teaserStage,
}: TeaserStageParagraphProps): TeaserLayout => {
  const itemsCount = teaserStage?.entities?.edges?.length || 0;
  let layout: TeaserLayout = null;

  switch (itemsCount) {
    case 1:
      layout = 'teaserStage1Item';
      break;
    case 2:
      layout = 'teaserStage2Items';
      break;
    case 3:
      layout = 'teaserStage3Items';
      break;
    case 4:
      layout = 'teaserStageDefault';
      break;
    default:
      layout = 'teaserStageUnlimited';
      break;
  }

  if (teaserStage.style === TEASER_STAGE_PARAGRAPH_STYLE_RANDOMIZED_LIST) {
    layout = 'teaserStageRandomizedList';
  }

  return layout;
};

const TeaserStageParagraph = teaserStageParagraphFactory({
  ensureTeaserInterface: (item, isHeadless) => ({ ...item, isHeadless }),
  gridLayout: getGridLayoutByProps,
  TeaserGridRenderer: () => TeaserGrid,
  styles: getStyleByProps,
});

export default TeaserStageParagraph;

import { RaschApolloConfig } from '../../../../../../../shared/decorators/withRaschRouterFactory';
import { GET_RATE_BY_CURRENCY } from './queries';

export const rateByCurrencyApolloConfig: RaschApolloConfig = {
  options: ({ params }) => {
    const { portfolioCurrency, currency } = params;
    return {
      query: GET_RATE_BY_CURRENCY,
      variables: {
        portfolioCurrency: portfolioCurrency,
        currency: currency,
      },
      fetchPolicy: 'network-only',
      ssr: false,
      skip: !portfolioCurrency || !currency,
    };
  },
};

import React, { memo } from 'react';
import styles from './styles.legacy.css';
import {
  MARKET_GROUPING,
  PAPER_VALUES_GROUPING,
  PAPER_VALUES_GROUPING_MAPPING,
} from '../../../../constants';
import { GroupTableRowProps } from './typings';

const GroupTableRow = ({
  group,
  groupType,
  tableFieldHeaders,
  groupedInstruments,
}: GroupTableRowProps) => {
  let groupLabel = group;
  if (
    groupType === MARKET_GROUPING &&
    groupedInstruments?.[0]?.marketDescription
  ) {
    groupLabel = `${groupedInstruments?.[0]?.marketDescription} (${group})`;
  } else if (groupType === PAPER_VALUES_GROUPING) {
    groupLabel =
      (PAPER_VALUES_GROUPING_MAPPING[group] &&
        `${PAPER_VALUES_GROUPING_MAPPING[group]} (${group})`) ||
      `Diverse (${group})`;
  }
  return (
    <tr className={group ? styles.SortLabel : ''}>
      <td colSpan={tableFieldHeaders.length}>
        <div className={styles.SortLabelSticky}>{groupLabel}</div>
      </td>
    </tr>
  );
};

export default memo<GroupTableRowProps>(GroupTableRow);

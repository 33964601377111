import { toast } from 'react-toastify';
import { tealiumTrackEvent } from '../../../../../../../shared/helpers/tealium';
import { handleEditPortfolioMutation } from '../Portfolio/helpers';
import { handleEditWatchlistMutation } from '../Watchlist/helpers';
import { portfolioEdit } from '../../../../components/PortfolioManagementForm';
import {
  displayErrorToast,
  displayInfoToast,
  displaySuccessToast,
} from '../../../../components/Toast';
import { watchlistEdit } from '../../../../components/WatchlistManagementForm';
import modal from '../../../../components/Modal';
import { ROUTE_PORTFOLIOS } from '../../../../constants';
import { GET_PORTFOLIOS, GET_PORTFOLIO_BY_KEY } from '../Portfolio/queries';
import { GET_PORTFOLIOS_CALCULATED } from '../Portfolios/queries';
import { GET_WATCHLIST_BY_KEY } from '../Watchlist/queries';
import {
  portfolioByKeyApolloConfig,
  portfolioScreenApolloConfig,
} from '../Portfolio/apolloConfig';
import { portfoliosCalculatedScreenApolloConfig } from '../Portfolios/apolloConfig';
import { watchlistByKeyApolloConfig } from '../Watchlist/apolloConfig';

export const saveUserSettings = ({
  currentKey,
  query,
  editDefaultsMutation,
  setEditLoading,
  setSubmitError,
  submitError,
  isDirty,
  originType,
}) => {
  const errorMsg =
    'Leider ist beim Speichern Ihrer Einstellungen ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.';

  if (currentKey) {
    const isPortfolio = originType === 'portfolio';
    const { ...options } =
      (isPortfolio &&
        portfolioByKeyApolloConfig.options({
          location,
          params: {
            portfolioKey: currentKey,
            withTransaction: 'false',
          },
        })) ||
      watchlistByKeyApolloConfig.options({
        location,
        params: {
          watchlistKey: currentKey,
        },
      });

    editDefaultsMutation({
      variables: {
        key: currentKey,
        view: query?.type || '',
        grouping: query?.group || '',
        settingsType: originType,
      },
      refetchQueries: [
        {
          query: (isPortfolio && GET_PORTFOLIO_BY_KEY) || GET_WATCHLIST_BY_KEY,
          variables: {
            ...options.variables,
          },
        },
      ],
    })
      .then(({ data }): Promise<void> => {
        if (
          data?.editDefaults &&
          data?.editDefaults?.error &&
          data?.editDefaults?.error !== null
        ) {
          if (!toast.isActive(`${originType}-settings-error`)) {
            displayErrorToast(errorMsg, `${originType}-settings-error`);
          }
          setSubmitError(true);
          setEditLoading(false);
          return;
        }

        if (toast.isActive(`${originType}-settings-error`)) {
          toast.dismiss(`${originType}-settings-error`);
        }

        setEditLoading(false);
        setSubmitError(false);
        if (isDirty) {
          isDirty.current = null;
        }

        displaySuccessToast(
          'Die aktuelle Ansicht und Gruppierung wurde als Standard gespeichert. ',
          `${originType}-settings-submitted`,
        );
      })
      .catch((): void => {
        if (!submitError && !toast.isActive(`${originType}-settings-error`)) {
          displayErrorToast(errorMsg, `${originType}-settings-error`);
        }

        setSubmitError(true);
        setEditLoading(false);
        return;
      });
  } else {
    displayErrorToast(errorMsg, `${originType}-settings-error`);
  }
};

export const handleSetDefaultClick = (
  isDefault,
  editMutation,
  mutationVariables,
  submitError,
  setSubmitError,
  originType,
) => {
  const isPortfolio = originType === 'portfolio';
  const portfolioDefaultInfoMessage =
    'Das Portfolio "%name%" ist bereits Ihr Standard-Portfolio.';
  const watchlistDefaultInfoMessage =
    'Die Watchlist "%name%" ist bereits Ihre Standard-Watchlist.';
  if (isDefault) {
    displayInfoToast(
      (isPortfolio &&
        portfolioDefaultInfoMessage.replace(
          '%name%',
          mutationVariables.name,
        )) ||
        watchlistDefaultInfoMessage.replace('%name%', mutationVariables.name),
      `${originType}-set-default-info`,
    );
  } else if (isPortfolio) {
    handleEditPortfolioMutation(
      mutationVariables,
      editMutation,
      setSubmitError,
      submitError,
    );
  } else {
    handleEditWatchlistMutation(
      mutationVariables,
      editMutation,
      setSubmitError,
      submitError,
    );
  }
};

export const deletePortfolio = ({
  portfolioKey,
  deletePortfolioMutation,
  setDeletePortfolioLoading,
  navigate,
}) => {
  const { ...portfoliosCalculatedOptions } =
    portfoliosCalculatedScreenApolloConfig.options({
      location,
      params: {
        isAuthenticated: 'true',
      },
    });
  const { ...portfolioOptions } = portfolioScreenApolloConfig.options({
    location,
    params: {
      isAuthenticated: 'true',
    },
  });
  const errorMsg =
    'Leider ist beim Löschen Ihres Portfolios ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.';

  if (portfolioKey) {
    setDeletePortfolioLoading(true);
    deletePortfolioMutation({
      variables: {
        portfolioKey: portfolioKey,
      },
      refetchQueries: [
        {
          query: GET_PORTFOLIOS,
          variables: portfolioOptions.variables,
        },
        {
          query: GET_PORTFOLIOS_CALCULATED,
          variables: portfoliosCalculatedOptions.variables,
        },
      ],
    }).then(({ data }): Promise<void> => {
      if (
        data?.deletePortfolio &&
        data?.deletePortfolio?.error &&
        data?.deletePortfolio?.error !== null
      ) {
        if (!toast.isActive('portfolio-delete-error')) {
          displayErrorToast(errorMsg, 'portfolio-delete-error');
        }
        setDeletePortfolioLoading(false);
        return;
      }

      if (toast.isActive('portfolio-delete-error')) {
        toast.dismiss('portfolio-delete-error');
      }

      setDeletePortfolioLoading(false);
      const refetchPortfolioList = global.refetchAllPortfoliosGQL;
      if (refetchPortfolioList) {
        refetchPortfolioList().then(() => {
          // track tealium event on successful portfolio deletion
          tealiumTrackEvent({
            type: 'link',
            payload: {
              event_name: 'portfolio_delete',
              event_category: 'portfolio',
              event_action: 'portfolio_delete',
              portfolio_key: portfolioKey,
            },
          });

          // navigate to the default portfolio when the current portfolio was successfully deleted
          navigate(`/${ROUTE_PORTFOLIOS}`);
        });
      }
    });
  } else {
    displayErrorToast(errorMsg, 'portfolio-delete-error');
  }
};

export const deleteWatchlist = ({
  watchlistKey,
  deleteWatchlistMutation,
  setDeleteWatchlistLoading,
  navigate,
}) => {
  const errorMsg =
    'Leider ist beim Löschen Ihrer Watchlist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.';

  if (watchlistKey) {
    setDeleteWatchlistLoading(true);
    deleteWatchlistMutation({
      variables: {
        watchlistKey,
      },
    }).then(({ data }): Promise<void> => {
      if (
        data?.deleteWatchlist &&
        data?.deleteWatchlist?.error &&
        data?.deleteWatchlist?.error !== null
      ) {
        if (!toast.isActive('watchlist-delete-error')) {
          displayErrorToast(errorMsg, 'watchlist-delete-error');
        }
        setDeleteWatchlistLoading(false);
        return;
      }

      if (toast.isActive('watchlist-delete-error')) {
        toast.dismiss('watchlist-delete-error');
      }

      setDeleteWatchlistLoading(false);
      const refetchWatchlistCollection = global.refetchAllWatchlistsGQL;
      if (refetchWatchlistCollection) {
        refetchWatchlistCollection().then(() => {
          // track tealium event on successful watchlist deletion
          tealiumTrackEvent({
            type: 'link',
            payload: {
              event_name: 'watchlist_delete',
              event_category: 'watchlist',
              event_action: 'watchlist_delete',
              watchlist_key: watchlistKey,
            },
          });

          // navigate to the standard watchlist when the current watchlist was successfully deleted
          navigate('/watchlist');
        });
      }
    });
  } else {
    displayErrorToast(errorMsg, 'watchlist-delete-error');
  }
};

const handleModalDeleteClick = ({
  originType,
  key,
  deleteMutation,
  setDeleteLoading,
  navigate,
}) => {
  if (originType === 'portfolio') {
    deletePortfolio({
      portfolioKey: key,
      deletePortfolioMutation: deleteMutation,
      setDeletePortfolioLoading: setDeleteLoading,
      navigate,
    });
  } else if (originType === 'watchlist') {
    deleteWatchlist({
      watchlistKey: key,
      deleteWatchlistMutation: deleteMutation,
      setDeleteWatchlistLoading: setDeleteLoading,
      navigate,
    });
  }
};

export const handleDeleteClick = ({
  name,
  key,
  isDefault,
  deleteMutation,
  setDeleteLoading,
  navigate,
  originType,
}: any) => {
  const isPortfolio = originType === 'portfolio';
  const portfolioDeleteInfoMessage = `Es ist nicht möglich Ihr Standard-Portfolio zu löschen. Bitte wählen Sie zuerst ein anderes Portfolio als Standard bevor Sie dieses löschen.`;
  const watchlistDeleteInfoMessage = `Es ist nicht möglich Ihre Standard-Watchlist zu löschen. Bitte wählen Sie zuerst eine andere Watchlist als Standard bevor Sie dieses löschen.`;
  if (isDefault) {
    displayInfoToast(
      isPortfolio ? portfolioDeleteInfoMessage : watchlistDeleteInfoMessage,
      `${originType}-delete-info`,
    );
  } else {
    modal({
      title: `"${name}" löschen`,
      hasStickyHeader: true,
      hasStickyFooter: true,
      content: `Sind Sie sicher, dass Sie "${name}" löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.`,
      buttons: [
        {
          children: 'Abbrechen',
        },
        {
          variant: 'secondary',
          children: 'Löschen',
          iconTypeLeft: 'IconTrash',
          onClick: () => {
            handleModalDeleteClick({
              originType,
              key,
              deleteMutation,
              setDeleteLoading,
              navigate,
            });
          },
        },
      ],
    });
  }
};

export const handleEditClick = ({
  key,
  name,
  isDefault,
  navigate,
  originType,
}) => {
  if (originType === 'portfolio') {
    return portfolioEdit({
      portfolioKey: key,
      portfolioName: name,
      isDefaultPortfolio: isDefault,
      navigate,
    });
  } else if (originType === 'watchlist') {
    return watchlistEdit({
      watchlistKey: key,
      watchlistName: name,
      isStandardWatchlist: isDefault,
      navigate,
    });
  }
};

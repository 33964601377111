import React from 'react';
import TeaserAuthor from '../../../../components/Teaser/components/TeaserAuthor';
import { PUBLICATION_CASH } from '../../../../../../../shared/constants/publications';
import styles from './styles.legacy.css';
import { AuthorsTeaserProps } from './typings';

const AuthorsTeaser = ({ authors }: AuthorsTeaserProps) => {
  if (!authors || authors.length <= 0) {
    return null;
  }
  return (
    <div className={styles.AuthorsWrapper}>
      {authors
        .filter(
          ({ node: { hasProfilePage, preferredUri, publications } }) =>
            hasProfilePage &&
            preferredUri &&
            publications.includes(PUBLICATION_CASH),
        )
        .map(({ node: author }) => (
          <div key={author.id}>
            <div className={styles.Divider} />
            <TeaserAuthor
              author={author}
              insideArticle
              withAuthorImage
              readMoreLabel={'Mehr erfahren'}
            />
          </div>
        ))}
      <div className={styles.Divider} />
    </div>
  );
};

export default AuthorsTeaser;

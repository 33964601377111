export const MARKET_TABLE_ORIGIN = 'market-table';

// see comment regarding overwrite https://jira.ringieraxelspringer.ch/browse/RDP-2838
export const MARKET_TABLE_OVERWRITE = {
  '998089-4-1': {
    name: null,
    link: 'kurse/aktien/schweiz/smi-index',
  },
  '998750-4-1': {
    name: null,
    link: 'kurse/aktien/schweiz/spi-index',
  },
  '846480-353-814': {
    name: null,
    link: 'kurse/aktien/europa/euro-stoxx-50',
  },
  '998032-830-814': {
    name: null,
    link: 'kurse/aktien/deutschland/dax-index-frankfurt-fra',
  },
  '998313-75021-333': {
    name: 'DJ',
    link: 'kurse/aktien/usa-und-kanada/djia',
  },
  '985336-1135-333': {
    name: null,
    link: 'kurse/aktien/usa-und-kanada/nasdaq-100',
  },
  '897789-148-1': {
    name: null,
    link: 'kurse/waehrungen/devisen/chf-spot-rates-wichtigste-waehrungen',
  },
  '275000-148-1': {
    name: null,
    link: 'kurse/waehrungen/devisen/usd-spot-rates',
  },
  '274702-176-333': {
    name: null,
    link: 'kurse/rohstoffe-edelmetalle/uebersicht/preise-pro-kilo-und-unze',
  },
  '999999915312-9910014-333': {
    name: 'Bitcoin',
    link: null,
  },
};

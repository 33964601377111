import React, { ReactElement, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { useStableNavigate } from '../../../../../shared/hooks/useStableNavigateContext';
import locationStateSelector from '../../../../shared/selectors/locationStateSelector';
import useRaschRouterLocation from '../../../../../shared/hooks/useRaschRouterLocation';
import Auth0Provider from '../../../../../common/components/Auth0Provider';
import BackgroundFetchProvider from '../../../../../common/components/BackgroundFetchProvider';
import ClientSideOnly from '../../../../../common/components/ClientSideOnly';
import HybridAppProvider from '../../../../../common/components/HybridAppProvider';
import OneSignalProvider from '../../../../../common/components/OneSignalProvider';
import PerformanceMeasureProvider from '../../../../../common/components/PerformanceMeasureProvider';
import PianoProvider from '../../../../../common/components/PianoProvider';
import TealiumProvider from '../../../../../common/components/Tealium/components/TealiumProvider';
import AppNexusProvider from '../AppNexusProvider';
import AppSetup from '../AppSetup';
import { selectWatchlistAndAddInstrument } from '../../screens/MyCash/components/Watchlist/components/AddInstrumentToWatchlist';
import { alertsFormOverlay } from '../AlertsForm';
import { selectPortfolioAndTrade } from '../PortfolioTradeForm';
import { useWidgetPage } from '../../../../shared/hooks/useWidgetPage';

const ToastContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "ToastContainer" */ '../../../../../common/components/ToastContainer'
    ),
);

const RaschProviders = ({ ignoreToastProvider = false }): ReactElement => {
  const navigate = useStableNavigate();
  const location = useRaschRouterLocation();
  const { isWidgetPage } = useWidgetPage();
  const isHybridApp = useSelector(
    (state) => locationStateSelector(state).isHybridApp,
  );
  if (typeof global.alertsFormOverlay === 'undefined') {
    global.alertsFormOverlay = ({ alertKey, fullquoteUri }) => {
      alertsFormOverlay({
        alertKey,
        fullquoteUri,
        navigate,
        location,
      });
    };
  }
  if (typeof global.selectPortfolioAndTrade === 'undefined') {
    global.selectPortfolioAndTrade = ({ instrumentKey, type, origin = '' }) => {
      selectPortfolioAndTrade({
        instrumentKey,
        type,
        origin,
      });
    };
  }
  if (typeof global.selectWatchlistAndAddInstrument === 'undefined') {
    global.selectWatchlistAndAddInstrument = ({
      instrumentKey,
      instrumentName,
      origin = '',
    }) => {
      selectWatchlistAndAddInstrument({
        instrumentKey,
        instrumentName,
        origin,
      });
    };
  }
  return (
    <>
      <AppSetup />
      <AppNexusProvider />
      <Auth0Provider />
      {!isHybridApp && <OneSignalProvider />}
      {!isWidgetPage && <TealiumProvider />}
      <PianoProvider />
      {!ignoreToastProvider && (
        <ClientSideOnly>
          <Suspense>
            <ToastContainer />
          </Suspense>
        </ClientSideOnly>
      )}
      <BackgroundFetchProvider />
      <PerformanceMeasureProvider />
      <HybridAppProvider />
    </>
  );
};

export default RaschProviders;

import { gql } from '@apollo/client';

export const GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS = gql`
  query AutoUpdateWithCredentials($listingKeys: String!, $constituents: Boolean)
  @api(name: "graphql-service") {
    quoteList(listingKeys: $listingKeys, constituents: $constituents) {
      id
      quoteList {
        count
        edges {
          node {
            __typename
            ... on Instrument {
              id
              ask
              askDatetime
              askDatetime1st
              askDatetime2nd
              askDatetime3rd
              askDatetime4th
              askDatetime5th
              askDatetime6th
              askDatetime7th
              askDatetime8th
              askDatetime9th
              askDatetime10th
              askValue1st
              askValue2nd
              askValue3rd
              askValue4th
              askValue5th
              askValue6th
              askValue7th
              askValue8th
              askValue9th
              askValue10th
              askVolume
              askVolume1st
              askVolume2nd
              askVolume3rd
              askVolume4th
              askVolume5th
              askVolume6th
              askVolume7th
              askVolume8th
              askVolume9th
              askVolume10th
              bcClose
              bcOpen
              bid
              bidDatetime
              bidDatetime1st
              bidDatetime2nd
              bidDatetime3rd
              bidDatetime4th
              bidDatetime5th
              bidDatetime6th
              bidDatetime7th
              bidDatetime8th
              bidDatetime9th
              bidDatetime10th
              bidValue1st
              bidValue2nd
              bidValue3rd
              bidValue4th
              bidValue5th
              bidValue6th
              bidValue7th
              bidValue8th
              bidValue9th
              bidValue10th
              bidVolume
              bidVolume1st
              bidVolume2nd
              bidVolume3rd
              bidVolume4th
              bidVolume5th
              bidVolume6th
              bidVolume7th
              bidVolume8th
              bidVolume9th
              bidVolume10th
              chanceFontIcon
              currentPrice
              currentValue
              dayBefore
              dayBeforeDate
              dividenYield
              eusipaId
              fullquoteUri
              hi52w
              hi52wDatetime
              high
              highDate
              iNetVperprV
              iNetVperprVPr
              instrumentKey
              isMarketOpen
              isin
              kgv
              lastDividend
              lo52w
              lo52wDatetime
              low
              lowDate
              lval
              lvalDatetime
              lvalVolume
              market
              marketDescription
              mCur
              mName
              monitorFontIcon
              mSymb
              open
              offvol
              openDatetime
              paidAverage
              pmAvVol
              pricingQuotationId
              perfPercentage
              perfPercentage1w
              perfPercentage4w
              perfPercentage12w
              perfPercentage26w
              perfPercentage52w
              perfPercentageYTD
              perfPercentage3Y
              relativePerformance
              scGrouped
              sensitivityFontIcon
              vol
              tur
              tottur
              totvol
              pOfftur
              pOffvol
              pVol
              pTur
              volDatetime
              totvolDatetime
              pOffvolDatetime
              pVolDatetime
              convexity
              dayCountConvention
              denomination
              dividendPolicy
              domicile
              duration
              exchangeDomicile
              instrumentType
              instrumentUnit
              interest
              interestDate
              issueAmt
              issueDate
              issuePrice
              issueVolume
              lastDividendDatetime
              lastTradingDate
              marketCap
              maturity
              mdur
              nominal
              nominalCurrency
              paymentFrequency
              paymentFrequencyUnit
              redemptionPayment
              sector
              seniority
              tradedShares
              unit
              wkn
              mValor
              yldeq
              prevLo52w
              prevLo52wDatetime
              yLo
              yLoDatetime
              yHi
              yHiDatetime
              prevHi52w
              prevHi52wDatetime
              prevYearHigh
              prevYearLow
              prevYearHighDatetime
              prevYearLowDatetime
              companyFullName
              callbyissuer
              callbyholder
              accInt
              yield
              rlife
              mic
              pricingQuotationId
            }
          }
        }
      }
    }
  }
`;

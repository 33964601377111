import { RaschApolloConfig } from '../../../../../../../shared/decorators/withRaschRouterFactory';
import { ITEMS_PER_PAGE } from './constants';
import { GET_ALERTS_LIST_EXTERNAL } from './queries';

export const alertsScreenApolloConfig: RaschApolloConfig = {
  options: ({ location }) => {
    const page = location?.query?.page || 1;
    return {
      query: GET_ALERTS_LIST_EXTERNAL,
      variables: {
        publication: 'CASH',
        limit: ITEMS_PER_PAGE,
        offset: Math.abs(page - 1) * ITEMS_PER_PAGE,
        cacheBustor: '',
      },
    };
  },
};

import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.legacy.css';
import { TimerProps } from './typings';

const Timer = ({ bcOpen, bcClose }: TimerProps) => {
  const [delta, setDelta] = useState('');
  const [isWeekend, setIsWeekend] = useState(false);
  const intervalRef = useRef(null);
  const [isMarketOpen, setIsMarketOpen] = useState(null);

  useEffect(() => {
    const timeString = (
      bcOpen: Date,
      bcClose: Date,
      currentDate: Date,
    ): string => {
      const calculationDate = isMarketOpen ? bcClose : bcOpen;

      if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
        setIsWeekend(true);
      }

      let hourOffset = 0;

      let minutes = calculationDate.getMinutes() - currentDate.getMinutes();
      if (minutes < 0) {
        minutes += 60;
        hourOffset = -1;
      }

      let hours =
        calculationDate.getHours() - currentDate.getHours() + hourOffset;
      if (hours < 0) {
        hours += 24;
      }

      return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')} Stunden`;
    };

    const currentDate = new Date();
    const currentTimeStamp = Date.parse(new Date(currentDate).toString());
    const openSeconds = new Date(bcOpen).getSeconds() * 1000;
    const closeSeconds = new Date(bcClose).getSeconds() * 1000;

    const marketTimeStamp = {
      open: Date.parse(new Date(bcOpen).toString()) - openSeconds,
      close: Date.parse(new Date(bcClose).toString()) - closeSeconds,
    };

    const isOpen =
      currentTimeStamp > marketTimeStamp.open &&
      currentTimeStamp < marketTimeStamp.close;

    setIsMarketOpen(isOpen);

    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setDelta(timeString(new Date(bcOpen), new Date(bcClose), currentDate));
      }, 1_000);
    } else {
      setDelta(timeString(new Date(bcOpen), new Date(bcClose), currentDate));
    }
  }, [isMarketOpen, setIsMarketOpen, bcOpen, bcClose, delta]);

  if (!bcOpen || !bcClose || isWeekend) {
    return null;
  }

  return (
    <p className={styles.Info} data-testid="fullquote-header-timer">
      Markt {isMarketOpen ? 'geöffnet' : 'geschlossen'} ·{' '}
      {isMarketOpen ? 'Schliesst' : 'Öffnet'} in {delta}
    </p>
  );
};

export default Timer;

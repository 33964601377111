export const pianoOfferConfig = {
  // Piano Sandbox
  sandbox: {
    anleger: {
      templateId: 'OTUEB4CYI9GZ',
      offerId: 'OFPCX29SBUNG',
      variantId: 'OTVFJ3IVIQCSH',
    },
    realtime: {
      templateId: 'OTUEB4CYI9GZ',
      offerId: 'OFTA3M9H1C1C',
      variantId: 'OTV446R5W5EPC',
    },
    profi: {
      templateId: 'OTUEB4CYI9GZ',
      offerId: 'OFLY4BR5ACHY',
      variantId: 'OTVADUC9CMWIP',
    },
  },
  // Piano Production
  production: {
    anleger: {
      templateId: 'OTBR4PDCR7TP',
      offerId: 'OF8SD6XM044P',
      variantId: 'OTV5H3KBTYRAK',
    },
    realtime: {
      templateId: 'OTBR4PDCR7TP',
      offerId: 'OFJUYO1C2RVZ',
      variantId: 'OTVPS4D80BP2J',
    },
    profi: {
      templateId: 'OTBR4PDCR7TP',
      offerId: 'OF4MRTZ1V1KE',
      variantId: 'OTVGRJ6KFR0JP',
    },
  },
};

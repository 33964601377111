import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { setInstrumentKeysAnonymous } from '../../../../../../shared/actions/autoUpdate';
import TopFlopTable from './components/TopFlopTable';
import {
  DEFAULT_SPONSOR_IMAGE,
  DEFAULT_SPONSOR_IMAGE_URL,
  SMI_LISTING_KEY,
  SP500_LISTING_KEY,
  SP500_LISTING_KEY_CUSTOM,
} from './constants';
import { GET_QUOTES_TABLE_DATA } from '../QuoteList/queries';
import styles from './styles.legacy.css';
import { QueryResult, TopFlopProps } from './typings';
import { isListingKey } from '../../../../screens/MyCash/components/Portfolio/helpers';
import { tealiumTrackEvent } from '../../../../../../../shared/helpers/tealium';
import useInView, {
  UseInViewResponse,
} from '../../../../../../../shared/hooks/useInView';

const TopFlop = ({ widgetParagraph }: TopFlopProps) => {
  const { setRef: setRefTop, isInView: isInViewTop }: UseInViewResponse =
    useInView({
      triggerOnce: true,
      threshold: 0.5,
    });
  const { setRef: setRefFlop, isInView: isInViewFlop }: UseInViewResponse =
    useInView({
      triggerOnce: true,
      threshold: 0.5,
    });
  const path = widgetParagraph?.link?.path;
  const url =
    (path && path.startsWith('/') && new URL(path, global.locationOrigin)) ||
    (path && new URL(path)) ||
    null;
  // this key is needed if the widget is used somewhere else than the quotes page
  // in this case the listingkey of the index has to be provided in the cms
  let listingKey = null;
  listingKey = url?.searchParams?.get('listingKey')?.trim();
  const listingKeyFromPath = listingKey ? true : false;
  const nodes =
    widgetParagraph?.quoteList?.quoteList?.edges.map((edge) => edge.node) || [];
  listingKey = listingKey || nodes[0]?.instrumentKey;
  const instruments = nodes;

  const dispatch = useDispatch();

  if (listingKey === SP500_LISTING_KEY) {
    // S&P 500 has to be fetched by query because we cannot use the
    // paginated data (only the first 200 instruments are fetched)
    listingKey = SP500_LISTING_KEY_CUSTOM;
  }

  const { data, loading, error } = useQuery<QueryResult>(
    GET_QUOTES_TABLE_DATA,
    {
      variables: {
        listingKeys: listingKey,
        constituents: listingKey !== SP500_LISTING_KEY_CUSTOM,
      },
      skip: !isListingKey(listingKey),
      ssr: false,
    },
  );

  const loadedNodes =
    data?.quoteList?.quoteList?.edges.map((edge) => edge.node) || nodes;

  useEffect(() => {
    if (instruments?.length > 0) {
      dispatch(
        setInstrumentKeysAnonymous(
          instruments.map((instrument) => {
            return {
              isMarketOpen: true,
              listingKey: instrument.instrumentKey,
            };
          }),
        ),
      );
    }
    if (loadedNodes?.length > 0) {
      dispatch(
        setInstrumentKeysAnonymous(
          loadedNodes.map((instrument) => {
            return {
              isMarketOpen: true,
              listingKey: instrument.instrumentKey,
            };
          }),
        ),
      );
    }
  }, [dispatch, listingKey, instruments, loadedNodes]);

  if (
    !listingKey ||
    (instruments.length === 0 && loadedNodes.length === 0) ||
    loading ||
    error
  ) {
    return <div className={styles.Skeleton}></div>;
  }

  const tracking = (integration_element: 'top' | 'flop') => {
    // only track on sponsored widget
    if (listingKey !== SMI_LISTING_KEY) {
      return;
    }
    tealiumTrackEvent({
      type: 'link',
      payload: {
        event_name: 'integration_impression',
        integration_action: 'Impression',
        integration_name: 'top_flop',
        integration_sponsor: 'BNPP',
        event_trigger: 'custom',
        integration_element: integration_element,
      },
    });
  };

  if (isInViewTop) {
    tracking('top');
  }

  if (isInViewFlop) {
    tracking('flop');
  }

  return (
    <div className={styles.Wrapper}>
      <div ref={setRefTop}>
        <p className={styles.Title}>Top</p>
        <TopFlopTable
          instruments={listingKeyFromPath ? loadedNodes : instruments}
          order="asc"
          limit={10}
          addPutsCallsLinks={listingKey === SMI_LISTING_KEY ? true : false}
        />
      </div>
      <div ref={setRefFlop}>
        <p className={styles.Title}>Flop</p>
        <TopFlopTable
          instruments={listingKeyFromPath ? loadedNodes : instruments}
          order="desc"
          limit={10}
          sponsorImage={
            listingKey === SMI_LISTING_KEY ? DEFAULT_SPONSOR_IMAGE : null
          }
          sponsorImageUrl={
            listingKey === SMI_LISTING_KEY ? DEFAULT_SPONSOR_IMAGE_URL : null
          }
          addPutsCallsLinks={listingKey === SMI_LISTING_KEY ? true : false}
        />
      </div>
    </div>
  );
};

export default TopFlop;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.legacy.css';
import { stockChartOptions } from '../../../defaultConfig';

const colors = stockChartOptions.colors;

export const ColorTableCell = ({ listingId, rowIndex }) => {
  const ref = useRef<HTMLElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [colorIndex, setColorIndex] = useState(rowIndex);
  // @ts-ignore
  const currentColorSet = window.currenColorSet;

  const updateColors = useCallback(
    (colorIndex) => {
      setColorIndex(colorIndex);
      setIsOpen(false);
      currentColorSet[listingId] = colorIndex;
      // @ts-ignore
      window.chartUpdateColors(currentColorSet);
    },
    [currentColorSet, listingId],
  );

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const isClickOutside =
      event.target !== ref.current &&
      !ref.current.contains(event.target as Node);

    setIsOpen(!isClickOutside);
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <span
      role="button"
      ref={ref}
      className={styles.ColorTableCell}
      tabIndex={0}
      style={{
        color: colors[colorIndex],
      }}
    >
      ▬
      {isOpen && (
        <div className={styles.ColorsTooltip}>
          {colors.map((color, index) => {
            if (index === 0) {
              return null;
            }
            return (
              <div
                role={'button'}
                tabIndex={index}
                className={styles.ColorItem}
                style={{ backgroundColor: color }}
                key={`${colors[index]}`}
                onClick={(event) => {
                  event.preventDefault();
                  updateColors(index);
                }}
                onKeyUp={(event) => {
                  event.preventDefault();
                  updateColors(index);
                }}
              />
            );
          })}
        </div>
      )}
    </span>
  );
};

export const LABEL_MAPPING = {
  intraday: 'INTRADAY',
  allIntraday: 'INTRADAY',
  oneWeek: '1 WOCHE',
  oneMonth: '1 MONAT',
  threeMonths: '3 MONATE',
  sixMonths: '6 MONATE',
  ytd: 'YTD',
  oneYear: '1 JAHR',
  threeYears: '3 JAHRE',
  fiveYears: '5 JAHRE',
  analyse: 'ANALYSE',
};

export const SHORT_TIME_PERIOD_FORMAT_MAPPING = {
  '1d': 'intraday',
  '1w': 'oneWeek',
  '1m': 'oneMonth',
  '3m': 'threeMonths',
  '6m': 'sixMonths',
  ytd: 'ytd',
  '1y': 'oneYear',
  '3y': 'threeYears',
  '5y': 'fiveYears',
  maximum: 'analyse',
};

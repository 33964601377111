import React, { ReactElement, memo } from 'react';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/client';
import ButtonWithLoading from '../../../ButtonWithLoading';
import AddToPortfolioButton from '../../../SearchResults/components/IntegrationButtonsWrapper/components/AddToPortfolioButton';
import { selectWatchlistAndAddInstrument } from '../../../../screens/MyCash/components/Watchlist/components/AddInstrumentToWatchlist';
import { alertsFormOverlay } from '../../../AlertsForm';
import { GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS } from '../../../AutoUpdateProvider/queries';
import styles from './styles.legacy.css';
import { WidgetParagraphProps } from '../../../Paragraphs/components/WidgetParagraph/typings';
import { QueryResult } from './typings';
import { useStableNavigate } from '../../../../../../../shared/hooks/useStableNavigateContext';
import { isListingKey } from '../../../../screens/MyCash/components/Portfolio/helpers';

const InstrumentActions = ({
  widgetParagraph,
}: WidgetParagraphProps): ReactElement => {
  const navigate = useStableNavigate();
  const location = useLocation();
  const origin = 'instrument-actions';
  const path = widgetParagraph?.link?.path;
  const url =
    (path && path.startsWith('/') && new URL(path, global.locationOrigin)) ||
    (path && new URL(path)) ||
    null;
  const listingKey =
    url?.searchParams?.get('listingKey') || url?.searchParams?.get('listingId');

  const { data, loading } = useQuery<QueryResult>(
    GET_AUTO_UPDATE_DATA_BY_INSTRUMENT_KEYS,
    {
      variables: {
        listingKeys: listingKey,
      },
      skip: !isListingKey(listingKey),
    },
  );

  const instrument = data?.quoteList?.quoteList?.edges?.[0]?.node;

  if (!instrument || loading) {
    return null;
  }

  const { mName, instrumentKey, fullquoteUri, scGrouped, eusipaId } =
    instrument;

  const isVisiblePortfolioButton = !['IND', 'INT', 'CUR'].includes(scGrouped);
  const isVisibleSimulatorButton =
    scGrouped === 'DER' &&
    [2100, 2200, 2210].includes(eusipaId) &&
    !location?.pathname?.includes('/simulator');

  return (
    <div className={styles.ButtonsWrapper}>
      {isVisibleSimulatorButton && (
        <ButtonWithLoading
          iconTypeLeft="IconChartArrowUp"
          size="small"
          variant="secondary"
          onClick={() => {
            navigate(`${location.pathname}/simulator`);
          }}
        >
          Simulator
        </ButtonWithLoading>
      )}
      <ButtonWithLoading
        iconTypeLeft="IconBell"
        size="small"
        variant="secondary"
        onClick={() =>
          alertsFormOverlay({
            alertKey: '',
            fullquoteUri,
            navigate,
            location,
          })
        }
      >
        Alert
      </ButtonWithLoading>
      {isVisiblePortfolioButton && (
        <AddToPortfolioButton
          listingId={instrumentKey}
          instrumentType={scGrouped}
          iconName="IconPieChart"
          origin={origin}
        >
          Portfolio
        </AddToPortfolioButton>
      )}
      <ButtonWithLoading
        iconTypeLeft="IconEye"
        size="small"
        variant="secondary"
        onClick={() => {
          selectWatchlistAndAddInstrument({
            instrumentKey,
            origin,
            instrumentName: mName,
          });
        }}
      >
        Watchlist
      </ButtonWithLoading>
    </div>
  );
};

export default memo<WidgetParagraphProps>(InstrumentActions);

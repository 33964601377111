import React from 'react';
import UtilityBar from '../../../../../UtilityBar';
import {
  UTILITY_BAR_ORIGIN_INLINE_OVERLAY,
  UTILITY_BAR_ORIGIN_OVERLAY,
} from '../../../../../../../../../shared/constants/utilitybar';
import styles from './styles.legacy.css';

const Default = ({
  overlayTitle,
  enabledUtilities,
  hasStickyness,
  shareUrl,
  title,
  shortTitle,
  lead,
  socialMediaTitle,
  imageUrl,
}) => (
  <>
    {overlayTitle && (
      <p className={styles.Title} data-testid="utility-overlay-title-wrapper">
        {overlayTitle}
      </p>
    )}
    {enabledUtilities &&
      Array.isArray(enabledUtilities) &&
      enabledUtilities.length > 0 && (
        <div className={styles.UtilityBarWrapper}>
          <UtilityBar
            shareUrl={shareUrl || ''}
            title={title || ''}
            shortTitle={shortTitle || ''}
            lead={lead || ''}
            socialMediaTitle={socialMediaTitle || ''}
            imageUrl={imageUrl || ''}
            enabledUtilities={enabledUtilities}
            origin={
              (!hasStickyness && UTILITY_BAR_ORIGIN_INLINE_OVERLAY) ||
              UTILITY_BAR_ORIGIN_OVERLAY
            }
          />
        </div>
      )}
  </>
);

export default Default;

import { RaschApolloConfig } from '../../../../../../../shared/decorators/withRaschRouterFactory';
import { GET_PORTFOLIO_TRANSACTIONS } from './queries';

export const portfolioTransactionsApolloConfig: RaschApolloConfig = {
  options: ({ params }) => {
    const portfolioKey = params?.portfolioKey;
    return {
      query: GET_PORTFOLIO_TRANSACTIONS,
      variables: {
        publication: 'CASH',
        portfolioKey: portfolioKey,
      },
      ssr: false,
      skip: !portfolioKey,
    };
  },
};

import { formatDate } from '../../../../../shared/helpers/dateTimeElapsed';
import { replaceAll } from '../../../../../shared/helpers/replaceAll';

export const replaceValues = (obj, title, valorName = '') => {
  if (!obj) {
    return null;
  }
  const regex = /\[title\]|\[valorName\]/gi;
  const newObj = JSON.stringify(obj).replace(regex, title);
  const newObj2 = newObj.replace(regex, valorName);
  return JSON.parse(newObj2);
};

type InsertWidgetUrlParamsData = {
  mSymb?: string;
  mIsin?: string;
  mMarketId?: number | string;
  mCurrencyId?: number | string;
  listingId?: string;
  compfullname?: string;
  mCur?: string;
  mMarket?: string;
  mMarketDescription?: string;
  mName?: string;
  scGrouped?: string;
  sec?: string;
  secId?: string;
  subtitle?: string;
  tradeType?: string;
  hrefBuy?: string;
};

const getDatesFromTokens = (body: string, replacements: string) => {
  const date = new Date();

  const dateTokens = body.match(/{(Date:.*?)}/) || [];
  return [...dateTokens].reduce((acc, value) => {
    const dateFormat = value
      .replace('Date:', '')
      .replace(replacements.slice(0, 1), '')
      .replace(replacements.slice(1), '');
    const key = value
      .replace(replacements.slice(0, 1), '')
      .replace(replacements.slice(1), '');
    acc = {
      ...acc,
      [key]: formatDate(date, dateFormat),
    };
    return acc;
  }, {});
};

const enrichBodyWithWidgetParams = ({
  body,
  data,
  replacements = '[]',
}: {
  body: string;
  data: InsertWidgetUrlParamsData & {
    valorName: string;
    market: string;
    currency: string;
    pageType?: string;
  };
  replacements?: string;
}): string => {
  const {
    mIsin,
    mSymb,
    mCurrencyId,
    mMarketId,
    listingId,
    compfullname,
    mCur,
    mMarket,
    mMarketDescription,
    mName,
    scGrouped,
    sec,
    secId,
    valorName,
    market,
    currency,
    pageType,
    subtitle,
    tradeType,
    hrefBuy,
  } = data;
  if (!valorName && !market && !currency) {
    return body;
  }

  const widgetParams = `${valorName}/${market}/${currency}`;
  const valorNameList = (valorName && valorName.split('-')) || [];
  const valorNr = valorNameList[valorNameList.length - 1] || '';
  const mIsinParam = mIsin ? `/${mIsin}` : '';
  const dates = getDatesFromTokens(body, replacements);

  Object.entries({
    pageType,
    widgetParams,
    valorName,
    valorNr,
    mMarketId,
    mCurrencyId,
    mSymb,
    mIsin,
    mIsinParam,
    listingId,
    compfullname,
    mCur,
    mMarket,
    mMarketDescription,
    mName,
    scGrouped,
    sec,
    secId,
    subtitle,
    tradeType,
    hrefBuy,
    ...dates,
  }).forEach(([key, value]) => {
    value = String(value || '').replace(/"/g, '\\"');
    body = replaceAll(
      body,
      replacements.slice(0, 1) + key + replacements.slice(1),
      String(value),
    );
  });

  return body;
};

export const enrichBody = ({
  body,
  data,
  replacements = '[]',
}: {
  body: any;
  data: InsertWidgetUrlParamsData & {
    valorName: string;
    market: string;
    currency: string;
    pageType?: string;
  };
  replacements?: string;
}) => {
  if (!body) {
    return body;
  }
  // do deep copy of items to get ride of mutations
  const bodyCopy = JSON.stringify(body);
  const enrichedBody = enrichBodyWithWidgetParams({
    body: bodyCopy,
    data,
    replacements,
  });

  return JSON.parse(replaceAll(enrichedBody, String.raw`\S`, '/S'));
};

export const breadcrumbItems = (
  fullquotePage: FullquotePage,
): Omit<ActiveMenuTrailItemConnection, 'pageInfo'> => {
  let link = '';
  let label = '';

  switch (fullquotePage?.canonicalUrl?.split('/')[0]) {
    case 'fonds':
      link = '/fonds';
      label = 'Fonds';
      break;
    case 'etf':
      link = '/etf';
      label = 'Fonds';
      break;
    case 'kryptowaehrungen':
      link = '/kryptowaehrungen';
      label = 'Kryptowaehrungen';
      break;
    case 'rohstoffe-edelmetalle':
      link = '/rohstoffe-edelmetalle';
      label = 'Rohstoffe & Edelmetalle';
      break;
    case 'devisen-zinsen':
      link = '/devisen-zinsen';
      label = 'Devisen & Zinsen';
      break;
    case 'derivate':
    case 'neuemissionen':
      link = '/derivate';
      label = 'Derivate';
      break;
    case 'wikifolio':
      link = '/aktien/wikifolio';
      label = 'Wikifolios';
      break;
    default:
      link = '/boerse';
      label = 'Börse & Märkte';
      break;
  }

  return {
    count: 2,
    totalCount: 2,
    edges: [
      {
        node: {
          id: '',
          link: link,
          label: label,
        },
      },
      {
        node: {
          id: '',
          link: null,
          label: fullquotePage?.title,
        },
      },
    ],
  };
};
